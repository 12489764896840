import React, {useContext, useState, useEffect} from 'react';
import APIContext from 'context/APIContext';
import AuthContext from 'context/AuthContext';
import {Formik, Form} from "formik";
import {Grid} from '@material-ui/core';
import {FormikTextField} from 'formik-material-fields';
import {useHistory, useLocation} from "react-router";
import MyButton from "components/Controls/MyButton";
import * as Yup from "yup";
import {NavLink} from "react-router-dom";
import logoLarge from "assets/images/logo-white.png";
import CacheContext, {DEFAULT_CACHE} from "context/CacheContext";
import config from "config/config.json";
import ErrorBadge from "components/common/ErrorBadge";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import qs from "qs";
import ShowIf from "components/common/ShowIf";
import {Alert} from "@material-ui/lab";
import {OAuthLoginForm} from "scenes/Login";
import TagManager from "react-gtm-module";
import {usePostHog} from 'posthog-js/react'

const register = "register";
const getRegistrationEmail = 'getRegistrationEmail';
const startRegistration = 'startRegistration';

const STATES = {
  start: 'start',
  details: 'details'
}

const Register = () => {

  const location = useLocation();
  const consent = true;
  const queryString = qs.parse(location.search, {ignoreQueryPrefix: true});
  const code = queryString?.code;

  const {call} = useContext(APIContext);
  const {setCache, cache} = useContext(CacheContext);
  const {setAuth} = useContext(AuthContext);
  const history = useHistory();
  const posthog = usePostHog();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [codeError, setCodeError] = useState();
  const [state, setState] = useState(STATES.start);
  const [email, setEmail] = useState(queryString.email);
  const [successMessage, setSuccessMessage] = useState();

  useEffect(() => {
    if (!!config.GOOGLE_TAG_ID) {
      TagManager.dataLayer({
        dataLayer: {
          event: 'start-registration',
        },
      });
    }
    posthog?.capture('start-registration');
  }, [])

  useEffect(() => {
    if (code) {
      setState(STATES.details);
      call(getRegistrationEmail, {code}, {setError: setCodeError}).then(response => {
        if (response.ok) {
          setEmail(response.body.value);
        }
      });
    }
  }, [code]);

  async function startRegistrationWrapper(values) {
    setLoading(true);
    let response = await call(startRegistration, {email: values.email}, {setError});
    if (response.ok) setSuccessMessage('Please check your email to continue your Ludo registration!');
    setLoading(false);
  }

  async function registerWrapper(values) {
    setLoading(true);
    const {name, password, first_name, last_name} = values;
    let response = await call(register, {auth: {name, code, password, first_name, last_name}}, {setError});
    if (response.ok) {
      let joinTeamId = cache.joinTeamId;
      localStorage.clear();
      sessionStorage.clear();
      onLoginPayload(response.body, joinTeamId)
    }
  }

  function onLoginPayload(payload, joinTeamId) {
    setAuth(payload, false);
    let selectedProjectId = payload.user.selected_project || DEFAULT_CACHE.selectedProjectId;
    let path = cache.unauthenticatedPath || "/";
    setCache({
      ...DEFAULT_CACHE,
      joinTeamId,
      selectedProjectId,
      projectsOpen: !selectedProjectId,
      unauthenticatedPath: undefined,
    });
    if (!!config.GOOGLE_TAG_ID) {
      TagManager.dataLayer({
        dataLayer: {
          event: 'registration',
        },
      });
    }
    posthog?.capture('submit-email-registration');
    if (joinTeamId) {
      history.push(`/join-team/${joinTeamId}/${payload.user.email}`);
    } else {
      history.push(path);
    }
  }

  return (
    <div className="app-wrapper bg-gradient min-vh-100 register">
      <div className="app-main min-vh-100 top-bg-content">
        <div className="app-content p-0">
          <div className="app-content--inner d-flex align-items-center">
            <div className="app-content--inner__wrapper">
              <div className="flex-grow-1 w-100 d-flex align-items-center">
                <div className="bg-composed-wrapper--content pb-5 px-2 mb-5">
                  <div className="auth-form-wrapper">
                    <div className="text-center mb-4 pb-2">
                      <h1 className="display-4 pb-4 mb-4 font-weight-bold font-size-xxxxl">
                        <img
                          width="225"
                          alt="Ludo"
                          className="d-block m-auto logo"
                          src={logoLarge}
                        />
                        Join now to start making hit games!
                      </h1>
                    </div>
                    <ShowIf condition={!code && state === STATES.start}>
                      <OAuthLoginForm onLoginPayload={onLoginPayload}/>
                      <StartRegistrationForm email={email} onSubmit={startRegistrationWrapper} loading={loading} error={error}
                                             successMessage={successMessage}/>
                    </ShowIf>
                    <ShowIf condition={state === STATES.details && !codeError}>
                      <RegisterForm email={email} onSubmit={registerWrapper} loading={loading} error={error}
                                    key={email}/>
                    </ShowIf>
                    <ErrorBadge error={codeError}/>
                    <div className="text-center pt-4">
                      Already on Ludo?{' '}
                      <NavLink to="/login" className="text-white font-weight-bold">
                        Sign in
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const StartRegistrationForm = ({onSubmit, loading, error, successMessage, email = ""}) => {
  return (<Formik
      initialValues={{email}}
      onSubmit={onSubmit}
      validationSchema={StartValidationSchema}
    >
      {({isValid, errors, dirty, validateField, setFieldValue}) => (
        <Form>
          <div className="text-center">
            Enter your email below. We’ll email you a link for verification.
          </div>
          <div className="mb-2 mt-2 pt-4">
            <label className="font-weight-bold mb-1 caps">
              Enter your email
            </label>
            <FormikTextField
              name="email"
              variant="outlined"
              size="small"
              fullWidth
              type="email"
              disabled={loading}
            />
          </div>
          <ErrorBadge error={error}/>

          <ShowIf condition={!!successMessage}>
            <Alert severity="success" className="mt-5 mb-2">
              {successMessage}
            </Alert>
          </ShowIf>

          <ShowIf condition={!successMessage}>
            <div className="text-center">
              <MyButton
                className="mt-4 mb-2 font-weight-bold blue"
                style={{width: "100%"}}
                loading={loading}
              >
                Get Started!
              </MyButton>
            </div>
          </ShowIf>
        </Form>
      )}
    </Formik>
  );
}

const RegisterForm = ({onSubmit, loading, error, email}) => {
  const [showPassword, setShowPassword] = useState(false);
  return (<Formik
      initialValues={{email, password: "", name: "", first_name: "", last_name: ""}}
      onSubmit={onSubmit}
      validationSchema={ValidationSchema}
    >
      {({isValid, errors, dirty, validateField, setFieldValue}) => (
        <Form>
          <div className="mb-4">
            <label className="font-weight-bold mb-1 caps">
              Email
            </label>
            <FormikTextField
              name="email"
              disabled={true}
              variant="outlined"
              size="small"
              fullWidth
              type="email"
            />
          </div>
          <div className="mb-4">
            <Grid container spacing={2}>
              <Grid item sm={6} xs={12}>
                <label className="font-weight-bold mb-1">
                  First Name/Given Name
                </label>
                <FormikTextField
                  name="first_name"
                  variant="outlined"
                  size="small"
                  fullWidth
                  disabled={loading}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <label className="font-weight-bold mb-1">
                  Last Name/Surname
                </label>
                <FormikTextField
                  name="last_name"
                  variant="outlined"
                  size="small"
                  fullWidth
                  disabled={loading}
                />
              </Grid>
            </Grid>
          </div>
          <div className="mb-4">
            <div className="d-flex justify-content-between position-relative">
              <label className="font-weight-bold mb-1">
                Password (6 or more characters)
              </label>
              <div
                className="d-flex"
                style={{position: "absolute", right: "5px", top: 0, color: "white", cursor: "pointer"}}
                onClick={() => setShowPassword(!showPassword)}
              >
                <div className="mr-3">
                  {showPassword ? <VisibilityOff/> : <Visibility/>}
                </div>
                <span style={{top: "1px", position: "relative", width: "35px", textAlign: "right"}}>
                  {showPassword ? "Hide" : "Show"}
                </span>
              </div>
            </div>
            <FormikTextField
              name="password"
              variant="outlined"
              size="small"
              fullWidth
              type={showPassword ? "text" : "password"}
              disabled={loading}
            />
          </div>
          <div className="mt-4 text-white">
          <span>By clicking this button, you agree to our <a tabIndex="-1" href="https://ludo.ai/privacy-policies"
                                                             target="_blank"
                                                             className="text-white underline">Terms of Service and Privacy Policy</a></span>
          </div>
          <ErrorBadge error={error}/>
          <div className="text-center">
            <MyButton
              className="mt-5 mb-2 font-weight-bold blue"
              style={{width: "100%"}}
              loading={loading}
            >
              Get Started!
            </MyButton>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default Register;

const StartValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Must be a valid email')
    .required('No email provided'),
});


const ValidationSchema = Yup.object().shape({
  first_name: Yup.string()
    .required('No first name provided'),
  last_name: Yup.string()
    .required('No last name provided'),
  email: Yup.string()
    .email('Must be a valid email')
    .required('No email provided'),
  password: Yup.string()
    .required('No password provided')
    .min(6, 'Password is too short - should be 6 characters minimum'),
});
