import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import SocketContext from "context/SocketContext";
import PageTitle from "components/layout-components/PageTitle";
import ShowIf from "components/common/ShowIf";
import CacheContext from "context/CacheContext";
import {
  AddArtStyle,
  FAIcon,
  GDDMenu,
  getNumberAllowedProjects,
  isProjectEditable,
} from "pages/GDD3/Helpers";
import { PDFExport } from "@progress/kendo-react-pdf";
import "./style.scss";
import _ from "lodash";
import GDDSideMenu from "pages/GDD3/GDDSideMenu";
import GDDGameSummary from "pages/GDD3/GDDGameSummary";
import PerformanceUtils from "helpers/PerformanceUtils";
import GDDGameElement, {
  GAME_ELEMENT_LAYOUTS,
} from "pages/GDD3/GDDGameElement";
import GDDText from "pages/GDD3/GDDText";
import GDDMoodboard from "pages/GDD3/GDDMoodboard";
import GDDGames from "pages/GDD3/GDDGames";
import { Provider } from "react-bus";
import GDDContext from "context/GDDContext";
import PDFTemplate from "pages/GDD3/PDFTemplate";
import CommentsSection from "pages/GDD3/GDDSideMenu/CommentsMenu";
import { sendToSlack, showInfoMessage } from "components/Sharing/ShareToSlack";
import AuthContext from "context/AuthContext";
import { useSnackbar } from "notistack";
import { drawDOM } from "@progress/kendo-drawing";
import { useHistory, useLocation, useParams } from "react-router";
import GDDSectionActions from "pages/GDD3/GDDSectionActions";
import DismissableMessage from "components/common/DismissableMessage";
import {
  AddCircleOutlineOutlined,
  ChevronLeft,
  CloseOutlined,
} from "@mui/icons-material";
import APIContext from "context/APIContext";
import GDDHeader from "pages/GDD3/GDDHeader";
import useGDDState from "hooks/useGDDState";
import { CircularProgress, IconButton } from "@material-ui/core";
import GDDLudoScore from "./GDDLudoScore";

const DEFAULT_ARRAY = [];
const generateGameConceptHeaderImagesNew = "generateGameConceptHeaderImagesNew";
const cancelGameConceptHeaderImages = "cancelGameConceptHeaderImages";
const DEFAULT_GDD = {
  genres: undefined,
  sections: [{ name: "summary", value: { title: "", text: "" } }],
};

const GDD3 = () => {
  const { call } = useContext(APIContext);
  const { cache, setCacheValue } = useContext(CacheContext);
  const { selectedProjectId } = cache;

  const { closeMenu } = useContext(GDDContext);

  const params = useParams();
  const gddState = useGDDState();

  const projectId = params.projectId || selectedProjectId;

  useEffect(() => {
    closeMenu();
  }, []);

  useEffect(() => {
    if (selectedProjectId !== projectId) {
      setCacheValue("selectedProjectId", projectId);
      call("updateUserInfo", { data: { selected_project: projectId } });
    }
  }, [call, selectedProjectId, projectId]);

  useEffect(() => {
    if (projectId) {
      setCacheValue("selectedProjectId", projectId);
    }
  }, [projectId, selectedProjectId]);

  useEffect(() => {
    gddState.reset();
  }, [projectId]);

  return projectId ? (
    <GDD3Content key={projectId} projectId={projectId} />
  ) : null;
};

const GDD3Content = ({ projectId }) => {
  const location = useLocation();
  const history = useHistory();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { call, loading } = useContext(APIContext);
  const { cache, setCache } = useContext(CacheContext);
  const { track, emit } = useContext(SocketContext);
  const { auth } = useContext(AuthContext);
  const { menu, openMenu, closeMenu, preview, setPreview } =
    useContext(GDDContext);
  const [generatedIcon, setGeneratedIcon] = useState();
  const [generatedHeader, setGeneratedHeader] = useState();
  const [generatingHeaderImages, setGeneratingHeaderImages] = useState(false);

  const editorRef = useRef();
  const pdfRef = useRef();
  const wrapperRef = useRef(null);

  const {
    projects = DEFAULT_ARRAY,
    detailsPanel,
    generationTypes = DEFAULT_ARRAY,
    gddComponents,
  } = cache;
  const project = useMemo(() => {
    return projects.find((project) => project._id === projectId) || {};
  }, [projectId, projects]);

  const [savingPDF, setSavingPDF] = useState(false);
  const [exportPDF, setExportPDF] = useState(false);
  const [activeComponent, setActiveComponent] = useState();

  const sidePanelOpen = !!menu || !!detailsPanel.mode;

  const debouncedSendGDDSocket = useRef(_.debounce(sendGDDSocket, 300)).current;
  const debouncedSendGDDSectionSocket = useRef(
    _.debounce(sendGDDSectionSocket, 300)
  ).current;

  useEffect(() => {
    if (!project?._id) {
      history.push("/game-concept");
    }
  }, [history, project]);

  const editorHeight = useMemo(() => {
    return editorRef?.current?.offsetHeight;
  }, [editorRef, savingPDF, exportPDF]);

  useEffect(() => {
    if (exportPDF) {
      pdfRef?.current.save(() => {
        setSavingPDF(false);
        setExportPDF(false);
      });
    }
  }, [pdfRef, editorRef, exportPDF]);

  const gdd = project.gdd2 || {
    ...DEFAULT_GDD,
    genres: auth.user.genres || DEFAULT_ARRAY,
  };

  useEffect(() => {
    if (location.state?.data) {
      let data = location.state.data;

      if (data.generateImages) {
        generateHeaderImages(data.extract);
      }
      history.replace({ ...history.location, state: {} });
    }
  }, [location, gdd, project]);

  useEffect(() => {
    if (generatedIcon) {
      setGeneratedIcon();
      changeAllGdd({ ...gdd, icon: generatedIcon });
    }
  }, [generatedIcon, gdd]);

  useEffect(() => {
    if (generatedHeader) {
      setGeneratedHeader();
      changeAllGdd({ ...gdd, header: generatedHeader });
    }
  }, [generatedHeader, gdd]);

  useEffect(() => {
    if (menu && project.platform !== gdd.platform) {
      changeAllGdd({ ...gdd, platform: project.platform });
    }
  }, [project, gdd, menu]);

  async function generateHeaderImages(extract) {
    setGeneratingHeaderImages(true);
    await call(generateGameConceptHeaderImagesNew, { projectId, extract });
    setGeneratingHeaderImages(false);
  }

  async function stopGeneration() {
    setGeneratingHeaderImages(false);
    await call(cancelGameConceptHeaderImages, { projectId });
  }

  function sendGDDSocket(gdd, teamId, id) {
    emit("gdd-update", { gdd, teamId, id });
  }

  function sendGDDSectionSocket(key, section, teamId, id) {
    emit("gdd-update-section", { key, section, teamId, id });
  }

  useEffect(() => {
    if (gdd?.sections?.length === 0) {
      changeGdd("sections", DEFAULT_GDD.sections);
    } else if (!gdd.sections.find(({ name }) => name === "summary")) {
      changeGdd("sections", [{ name: "summary" }, ...gdd.sections]);
    }
  }, [gdd]);

  function changeAllGdd(updatedGdd) {
    project.platform = project.platform || "Mobile";
    if (!updatedGdd.platform && project.platform)
      updatedGdd.platform = project.platform;
    debouncedSendGDDSocket(updatedGdd, project.team_id, project._id);
    updateGDDCache(updatedGdd);
  }

  const changeGdd = useCallback(
    (key, value, isSection = false, otherData) => {
      let updatedGdd;
      if (isSection) {
        updatedGdd = {
          ...gdd,
          ...(otherData || {}),
          sections: (gdd.sections || []).map((section) => {
            if (section.id === key || section.name === key)
              section.value = value;
            return section;
          }),
        };
        if (otherData)
          debouncedSendGDDSocket(updatedGdd, project.team_id, project._id);
        else {
          let section = updatedGdd.sections.find(
            (section) => section.id === key || section.name === key
          );
          debouncedSendGDDSectionSocket(
            key,
            section,
            project.team_id,
            project._id
          );
        }
      } else {
        updatedGdd = {
          ...gdd,
          [key]: value,
        };
        if (!updatedGdd.platform && project.platform)
          updatedGdd.platform = project.platform;
        debouncedSendGDDSocket(updatedGdd, project.team_id, project._id);
      }
      updateGDDCache(updatedGdd);
    },
    [gdd, project, projectId]
  );

  function updateGDDCache(updatedGdd) {
    let updatedProject = { ...project, gdd2: updatedGdd };
    setCache((prevState) => {
      return {
        ...prevState,
        projects: PerformanceUtils.editOrAdd(
          updatedProject,
          prevState.projects,
          "_id"
        ),
      };
    });
  }

  function deleteSection(key) {
    track("gdd.section.delete-section", { section: key });
    let sections = PerformanceUtils.removeFromArray(
      { name: key },
      gdd.sections,
      "name"
    );
    sections = PerformanceUtils.removeFromArray({ id: key }, sections, "id");
    changeGdd("sections", sections);
    setActiveComponent();
  }

  function changeOrder(key, up = true) {
    track("gdd.section.re-order", {
      section: key,
      direction: up ? "up" : "down",
    });
    let index = _.findIndex(gdd.sections, ["name", key]);
    if (index < 0) index = _.findIndex(gdd.sections, ["id", key]);
    if (index >= 0) {
      let targetIndex = index + (up ? -1 : 1);
      targetIndex = Math.min(
        Math.max(targetIndex, 1),
        (gdd.sections || []).length - 1
      );
      if (targetIndex !== index) {
        let sections = PerformanceUtils.arrayMove(
          gdd.sections,
          index,
          targetIndex
        );
        changeGdd("sections", sections);
      }
    }
  }

  const numberAllowedProjects = useMemo(() => {
    return getNumberAllowedProjects(auth);
  }, [auth]);

  const editable = useMemo(() => {
    return isProjectEditable(projects, projectId, numberAllowedProjects);
  }, [numberAllowedProjects, projects, projectId]);

  useEffect(() => {
    if (!editable) setPreview(true);
    else setPreview(false);
  }, [editable]);

  function clickedAdd(section) {
    track("gdd.clicked-add");
    changeActiveComponent();
    openMenu(section);
  }

  function addNewComponent(component, afterSection = "summary") {
    let newSection = {
      name: component.section,
      id: component?.id || PerformanceUtils.generateId(),
      value: {},
    };
    let sections = gdd?.sections || [];

    if (component.gameElements) {
      newSection.value = {};
      newSection.value.layout = generationTypes.includes(component.section)
        ? GAME_ELEMENT_LAYOUTS.images
        : GAME_ELEMENT_LAYOUTS.text;
    }

    if (afterSection) {
      let index = _.findIndex(gdd.sections, ["name", afterSection]);
      if (index < 0) index = _.findIndex(gdd.sections, ["id", afterSection]);
      index = Math.max(0, index);
      let newSections = [];

      for (let i = 0; i <= index; i++) newSections.push(sections[i]);

      newSections.push(newSection);

      for (let i = index + 1; i < sections.length; i++)
        newSections.push(sections[i]);

      sections = newSections;
    } else {
      sections = [...sections, newSection];
    }

    changeGdd("sections", sections);
    changeActiveComponent({ ...component, id: newSection.id });
    closeMenu();
  }

  function changeActiveComponent(component) {
    setActiveComponent(component);
  }

  function downloadPDF() {
    track("gdd.download-pdf");
    setSavingPDF(true);
    setTimeout(() => {
      setExportPDF(true);
    }, 100);
  }

  async function exportPDFBase64() {
    setSavingPDF(true);
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        let gridElement = document.querySelector(".gdd .editor");
        drawDOM(gridElement, PDF_OPTIONS)
          .then((group) => exportPDF(group))
          .then((dataUri) => {
            return resolve(dataUri.split(";base64,")[1]);
          })
          .catch((err) => reject(err))
          .finally(() => {
            setSavingPDF(false);
          });
      }, 100);
    });
  }

  async function shareToSlack() {
    let gdd_pdf = await exportPDFBase64();
    let data = { gdd, gdd_pdf, project: project._id };
    let team = (auth.user.teams || [])[0];
    if (!team)
      return showInfoMessage(
        "Sharing can only be used if you are a member of a team",
        enqueueSnackbar,
        closeSnackbar
      );
    await sendToSlack(call, loading, { data, project: project._id, team });
  }

  const title = (gdd?.sections || [])[0]?.value?.title;

  let inchHeight = Math.max(editorHeight * 0.0104166667, 11.7);

  const PDF_OPTIONS = {
    scale: 0.6,
    paperSize: ["27cm", `${inchHeight}in`],
    fileName: `${title || project.name} GDD.pdf`,
    margin: "1cm",
  };

  const hasHeader = !!gdd.header?.url;

  let editorClassName = "editor";
  if (savingPDF) editorClassName += " k-pdf-export";

  let gddPageClassName = "w-100 gdd-page";
  if (sidePanelOpen) gddPageClassName += " panel-open";
  if (hasHeader) gddPageClassName += " has-header-image";

  let gddContentClassName = "gdd-content";
  if (preview || savingPDF) gddContentClassName += " gdd-preview";

  if (!project?._id) return null;

  return (
    <Provider>
      <div className="gdd-page-wrapper">
        <div className={gddPageClassName}>
          <PageTitle
            titleHeading="Game Concepts"
            titleDescription="Let Ludo help turn your game idea into a complete Concept Document"
          />
          <ShowIf condition={!!projectId}>
            <PDFExport
              paperSize={PDF_OPTIONS.paperSize}
              fileName={PDF_OPTIONS.fileName}
              scale={PDF_OPTIONS.scale}
              margin={PDF_OPTIONS.margin}
              title=""
              subject=""
              keywords=""
              creator="Ludo AI"
              pageTemplate={PDFTemplate}
              ref={pdfRef}
            >
              <div className="gdd-top-bar-wrapper">
                <GDDMenu
                  changeAllGdd={changeAllGdd}
                  sticky={false}
                  sidePanelOpen={sidePanelOpen}
                  numberAllowedProjects={numberAllowedProjects}
                />
                <div className="gdd">
                  <div className={gddContentClassName}>
                    <GDDHeader
                      gdd={gdd}
                      setPreview={setPreview}
                      active={
                        activeComponent?.id === gddComponents.header.section
                      }
                      changeAllGdd={changeAllGdd}
                      section={gddComponents.header.section}
                      component={gddComponents.header}
                      setActiveComponent={() =>
                        changeActiveComponent({
                          ...gddComponents.header,
                          id: gddComponents.header.section,
                        })
                      }
                      generating={generatingHeaderImages}
                      shareToSlack={shareToSlack}
                      downloadPDF={downloadPDF}
                      editable={editable}
                      changeGdd={changeGdd}
                      project={project}
                      preview={preview || savingPDF}
                      numberAllowedProjects={numberAllowedProjects}
                    />
                    <DismissableMessage
                      id="gdd-mobile-warning"
                      message="Your Game Concept can only be edited in the desktop version. Visit Ludo on your desktop or laptop to get the full experience!"
                      className="only-mobile mt-3 mx-2"
                    />
                    <div className="content-wrapper" ref={wrapperRef}>
                      <div
                        className={editorClassName}
                        ref={editorRef}
                        key={projectId}
                      >
                        <Components
                          projectId={projectId}
                          gdd={gdd}
                          changeGdd={changeGdd}
                          activeComponent={activeComponent}
                          changeOrder={changeOrder}
                          deleteSection={deleteSection}
                          setActiveComponent={changeActiveComponent}
                          addNewComponent={addNewComponent}
                          clickedAdd={clickedAdd}
                          stopGeneration={stopGeneration}
                          projectId={projectId}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </PDFExport>
          </ShowIf>
        </div>
        <ShowIf condition={!!projectId && !preview}>
          <GDDSideMenu
            key={projectId}
            changeGdd={changeGdd}
            addNewComponent={addNewComponent}
            gdd={gdd}
          />
        </ShowIf>
      </div>
    </Provider>
  );
};

const Components = ({
  gdd,
  changeGdd,
  activeComponent,
  setActiveComponent,
  clickedAdd,
  changeOrder,
  deleteSection,
  stopGeneration,
  projectId,
}) => {
  const { cache } = useContext(CacheContext);
  const { gddComponents } = cache;

  const { summary, text, games, moodboard, images, trending, score } =
    gddComponents;

  const { menu, preview } = useContext(GDDContext);

  return (gdd.sections || []).map((section) => {
    const matchingComponent = gddComponents[section.name] || {};

    let sectionName = section.id || section.name;
    let active = section.id === activeComponent?.id;
    if (!!activeComponent?.targetSection)
      active = section.id === activeComponent.targetSection;

    function sectionSetActiveComponent() {
      if (!active) {
        //closeMenu();
        return setActiveComponent(
          { ...matchingComponent, id: section.id },
          undefined
        );
      }
    }

    let addHere =
      menu?.section === section.id && menu?.component === gddComponents.index;
    let onlyOneSection = gdd.sections.length === 1;
    const isLoading = section.loading;
    if (isLoading) active = false;

    let iconsWrapperClassName = "icons-wrapper";
    if (addHere) iconsWrapperClassName += " active";
    if (onlyOneSection) iconsWrapperClassName += " hover";

    let wrapperClassName = "section-wrapper";
    if (active) wrapperClassName += " active";
    if (isLoading) wrapperClassName += " loading";

    let sectionContentWrapperClassName = "section-content-wrapper";
    if (active) sectionContentWrapperClassName += " active";

    if (preview && !!activeComponent)
      sectionContentWrapperClassName += " comments-active";

    return (
      <div className={wrapperClassName} key={section.id || section.name}>
        <div
          className={sectionContentWrapperClassName}
          onClick={sectionSetActiveComponent}
        >
          <GDDSectionActions
            changeGdd={changeGdd}
            component={matchingComponent}
            gdd={gdd}
            value={section.value}
            section={sectionName}
            changeOrder={(up) => changeOrder(sectionName, up)}
            deleteSection={
              section.name !== "summary"
                ? () => deleteSection(sectionName)
                : undefined
            }
            projectId={projectId}
          />
          <ShowIf condition={active && preview}>
            <CommentsSection
              value={section.value}
              component={activeComponent}
              gdd={gdd}
              changeGdd={changeGdd}
              section={sectionName}
            />
          </ShowIf>
          {isLoading ? (
            <div className="loading-wrapper">
              <CircularProgress size={55} />
              <IconButton onClick={stopGeneration}>
                <CloseOutlined className="font-size-xxl pointer text-secondary" />
              </IconButton>
            </div>
          ) : null}
          {section.name === summary.section ? (
            <GDDGameSummary
              value={section.value}
              section={sectionName}
              active={active}
              changeGdd={changeGdd}
              component={matchingComponent}
            />
          ) : null}
          {section.name === text.section ? (
            <GDDText
              value={section.value}
              section={sectionName}
              active={active}
              changeGdd={changeGdd}
              component={matchingComponent}
              gdd={gdd}
            />
          ) : null}
          {section.name === score.section ? (
            <GDDLudoScore
              value={section.value}
              section={sectionName}
              active={active}
              changeGdd={changeGdd}
              component={matchingComponent}
              gdd={gdd}
              projectId={projectId}
            />
          ) : null}
          {section.name === moodboard.section ||
          section.name === images.section ? (
            <GDDMoodboard
              value={section.value}
              section={sectionName}
              active={active}
              changeGdd={changeGdd}
              gdd={gdd}
              component={matchingComponent}
            />
          ) : null}
          {section.name === games.section ||
          section.name === trending.section ? (
            <GDDGames
              key={sectionName}
              value={section.value}
              section={sectionName}
              active={active}
              changeGdd={changeGdd}
              component={matchingComponent}
              includeStats={true}
              gdd={gdd}
              title="Games"
            />
          ) : null}
          {/*{section.name === trending.section ?
            <GDDGames
              key={sectionName}
              value={section.value}
              section={sectionName}
              active={active}
              changeGdd={changeGdd}
              gdd={gdd}
              component={matchingComponent}
              includeStats={true}
              title="Trending Games"
            /> : null}*/}
          {matchingComponent.gameElements ? (
            <GDDGameElement
              component={matchingComponent}
              title={matchingComponent.label}
              value={section.value}
              section={sectionName}
              active={active}
              changeGdd={changeGdd}
              gdd={gdd}
              sectionSetActiveComponent={sectionSetActiveComponent}
            />
          ) : null}
        </div>
        <AddSection
          className={iconsWrapperClassName}
          clickedAdd={clickedAdd}
          section={section}
          menu={menu}
        />
      </div>
    );
  });
};

const AddSection = ({ className, clickedAdd, section, menu }) => {
  const ref = useRef();
  const { cache } = useContext(CacheContext);
  const { gddComponents } = cache;

  function scrollTo(delayMs) {
    setTimeout(() => {
      ref.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }, delayMs);
  }

  return (
    <div
      ref={ref}
      className={className}
      onClick={() => {
        if (!menu?.component) {
          scrollTo(200);
        }
        clickedAdd({ component: gddComponents.index, section: section.id });
      }}
    >
      <FAIcon
        tooltip="Add Section"
        icon={<AddCircleOutlineOutlined className="font-size-xxxxxxl" />}
        animation="pulse"
        className="animate__infinite animate__slower mx-3"
      />
      <hr />
      <div className="angles animate__animated animate__fadeIn">
        {[3, 2, 1, 0].map((delay) => (
          <FAIcon
            key={delay}
            icon={<ChevronLeft />}
            animation="pulse"
            className={`animate__infinite animate__slower delay-${delay}`}
          />
        ))}
      </div>
    </div>
  );
};

export default GDD3;
