import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import usePersistedState from "../../hooks/usePersistedState";
import { GDDModal } from "../GDD3/Helpers";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { Chip, Slider as OldSlider } from "@material-ui/core";
import ReactCrop from "react-image-crop";
import MyButton from "../../components/Controls/MyButton";
import { Canvas } from "./MaskCanvas";
import SocketContext from "../../context/SocketContext";

const DEFAULT_ARRAY = [];

const ImageEditor = ({
  url,
  onClose,
  onSuccess,
  sizeCrop = DEFAULT_ARRAY,
  initialMask,
}) => {

  const { track } = useContext(SocketContext);
  const [crop, setCrop] = useState({});
  const [mask, setMask] = useState(initialMask);
  const [mode, setMode] = useState("mask");
  const [initialCrop, setInitialCrop] = useState(sizeCrop);
  const [maskKey, setMaskKey] = useState(1);
  const [brushSize, setBrushSize] = usePersistedState(
    "ImageEditor.brushSize",
    10
  );
  const [cropImage, setCropImage] = useState();
  const [cropContainerSize, setCropContainerSize] = useState();
  const [imageSize, setImageSize] = useState();
  const [image, setImage] = useState(null);
  const [loadedImage, setLoadedImage] = useState(false);

  function onLoadImage(event) {
    setImageSize({ width: event.target.width, height: event.target.height });
  }

  useEffect(() => {
    if (imageSize && crop.x === undefined) {

      function toPercentage(value, reference) {
        return (value / reference) * 100.0;
      }

      const {height, width } = imageSize;

      let newCrop = {
        "x": toPercentage((sizeCrop[0] || 0), width),
        "y": toPercentage((sizeCrop[1] || 0), height),
        "width": toPercentage((parseInt(width) - (sizeCrop[2] || 0) - (sizeCrop[0] || 0)), width),
        "height": toPercentage((parseInt(height) - (sizeCrop[3] || 0) - (sizeCrop[1] || 0)), height),
        "unit": "%"
      };
      setCrop(newCrop);
      setInitialCrop();
    }
  },[imageSize, initialCrop, crop])

  useEffect(() => {
    if (url) {
      fetch(url)
        .then((response) => response.blob())
        .then(async (blob) => {
          if (!blob.type) blob = new Blob([blob], { type: "image/jpeg" });
          setImage({ url: URL.createObjectURL(blob) });
        });
    }
  }, [url]);

  useEffect(() => {
    if (image && imageSize) {
      const cropX = ((crop.x || 0) / 100) * imageSize?.width;
      const cropY = ((crop.y || 0) / 100) * imageSize?.height;
      const cropWidth = ((crop.width || 100) / 100) * imageSize?.width;
      const cropHeight = ((crop.height || 100) / 100) * imageSize?.height;

      var canvas = document.createElement("canvas");
      canvas.width = cropWidth;
      canvas.height = cropHeight;

      var canvasContext = canvas.getContext("2d");
      let htmlImage = new Image();
      htmlImage.src = image.url;
      canvasContext.drawImage(
        htmlImage,
        cropX,
        cropY,
        cropWidth,
        cropHeight,
        0,
        0,
        cropWidth,
        cropHeight
      );
      setCropImage({ url: canvas.toDataURL("image/png") });
      setMaskKey((prevState) => prevState + 1);
    }
  }, [crop, mode, image, imageSize]);

  const cropContainerRef = useCallback((node) => {
    function setDimensions(node) {
      if (node.offsetWidth > 0 && node.offsetHeight > 0) {
        setCropContainerSize({
          width: node.offsetWidth,
          height: node.offsetHeight,
        });
        return true;
      }
      return false;
    }

    if (node !== null) {
      if (!setDimensions(node)) {
        setTimeout(() => {
          setDimensions(node);
        }, 1000);
      }
      setMaskKey((prevState) => prevState + 1);
    }
  }, [loadedImage]);

  const convertedCrop = useMemo(() => {
    if (crop && imageSize) {
      const { width, height } = imageSize;

      function fromPercentage(value, reference) {
        return parseInt("" + (value / 100.0) * reference);
      }

      return [
        fromPercentage(crop.x, width),
        fromPercentage(crop.y, height),
        width -
          fromPercentage(crop.width, width) -
          fromPercentage(crop.x, width),
        height -
          fromPercentage(crop.height, height) -
          fromPercentage(crop.y, height),
      ];
    }
  }, [crop, imageSize]);

  function onSubmit() {
    onSuccess(convertedCrop, mask);
  }

  let containerClassName = "image-container " + mode;
  let modeToggleClassName = "mode-toggle " + mode;
  let maskContainerClassName = "mask-container";
  if (mask) maskContainerClassName += " has-mask";

  async function changeMode(newMode) {
    track("image-generator.editor.change-mode", { mode: newMode });
    if (newMode === "mask") {
      setCropContainerSize();
    }
    if (newMode) {
      setMode(newMode);
      await new Promise((resolve) => setTimeout(resolve, 500));
      setMaskKey((prevState) => prevState + 1);
    }
  }

  function onCanvasExport(maskImage) {
    if (maskImage) {
      setMask(maskImage);
    }
  }

  function clearMask() {
    setMask(undefined);
    setMaskKey(maskKey + 1);
  }

  function clearCrop() {
    setCrop({ x: 0, y: 0, width: 100, height: 100, unit: "%" });
    clearMask();
  }

  function changeCrop(value) {
    track("image-generator.editor.change-crop", {});
    setCrop(value);
    clearMask();
  }

  return url ? (
    <GDDModal
      open={!!url}
      onClose={onClose}
      title="Image Editor"
      className="image-editor-modal"
    >
      <div className="px-4 m-auto modal-content">
        <img src={image?.url} className="hide" onLoad={onLoadImage} />
        <div className={modeToggleClassName}>
          <ToggleButtonGroup
            color="primary"
            value={mode}
            exclusive
            onChange={(event, mode) => changeMode(mode)}
            aria-label="Mode"
          >
            <ToggleButton value="mask">Draw Mask</ToggleButton>
            <ToggleButton value="crop">Crop</ToggleButton>
          </ToggleButtonGroup>

          <div className="canvas-controls">
            <Chip
              onClick={clearCrop}
              className="clear-crop font-weight-bold"
              label="Clear Crop"
            />
            <Chip
              onClick={clearMask}
              className="clear-mask font-weight-bold"
              label="Clear Mask"
            />
            <div className="slider-wrapper">
              <span>Brush Size</span>
              <OldSlider
                className="slider-primary"
                track="inverted"
                value={brushSize}
                step={1}
                min={1}
                onChange={(event, value) => {
                  setBrushSize(value);
                }}
                max={80}
              />
            </div>
          </div>
        </div>

        {mode !== "crop" && cropImage && !initialCrop && (
          <div className={maskContainerClassName} ref={cropContainerRef}>
            <img src={cropImage?.url} className="cropped-image" onLoad={() => setLoadedImage(true)} />
            {cropContainerSize && mask && loadedImage && (
              <img
                className="segmentation-mask"
                src={mask}
                style={{
                  width: cropContainerSize.width,
                  height: cropContainerSize.height,
                }}
              />
            )}
            {cropContainerSize && mode === "mask" && loadedImage && (
              <Canvas
                className="mask-canvas"
                key={maskKey}
                width={cropContainerSize.width+1}
                height={cropContainerSize.height+1}
                onExport={onCanvasExport}
                brushSize={brushSize}
              />
            )}
          </div>
        )}

        {mode === "crop" && (
          <div className={containerClassName}>
            <>
              <img src={url} className="bg-image" />
              {cropContainerSize && (
                <ReactCrop
                  crop={crop}
                  onChange={(pixelCrop, percentageCrop) =>
                    changeCrop(percentageCrop)
                  }
                  unit="%"
                >
                  <div
                    className="crop-target"
                    style={{
                      width: cropContainerSize?.width,
                      height: cropContainerSize?.height,
                    }}
                  />
                </ReactCrop>
              )}
              {mask && crop && (
                <div
                  className="segmentation-mask-wrapper"
                  style={{
                    width: "100%",
                    height: "auto",
                  }}
                >
                  <img
                    className="segmentation-mask"
                    src={mask}
                    style={{
                      width: crop.width + "%",
                      height: crop.height + "%",
                      left: crop.x + "%",
                      top: crop.y + "%",
                    }}
                  />
                </div>
              )}
            </>
          </div>
        )}
        <MyButton color="secondary" onClick={onSubmit}>
          Save
        </MyButton>
      </div>
    </GDDModal>
  ) : null;
};
export default ImageEditor;
