import React, {useContext, useEffect, useMemo, useRef, useState} from 'react';
import {
  CircularProgress, Slider as OldSlider,
  Grid, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Typography, Checkbox,
} from '@material-ui/core';
import APIContext from "context/APIContext";
import FormikChipSelect from "components/Controls/FormikChipSelect";
import {Form, Formik, useFormikContext} from "formik";
import PageTitle from "components/layout-components/PageTitle";
import MyButton, {ConfirmDialog, GeneratingButton} from "components/Controls/MyButton";
import CacheContext from "context/CacheContext";
import usePersistedState from "hooks/usePersistedState";
import FormikPersist from 'components/utils/FormikPersist';
import * as Yup from "yup";
import ShowIf from "components/common/ShowIf";
import PerformanceUtils from "helpers/PerformanceUtils";
import UniversalInput, {
  ChangeDataOnLocation,
  convertUniversalInput, textToUniversalOption,
} from "components/Controls/UniversalInput";
import 'react-image-crop/src/ReactCrop.scss'
import {convertFormColors, FilterPanel, FilterPanelData, FiltersButton, showColorAvatar} from "pages/Search";
import './style.scss';
import {convertProxyUrl} from "components/common/ImageGallery";
import {FormikSelectField} from "formik-material-fields";
import AuthContext from "context/AuthContext";
import {ThumbnailPreview, toBase64} from "components/Controls/FileUpload";
import {useHistory} from "react-router";
import ReactPlayer from 'react-player'
import {Hint} from "scenes/Headquarters";
import {
  ArrowDropDown,
  CloseOutlined,
  CropLandscapeOutlined,
  CropPortraitOutlined,
  CropSquare,
  DeblurOutlined, DeleteOutline,
  DownloadOutlined,
  EditOutlined, ErrorOutline,
  PlayCircleOutline, StopCircleOutlined,
} from "@mui/icons-material";
import LoadingTip, {LOADING_TIPS_SECTIONS} from "components/utils/LoadingTip";
import moment from 'moment';
import LinearProgress from "@material-ui/core/LinearProgress";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import LudoLazyLoad from "components/common/LudoLazyLoad";
import {ReferenceImage, ReferenceVideo} from "./MediaInputs";
import SocketContext from "../../context/SocketContext";

const cancelGeneration = 'cancelGeneration';
const uploadImage = 'uploadImage';
const generateVideo = 'generateVideo';
const getGeneratedVideos = 'getGeneratedVideos';
const cancelGeneratedVideo = 'cancelGeneratedVideo';
const upscaleGeneratedVideo = 'upscaleGeneratedVideo';
const generatedVideoEmailNotification = 'generatedVideoEmailNotification';

const DEFAULT_OBJECT = {};
const DEFAULT_ARRAY = [];

let cancelBatches = [];

const MIN_VIDEO_DURATION = 1;
const MAX_VIDEO_DURATION = 10;
const MAX_VIDEO_DURATION_LIMITED = 5;
const DEFAULT_VIDEO_DURATION = 5;

export const VIDEO_STRENGTH = {
  min: 0.0,
  max: 100.0,
  default: 70,
  increment: 5
}

const FINALIZE_STRENGTH = {
  min: 0.0,
  max: 100.0,
  default: 80,
  increment: 5
}

export const ASPECT_RATIOS = {
  square: {label: <span><CropSquare/> Square</span>, value: 'square', labelCollapsed: 'Square'},
  landscape: {
    label: <span><CropLandscapeOutlined/> Landscape</span>,
    value: 'landscape',
    labelCollapsed: 'Landscape'
  },
  portrait: {label: <span><CropPortraitOutlined/> Portrait</span>, value: 'portrait', labelCollapsed: 'Portrait'},
}

const VideoGenerator = ({limitedVideoGenerator = true}) => {

  const {auth} = useContext(AuthContext);
  //double-check people aren't going around the URL
  if (!auth.user.video_generator) limitedVideoGenerator = true;

  return (
    <div className="w-100 video-generator">
      <PageTitle
        titleHeading={"Video Generator" + (limitedVideoGenerator ? "" : " Plus")}
        titleDescription="Generate game videos based on your own videos, images and words."
      >
      </PageTitle>
      <VideoGeneratorResults limitedVideoGenerator={limitedVideoGenerator}/>
    </div>
  )
};

export const VideoGeneratorResults = ({
                                        fullVersion = true,
                                        artStyle,
                                        initialValues,
                                        limitedVideoGenerator = true
                                      }) => {

  const {cache, setCacheValue} = useContext(CacheContext);
  const {call, loading} = useContext(APIContext);

  const [currentBatchId, setCurrentBatchId] = useState(undefined);

  useEffect(() => {
    call(getGeneratedVideos).then(response => {
      if (response.ok) {
        setCacheValue('videos', response.body);
      }
    })
  }, []);

  const loadingGenerateVideo = loading[generateVideo];
  const {projects, selectedProjectId, videos = DEFAULT_ARRAY} = cache;
  const project = useMemo(() => {
    return (projects || []).find(project => project._id === selectedProjectId) || {};
  }, [projects, selectedProjectId]);


  function cancelGenerationWrapper() {
    if (currentBatchId) {
      cancelBatches.push(currentBatchId);
      call(cancelGeneration, {generationId: currentBatchId}, {hideErrorMessage: true});
    }
    setCurrentBatchId(undefined);
  }

  const generate = async (values) => {
    cancelGenerationWrapper();
    let data = {
      request_id: PerformanceUtils.generateId(),
      filters: {
        genres: values.genres,
        colors: values.selectedColors,
        duration: values.duration,
      },
      art_style: values.art_style,
      aspect_ratio: values.aspect_ratio,
      preserve_color: values.preserve_color,
      preserve_composition: values.preserve_composition,
      preserve_details: values.preserve_details,
      initial_image: values.initial_image,
      initial_video: values.initial_video,
      video_strength: values.video_strength,
      initial_video_time_crop: values.initial_video_time_crop,
      initial_video_size_crop: values.initial_video_size_crop,
      mask_image: values.mask_image,
      propagate_mask: values.propagate_mask,
      ...convertUniversalInput(values.search),
    };

    setCurrentBatchId(data.request_id);
    let response = await call(generateVideo, {data});
    if (response.ok) {
      let newVideo = response.body;
      setCacheValue('videos', [newVideo, ...videos]);
    }
    setCurrentBatchId(undefined);
  };

  return (
    <div className="generator-wrapper">
      <div className="form-wrapper pb-3">
        <VideoGeneratorForm
          generate={generate}
          project={project}
          loadingGenerate={loadingGenerateVideo}
          onCancel={cancelGenerationWrapper}
          fullVersion={fullVersion}
          artStyle={artStyle}
          initialValues={initialValues}
          limited={limitedVideoGenerator}
        />
      </div>
      <LoadingTip
        style={{marginLeft: "60px", marginTop: "30px", marginBottom: "30px"}}
        section={LOADING_TIPS_SECTIONS.videoGenerator}
        visible={loadingGenerateVideo || videos?.length === 0}
        key={loadingGenerateVideo}
      />
      <div className="main-generator-content">
        <GeneratedVideos videos={videos} limited={limitedVideoGenerator}/>
      </div>
    </div>
  )
}

const GeneratedVideos = ({videos = DEFAULT_ARRAY, limited}) => {
  const PAGE_SIZE = 10;
  return (
    <>
      {videos.map((video, index) => (
        <>
          <LudoLazyLoad
            enable={true}
            offset={0}
            height={500}
            once
            key={"lazy" + video._id} // The unique key for React list items
            scrollContainer=".app-content--inner__wrapper"
          >
            <GeneratedVideo key={video._id} video={video} limited={limited}/>
          </LudoLazyLoad>
          {(index + 1) % PAGE_SIZE === 0 && <PageDivider page={(index + 1) / PAGE_SIZE}/>}
        </>
      ))}
    </>
  );
}

const PageDivider = ({page}) => {
  return (
    <div className="page-divider">
      <hr/>
      <span>Page {page}</span>
    </div>
  )
}

const GeneratedVideo = ({video, limited}) => {
  const history = useHistory();

  const {date, initial_payload = DEFAULT_OBJECT, progress, failed, videos = DEFAULT_ARRAY} = video;
  const {
    hints = DEFAULT_ARRAY,
    initial_image,
    initial_video,
    filters,
    art_style,
    aspect_ratio,
    preserve_composition,
    preserve_color,
    preserve_details,
    video_strength,
    initial_video_time_crop,
    initial_video_size_crop,
    mask_image,
  } = initial_payload;

  const {track} = useContext(SocketContext);
  const {cache, setCacheValue} = useContext(CacheContext);
  const {call} = useContext(APIContext);
  const {auth} = useContext(AuthContext);

  const email = auth.user.email;

  const videosLength = videos?.length || 0;

  const [urlFile, setUrlFile] = useState(null);
  const [initialVideoFiles, setInitialVideoFiles] = useState(null);
  const [initialImageFiles, setInitialImageFiles] = useState(null);
  const [shouldCancel, setShouldCancel] = useState(false);
  const [cancelling, setCancelling] = useState(false);
  const [cancelConfirmation, setCancelConfirmation] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [menuNameAnchorEl, setMenuNameAnchorEl] = useState(null);
  const [finalizeMenuAnchorEl, setFinalizeMenuAnchorEl] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(Math.max(0, videosLength - 1));
  const [finalizeStrength, setFinalizeStrength] = useState(FINALIZE_STRENGTH.default);

  const finishedPreview = videosLength >= 1;
  const finishedUpsample = videosLength >= 2;
  const isGenerating = progress?.current !== undefined && !failed;
  const isGeneratingUpscale = finishedPreview && isGenerating;
  const isGeneratingPreview = !finishedPreview && isGenerating;

  const previewLabel = "Result";
  const maxDuration = limited ? MAX_VIDEO_DURATION_LIMITED : MAX_VIDEO_DURATION;

  useEffect(() => {
    setSelectedIndex(Math.max(0, videosLength - 1));
  }, [videosLength]);

  const selectedVideo = videos[selectedIndex];
  const selectedVideoUrl = selectedVideo?.url;

  useEffect(() => {
    if (selectedVideoUrl) {
      setUrlFile(undefined);
      fileFromImage({url: selectedVideoUrl}).then(setUrlFile);
    }
  }, [selectedVideoUrl]);

  useEffect(() => {
    if (initial_image?.url) fileFromImage(initial_image).then(file => setInitialImageFiles([file]));
  }, [initial_image]);

  const initialVideoUrl = initial_video?.url;

  useEffect(() => {
    if (initialVideoUrl) {
      fileFromImage({url: initialVideoUrl}).then(file => {
        setInitialVideoFiles([file])
      });
    }
  }, [initialVideoUrl]);

  const hintsText = useMemo(() => hints.map(h => `"${h}"`).join(', '), [hints]);

  async function onCancel(shouldRemove = false) {
    if (cancelling) return;

    if (shouldCancel || shouldRemove) {
      track('generated-video.cancel', {id: video._id});
      setMenuAnchorEl(null);
      setCancelling(true);
      if (shouldRemove) setCancelConfirmation(true);
      let response = await call(cancelGeneratedVideo, {id: video._id, remove: shouldRemove});
      if (response.ok) {
        let id = response.body?._id;
        if (!id) {
          let newCacheVideos = cache.videos;
          newCacheVideos = newCacheVideos.filter(v => v._id !== video._id);
          setCacheValue('videos', newCacheVideos);
        } else {
          updateVideos(response.body);
        }
      }
      setCancelling(false);
      setShouldCancel(false);
    } else {
      setShouldCancel(true);
      setTimeout(() => {
        setShouldCancel(prevState => {
          if (prevState) return false;
          return prevState;
        })
      }, 3000);
    }
  }

  function clickedFinalize(event) {
    setFinalizeMenuAnchorEl(event.currentTarget)
  }

  async function finalize() {
    track('generated-video.finalize', {id: video._id});
    setFinalizeMenuAnchorEl(null)
    let response = await call(upscaleGeneratedVideo, {id: video._id, strength: finalizeStrength});
    if (response.ok) {
      updateVideos(response.body);
    }
  }

  function updateVideos(newVideo) {
    let newVideos = cache.videos.map(v => {
      if (v._id === newVideo._id) return newVideo;
      return v;
    })
    setCacheValue('videos', newVideos);
  }

  async function toggleNotification() {
    let valueToSet = !video.notification;
    video.notification = valueToSet;
    let response = await call(generatedVideoEmailNotification, {id: video._id, value: valueToSet});
    if (response.ok) {
      updateVideos(response.body);
    }
  }

  function toggleMenu(event) {
    setMenuAnchorEl(menuAnchorEl ? null : event.currentTarget);
  }

  function toggleNameMenu(event) {
    setMenuNameAnchorEl(menuNameAnchorEl ? null : event.currentTarget);
  }

  function deleteVideo() {
    setCancelConfirmation(true);
    setMenuAnchorEl(null);
  }

  function onEdit(videoToUse) {
    track('generated-video.make-new-version', {id: video._id});
    let data = JSON.parse(JSON.stringify(initial_payload));
    data = {
      ...data,
      filters: undefined,
      hints: undefined,
      initial_video: videoToUse?.url ? videoToUse : undefined,
      initial_image: data.initial_image?.url ? data.initial_image : undefined,
      genres: data.filters?.genres || [],
      selectedColors: data.filters?.colors || [],
      aspect_ratio: data.aspect_ratio || data.initial_video?.url ? undefined : ASPECT_RATIOS.portrait.value,
      duration: data.filters?.duration || data.initial_video?.url ? undefined : maxDuration,
      search: data.hints.map(textToUniversalOption),
      mask_image: data.mask_image,
      propagate_mask: data.propagate_mask,
    }
    let url = limited ? '/video-generator' : '/video-generator-plus';
    history.push(url, {data});
    setMenuAnchorEl(null)
  }

  const progressPercent = progress?.current !== undefined ? (progress.current / progress.total) * 100 : 0;

  const minProgress = Math.max(progressPercent || 0, 15);

  const progressStyle = {
    left: `calc(${(minProgress) / 2 + "%"} - 10px)`
  }

  let retainText = [];
  if (preserve_details) retainText.push("Details");
  if (preserve_composition) retainText.push("Composition");
  if (preserve_color) retainText.push("Color");

  const videoNames = useMemo(() => {
    if (videos.length === 0) return [previewLabel];
    const numberedFinals = videos.length > 2;
    return videos.map((video, index) => {
      if (video.video_type === "preview") {
        return previewLabel;
      } else {
        return `Final${numberedFinals ? ` ${index}` : ''}`;
      }
    })
  }, [videos])

  const selectionName = videoNames[selectedIndex];

  const showThumbnail = isGenerating && progress?.images?.length > 0;

  const options = useMemo(() => {
    const result = [];
    if (isGenerating) return result;
    /*if (finishedPreview && !finishedUpsample && !limited) {
      result.push({
        name: "Finalize Video",
        buttonIcon: <DeblurOutlined/>,
        menuIcon: <DeblurOutlined className="text-secondary"/>,
        onClick: clickedFinalize,
      })
    }*/
    if (selectedVideo) {
      result.push({
        name: `Download ${selectionName}`,
        buttonIcon: <DownloadOutlined/>,
        menuIcon: <DownloadOutlined className="text-secondary"/>,
        onClick: () => download(selectedIndex),
      })
    }
    /*if (finishedUpsample && !limited) {
      result.push({
        name: `Generate New Upsample`,
        buttonIcon: <DeblurOutlined/>,
        menuIcon: <DeblurOutlined className="text-secondary"/>,
        onClick: clickedFinalize,
      })
    }*/
    result.push({
      name: `Make New Version`,
      buttonIcon: <EditOutlined/>,
      menuIcon: <EditOutlined className="text-secondary"/>,
      onClick: () => onEdit(initial_payload.initial_video),
    })
    if (selectedVideo) {
      result.push({
        name: `Edit Result`,
        buttonIcon: <EditOutlined/>,
        menuIcon: <EditOutlined className="text-secondary"/>,
        onClick: () => onEdit(selectedVideo),
      })
    }
    result.push({
      name: `Delete Video Group`,
      buttonIcon: <DeleteOutline/>,
      menuIcon: <DeleteOutline className="text-secondary"/>,
      onClick: deleteVideo,
    })
    return result;
  }, [selectionName, selectedVideo, urlFile, finishedPreview, shouldCancel, finalize, onEdit, deleteVideo, menuAnchorEl, isGenerating]);

  async function download(index = selectedIndex) {
    let url = videos[index]?.url
    if (url) {
      track('generated-video.download', {id: video._id, index, name: videoNames[selectedIndex]});
      let name = `${hints.join(', ')}`;
      if (videoNames.length > 1) name += ` (${videoNames[selectedIndex]})`;
      const urlToBase64 = async () => {
        const response = await fetch(url);
        const blob = await response.blob();
        let file = new File([blob], name, {type: blob.type || "video/mp4"});
        return toBase64(file, false);
      }

      let link = document.createElement('a');
      link.download = name+".mp4";
      link.href = await urlToBase64();
      link.click();
      setMenuAnchorEl(null)
    }
  }

  return (
    <div className="generated-video">
      <div className="date">
        {moment.unix(date).format('MMM D YYYY - h:mm a')}
      </div>
      <div className="content">
        <div className="media">
          {urlFile && !isGenerating && <ThumbnailPreview files={[urlFile]} squareSize={500}/>}
          {showThumbnail &&
            <Gif key={progress.images[0]?.url} images={progress?.images} squareSize={500}/>}
          {isGenerating && !showThumbnail &&
            <div className="gif-placeholder"><CircularProgress size={60}/></div>}
          {failed && !selectedVideo && <div className="error">
            <ErrorOutline className="error-icon"/>
            <span>There was a problem with your request, please try again.</span>
          </div>}
        </div>
        <div className="meta">
          <div className="name">
            <span className="current-name" onClick={toggleNameMenu}>
              {selectionName} <ArrowDropDown className="font-size-xxxl"/>
            </span>
            {urlFile && <div className="clickable" onClick={() => download()}>
              <DownloadOutlined/>
            </div>}
            <ShowIf condition={!!menuNameAnchorEl}>
              <Menu
                anchorEl={menuNameAnchorEl}
                keepMounted
                open={!!menuNameAnchorEl}
                onClose={() => setMenuNameAnchorEl(null)}
              >
                {videoNames.map((name, index) => (
                  <MenuItem
                    onClick={() => {
                      setSelectedIndex(index);
                      setMenuNameAnchorEl(null);
                    }}
                    key={index}
                  >
                    <ListItemText primary={
                      <span className="text-secondary font-weight-bold">{name}</span>
                    }/>
                  </MenuItem>
                ))}
              </Menu>
            </ShowIf>
          </div>
          {hints.length > 0 && <span className="hints">{hintsText}</span>}
          {(initialVideoFiles || initialImageFiles) && <div className="input-media">
            {initialImageFiles &&
              <ThumbnailPreview key="1" files={initialImageFiles} squareSize={80} squareFit="cover"/>}
            {initialVideoFiles && (
              <>
                <ThumbnailVideo
                  url={initialVideoFiles[0]?.preview || initialVideoFiles[0]?.image?.url}
                  width={80}
                  height={80}
                  start={(initial_video_time_crop || [])[0]}
                  end={(initial_video_time_crop || [])[1]}
                />
                <div className="video-meta">
                  {video_strength &&
                    <div className="meta-line"><span>Strength:</span><span
                      className="value">{video_strength}</span>
                    </div>}
                  {retainText.length > 0 && <div className="meta-line"><span>Retain:</span><span
                    className="value">{retainText.join(', ')}</span></div>}
                </div>
              </>
            )}
          </div>}
          <div className="chips">
            <FilterPanelData
              values={{
                art_style,
                aspect_ratio, ...filters,
                initial_video_time_crop,
                initial_video_size_crop,
                mask_image
              }}/>
          </div>
          {progress?.message && !failed && <div className="progress">
            <div className="message">{progress?.message}</div>
            <div className="bar-wrapper">
              <div className="bar">
                <LinearProgress
                  variant="determinate"
                  value={minProgress}
                />
                <span className="progress-label"
                      style={progressStyle}>{parseFloat(progressPercent).toFixed(0) + "%"}</span>
              </div>
              <div className="cancel" onClick={() => onCancel()}>
                {!cancelling && shouldCancel && <span>Cancel</span>}
                {cancelling ? <CircularProgress size={15}/> : <CloseOutlined className="font-size-lg"/>}
              </div>
            </div>
          </div>}
          {isGeneratingUpscale &&
            <div className="notification">
              <Checkbox
                checked={!!video.notification}
                className="p-0 mr-2"
                onChange={(event) => {
                  toggleNotification()
                }}
              />
              <span>Notify me by email when ready</span> <Hint
              hint={`Ludo will send an email to ${email} when the generation is completed`}
            /></div>}
          {isGeneratingPreview &&
            <span
              className="warning">You can keep submitting more generation requests in the meantime.</span>}
          <div className="actions">
            {options.length > 0 && <MyButton className="gradient"
                                             onClick={options[0].onClick}>
              {options[0].buttonIcon} {options[0].name}
            </MyButton>}
            {options.length > 1 &&
              <IconButton component="span" aria-label="more actions" className="text-secondary"
                          onClick={toggleMenu}>
                <MoreVertIcon style={{height: "22px"}}/></IconButton>}
            <ShowIf condition={!!finalizeMenuAnchorEl}>
              <Menu
                anchorEl={finalizeMenuAnchorEl}
                keepMounted
                open={!!finalizeMenuAnchorEl}
                onClose={() => setFinalizeMenuAnchorEl(null)}
              >
                <div style={{
                  width: "320px",
                  paddingRight: "33px", paddingLeft: "33px",
                }}>
                  <div className="slider py-4">
                    <Typography id="discrete-slider-restrict" className="title" gutterBottom>
                      <span className="title-span">Upsample fidelity</span>
                      <Hint
                        iconClassName="pt-0"
                        hint="Higher values will stay closer to the preview video, lower values allow more creative freedom"
                      />
                    </Typography>
                    <div style={{marginTop: "30px"}}>
                      <OldSlider
                        className="slider-primary"
                        track="inverted"
                        value={finalizeStrength}
                        step={FINALIZE_STRENGTH.increment}
                        valueLabelDisplay="on"
                        min={FINALIZE_STRENGTH.min}
                        onChange={(event, value) => {
                          setFinalizeStrength(value)
                        }}
                        max={FINALIZE_STRENGTH.max}
                        marks={[{
                          value: FINALIZE_STRENGTH.min,
                          label: "" + FINALIZE_STRENGTH.min
                        }, {
                          value: FINALIZE_STRENGTH.max,
                          label: "" + FINALIZE_STRENGTH.max
                        }]}
                      />
                    </div>
                    <MyButton
                      className="gradient"
                      onClick={finalize}
                      style={{marginTop: 0}}
                    >
                      <DeblurOutlined className="mr-2 text-white"/> Generate Upsample
                    </MyButton>
                  </div>
                </div>
              </Menu>
            </ShowIf>
            <ShowIf condition={!!menuAnchorEl}>
              <Menu
                anchorEl={menuAnchorEl}
                keepMounted
                open={!!menuAnchorEl}
                onClose={() => setMenuAnchorEl(null)}
              >
                {options.slice(1).map(option => (
                  <MenuItem onClick={option.onClick} key={option.name}>
                    <ListItemIcon>
                      {option.menuIcon}
                    </ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={{className: "text-secondary font-weight-bold pr-5"}}
                      primary={option.name}/>
                  </MenuItem>
                ))}
              </Menu>
            </ShowIf>
            <ConfirmDialog
              title="Delete Video Group?"
              textElement={
                <span>This will delete this video's versions forever.</span>
              }
              onCancel={() => setCancelConfirmation(false)}
              onConfirm={() => onCancel(true)}
              open={cancelConfirmation}
              confirmLabel="Delete"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

const Gif = ({images, ...props}) => {

  const [files, setFiles] = useState([]);
  const [index, setIndex] = useState(0);

  useEffect(() => {

    let interval;

    Promise.all(images.map(async image => {
      return fileFromImage(image);
    })).then(files => {
      setFiles(files);
      setInterval(() => {
        setIndex(prevState => (prevState + 1) % files.length);
      }, 1000);
    });

    return () => {
      clearInterval(interval);
    }

  }, [images]);

  const file = files[index];

  return file ? <ThumbnailPreview files={[file]} {...props}/> : null;
}

export async function fileFromImage(image) {
  const urlToObject = async (url) => {
    let extension = url.split('.').pop() || 'jpg';
    const response = await fetch(url);
    const blob = await response.blob();
    return new File([blob], `file.${extension}`, {type: blob.type});
  }

  let proxyUrl = convertProxyUrl(image, true);
  let file = await urlToObject(proxyUrl);
  file.preview = URL.createObjectURL(file);
  file.image = image;
  return file;
}

const VideoGeneratorForm = ({
                              generate,
                              project = DEFAULT_OBJECT,
                              loadingGenerate,
                              onCancel,
                              fullVersion,
                              artStyle,
                              initialValues,
                              limited
                            }) => {

  const {cache} = useContext(CacheContext);
  const {auth} = useContext(AuthContext);
  const {loading} = useContext(APIContext);

  const loadingUploadImages = loading[uploadImage];

  const maxDuration = limited ? MAX_VIDEO_DURATION_LIMITED : MAX_VIDEO_DURATION;

  const {
    genres = DEFAULT_ARRAY,
    colors = DEFAULT_ARRAY,
    generationStyles = DEFAULT_ARRAY,
  } = cache;
  const [persistedData, setPersistedData] = useState();

  const formKey = "VideoGenerator5" + limited + project._id + (!fullVersion ? "-small-" : "") + JSON.stringify(initialValues || {});
  const [collapsed, setCollapsed] = usePersistedState(formKey + ".collapsed", true, true);

  const finalInitialValues = initialValues || {
    search: [],
    genres: initialValues?.genres || auth.user.genres || [],
    selectedColors: [],
    art_style: artStyle || generationStyles[0],
    preserve_color: false,
    preserve_composition: true,
    preserve_details: true,
    aspect_ratio: ASPECT_RATIOS.portrait.value,
    initial_image: undefined,
    initial_video: undefined,
    mask_image: undefined,
    propagate_mask: false,
    initial_video_size_crop: [],
    initial_video_time_crop: [],
    video_strength: VIDEO_STRENGTH.default,
    duration: DEFAULT_VIDEO_DURATION,
  }

  const chipSize = {
    xs: 12,
    md: fullVersion ? 4 : 12,
    sm: fullVersion ? 6 : 12,
  };

  const convertedColors = useMemo(() => convertFormColors(colors), [colors]);

  function onVideosChanged(formik, video, timeCrop, sizeCrop, mask, propagateMask) {
    formik.setValues({
      ...formik.values,
      duration: video ? undefined : formik.values.duration || finalInitialValues.duration,
      aspect_ratio: video ? undefined : formik.values.aspect_ratio || finalInitialValues.aspect_ratio,
      initial_video: video,
      initial_video_time_crop: timeCrop,
      initial_video_size_crop: sizeCrop,
      mask_image: mask,
      propagate_mask: propagateMask
    });
  }

  function onImagesChanged(formik, initial_image) {
    formik.setValues({...formik.values, initial_image});
  }

  return (
    <>
      <Formik
        key={formKey}
        initialValues={finalInitialValues}
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={BySentenceValidationSchema}
        onSubmit={values => generate(values)}
      >
        {(formik) => (
          <>
            <FormikPersist name={formKey} onLoad={data => {
              setPersistedData(data)
            }}/>
            <ShowIf condition={!!persistedData}>
              <ChangeDataOnLocation
                initialValues={finalInitialValues}
                fields={Object.keys(finalInitialValues)}
              />
            </ShowIf>
            <Form>
              {!!persistedData && <div className="d-flex flex-column">
                <Grid container>
                  <Grid
                    item
                    container
                    justifyContent="flex-start"
                    alignItems="flex-end"
                  >
                    <Grid item sm={12} md={12} className="input-fields-wrapper">
                      <div className="d-flex input-fields full-border-radius mb-3">
                        <UniversalInput
                          label="Describe the game that you want to see in the video"
                          name="search"
                          formik={formik}
                          onSetData={(data) => {
                            formik.setFieldValue("search", data);
                          }}
                          value={formik.values.search}
                          allowed={["text"]}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={fullVersion ? 12 : 12} sm={fullVersion ? "auto" : 12}
                          md={fullVersion ? "auto" : 12}>
                      <div className="file-wrapper">
                        <div className="row">
                          <ReferenceVideo
                            key={formik.values.initial_video?.url || "none"}
                            video={formik.values.initial_video}
                            timeCrop={formik.values.initial_video_time_crop}
                            sizeCrop={formik.values.initial_video_size_crop}
                            initialMask={formik.values.mask_image}
                            initialPropagate={formik.values.propagate_mask}
                            onNewVideo={(...values) => {
                              onVideosChanged(formik, ...values)
                            }}
                            formik={formik}
                            defaultDuration={DEFAULT_VIDEO_DURATION}
                            maxDuration={maxDuration}
                            limited={limited}
                          />
                          <ReferenceImage
                            key={formik.values.initial_image?.url}
                            initialImage={formik.values.initial_image}
                            onFilesChanged={images => {
                              onImagesChanged(formik, images)
                            }}
                          />
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <FiltersButton collapsed={collapsed} setCollapsed={setCollapsed}
                                     page="image-generator"/>
                    </Grid>
                    <ShowIf condition={!collapsed}>
                      <div className="filters-form">
                        <Grid item xs={chipSize.xs} sm={chipSize.sm} md={chipSize.md}>
                          <FormikChipSelect
                            name="genres"
                            title="Genres"
                            values={genres}
                          />
                        </Grid>
                        <Grid item xs={chipSize.xs} sm={chipSize.sm} md={chipSize.md}>
                          <FormikChipSelect
                            name="selectedColors"
                            title="Colors"
                            className="color-picker"
                            showAvatar={(entry) => showColorAvatar(entry, convertedColors)}
                            menuClassName="color-picker"
                            itemClassName="text-capitalize no-hover-change color-picker-entry"
                            itemStyle={entry => {
                              let {rgb} = entry;
                              return {
                                fill: `rgb(${rgb[0]}, ${rgb[1]},${rgb[2]})`,
                              };
                            }}
                            checkboxStyle={entry => {
                              let {rgb, label} = entry;
                              let rgbColor = `rgb(${rgb[0]}, ${rgb[1]},${rgb[2]})`;
                              let border = label === "white" || label === "orange" ? "1px solid lightgrey" : "none";
                              return {
                                fill: rgbColor,
                                background: rgbColor,
                                color: rgbColor,
                                border
                              }
                            }}
                            options={convertedColors}
                          />
                        </Grid>
                        <Grid item xs={chipSize.xs} sm={chipSize.sm} md={chipSize.md} style={{alignSelf: "flex-end"}}>
                          <FormikSelectField
                            name="art_style"
                            label="Art Style"
                            options={generationStyles.map(value => {
                              return {
                                value,
                                label: (
                                  <div className="d-flex flex-column">
                                <span className="font-weight-bold">
                                  {value}
                                </span>
                                  </div>)
                              }
                            })}
                            fullWidth
                          />
                        </Grid>
                        {!formik.values.initial_video &&
                          <Grid item xs={chipSize.xs} sm={chipSize.sm} md={chipSize.md}>
                            <FormikSelectField
                              name="aspect_ratio"
                              label="Aspect Ratio"
                              options={Object.keys(ASPECT_RATIOS).map(key => {
                                let {label, value} = ASPECT_RATIOS[key];
                                return {
                                  value,
                                  label: (
                                    <div className="d-flex flex-column">
                                <span className="font-weight-bold">
                                  {label}
                                </span>
                                    </div>)
                                }
                              })}
                              fullWidth
                            />
                          </Grid>}
                        {!formik.values.initial_video &&
                          <Grid item xs={chipSize.xs} sm={chipSize.sm} md={chipSize.md}>
                            <FormikSlider
                              name="duration"
                              title="Duration"
                              min={MIN_VIDEO_DURATION}
                              max={maxDuration}
                            />
                          </Grid>}
                      </div>
                    </ShowIf>
                    <ShowIf condition={collapsed}>
                      <Grid item sm={12} md={12}>
                        <FilterPanel
                          ignore={['videos', 'images', 'canvas_state', 'propagate_mask', 'mask_image']}
                          convertedColors={convertedColors}
                          onExpand={() => setCollapsed(false)}
                        />
                      </Grid>
                    </ShowIf>
                  </Grid>
                  <div className="d-flex align-self-center">
                    <GeneratingButton
                      id="video-generator.generate"
                      label="Start New Generation"
                      loading={loadingGenerate}
                      onCancel={onCancel}
                      className="gradient"
                      style={{margin: 0, marginLeft: "10px"}}
                      disabled={loadingUploadImages}
                      trackOptions={{
                        ...formik.values,
                        mask_image: !!formik.values.mask,
                        search: convertUniversalInput(formik.values.search)
                      }}
                      loadProgressSecs={30}
                    />
                  </div>
                </Grid>
              </div>}
            </Form>
          </>
        )}
      </Formik>
    </>
  )
};

const FormikSlider = ({min = 0, max = 1, name, title, step = 0.1}) => {
  const formik = useFormikContext();

  const value = formik.values[name];

  return (
    <div className="pl-3 pr-5 pb-3" style={{display: "flex", flexDirection: "row", placeItems: "center", gap: "15px"}}>
      {title && <span style={{placeSelf: "flex-end"}} className="font-weight-bold">{title}:</span>}
      <OldSlider
        style={{marginTop: "20px"}}
        value={value}
        step={step}
        min={min}
        max={max}
        valueLabelDisplay="on"
        onChange={(event, value) => {
          formik.setValues({...formik.values, [name]: value});
        }}
      />
    </div>
  )
}

export const ThumbnailVideo = ({url, width, height, start = 0, end, children}) => {

  const playerRef = useRef(null);

  const [video, setVideo] = useState();
  const [progress, setProgress] = useState(null);
  const [player, setPlayer] = useState(null);
  const [playing, setPlaying] = useState(false);
  const [duration, setDuration] = useState();

  useEffect(() => {
    if (progress?.playedSeconds > end || progress?.played === 1) {
      onStop();
    }
  }, [progress, end, start]);

  useEffect(() => {
    if (player && duration) {
      onSeek(start, player, duration);
    }
  }, [player, start, duration]);

  function onDuration(duration) {
    setDuration(duration);
  }

  function onSeek(second, selectedPlayer = player, currentDuration = duration) {
    let fraction = (second / currentDuration)
    selectedPlayer.seekTo(fraction, 'fraction');
  }

  function onReady(newPlayer) {
    setPlayer(newPlayer);
  }

  function onPlay() {
    onSeek(start, player);
    setPlaying(true);
  }

  function onStop() {
    setPlaying(false);
    onSeek(start);
  }

  useEffect(() => {
    if (url) {
      fetch(url)
        .then(response => response.blob())
        .then(async blob => {
          if (!blob.type)
            blob = new Blob([blob], {type: 'audio/mpeg'});
          setVideo({url: URL.createObjectURL(blob)})
        })
    }
  }, [url]);

  return (
    <div className="video-thumbnail thumbnail-preview m-1" style={{width: `${width}px`, height: `${height}px`}}>
      {children}
      <div className="square-thumbnail">
        <div className="play-pause">
          {playing && <StopCircleOutlined onClick={onStop}/>}
          {!playing && <PlayCircleOutline onClick={onPlay}/>}
        </div>
        <ReactPlayer
          key={video?.url}
          url={video?.url}
          ref={playerRef}
          onReady={onReady}
          playing={playing}
          onDuration={onDuration}
          onProgress={setProgress}
          progressInterval={50}
          onError={console.log}
          height={height}
          width={width}
        />
      </div>
    </div>
  )
}

export default VideoGenerator;

const BySentenceValidationSchema = Yup.object().shape(
  {
    search: Yup.array()
      .min(1, 'Must provide at least one game description')
      .required('Must provide at least one game description'),
  }
);
