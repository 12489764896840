import React, {useContext, useState} from "react";
import APIContext from "context/APIContext";
import AuthContext from "context/AuthContext";
import {useSnackbar} from "notistack";
import {
  Grid,
  Typography,
} from "@material-ui/core";
import moment from "moment";
import CreditCards from "scenes/SubscriptionPage/CreditCards";
import ShowIf from "components/common/ShowIf";
import PlanCard from "scenes/SubscriptionPage/PlanCard";
import InvoicingDetails from "scenes/SubscriptionPage/SubscriptionFlow/InvoicingDetails";
import Stepper from "@material-ui/core/Stepper";
import StepConnector from "@material-ui/core/StepConnector";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import clsx from "clsx";
import Check from "@mui/icons-material/Check";
import {Form, Formik} from "formik";
import {FormikTextField} from "formik-material-fields";
import MyButton from "components/Controls/MyButton";
import * as Yup from "yup";
import {Alert} from "@material-ui/lab";
import config from "config/config.json";
import CacheContext from "context/CacheContext";
import TagManager from "react-gtm-module";
import {CheckOutlined, CreditCard, ViewColumn} from "@mui/icons-material";
import {usePostHog} from "posthog-js/react";

const subscribe = 'subscribe';

const STEPS = [
  {
    name: "Select Plan",
    icon: <ViewColumn
      className="font-size-xxxxl"
    />
  },
  {
    name: "Payment Details",
    icon: <CreditCard
      className="font-size-xxxxl"
    />
  },
  {
    name: "Confirmation",
    icon: <CheckOutlined
      className="font-size-xxxxl"
    />
  }
];

const SubscriptionFlow = ({plan, onSuccess, goBack}) => {

  const posthog = usePostHog();
  const {call, loading} = useContext(APIContext);
  const {setCacheValue} = useContext(CacheContext);
  const {setAuth} = useContext(AuthContext);
  const {enqueueSnackbar, closeSnackbar} = useSnackbar();
  const [activeStep, setActiveStep] = useState(1);

  async function onSubscribe() {
    let data = {planId: plan.id};
    let response = await call(subscribe, data);
    if (response.ok) {
      let subscription = response.body;
      if(subscription) {
        posthog?.capture('active-subscription');
        setAuth({subscription}, true, false);
        showMessage(`Success! Your ${subscription ?.plan?.name} subscription is now active!`, enqueueSnackbar, closeSnackbar);
      }
      setCacheValue('trialExpired', false);
      if(plan.interval === "one_time") {
        posthog?.capture('booster');
        showMessage(`Success! Your credits are now available!`, enqueueSnackbar, closeSnackbar);
      }

      posthog?.capture('payment');

      if (!!config.GOOGLE_TAG_ID) {
        TagManager.dataLayer({
          dataLayer: {
            event: 'subscription',
            value: (plan.amount || 0) / 100.0,
            currency: 'USD'
          },
        });
      }

      if (onSuccess) onSuccess(subscription);
    }
  }

  function onSelectedCard() {
    setActiveStep(2);
  }

  async function onStartSubscription() {
    return onSubscribe();
  }

  function goToStep(step) {
    if (step < activeStep) {
      if (step === 0)
        goBack();
      else
        setActiveStep(step);
    }
  }

  let {percent_off, amount_off} = plan;
  const hasDiscount = !!(percent_off || amount_off);

  let total = plan.amount || 0;
  if (hasDiscount) {
    if (percent_off) {
      total -= (total * percent_off) / 100;
    } else if (amount_off) {
      total -= amount_off;
    }
  }

  return (
    <div className="subscription-flow">
      <div className="d-block d-xl-flex my-2 justify-content-between text-align-center flex-column">
        <Typography variant="h4" className="my-2 font-weight-bold w-100">
          {plan.interval === "one_time" ? `Add Credits with the ${plan.name} plan` : `Subscribe to ${plan.name} plan` }
        </Typography>
      </div>
      <Header
        activeStep={activeStep}
        steps={STEPS}
        goToStep={goToStep}
      />
      <>
        <ShowIf condition={activeStep === 1}>
          <CreditCards
            detailsForm={true}
            whiteForm={false}
            onSelectedCard={onSelectedCard}
            message="Click a credit card below or add a new one"
          />
        </ShowIf>
        <ShowIf condition={activeStep === 2}>
          <Grid container direction="column" alignContent="center">
            <Grid item sm={12}>
              <Typography className="font-weight-bold mb-4 text-center" style={{maxWidth: "600px"}}>
                Congratulations, you're just one step away from unlocking all of our premium features!
              </Typography>
              <Typography className="font-weight-bold text-center" style={{maxWidth: "600px"}}>
                {plan.interval === "one_time" ? `By clicking the "Add Credits" button below, you'll confirm your one-time purchase of ${parseInt(plan.metadata.credits)} credits for $${total}. These credits will be added to your account immediately and can be used at any time.`:
                `By proceeding you'll confirm your ${plan.interval === "year" ? "annual" : plan.interval} subscription at the cost of $${total}. Remember, this charge will automatically renew each ${plan.interval}.`}
              </Typography>
            </Grid>
            <div className="plans-wrapper">
              <ShowIf condition={hasDiscount}>
                <div className="promo-code pb-4">
                  <Grid container>
                    <Grid item sm={12}>
                      <Alert severity="success" className="mt-2 w100">
                        Your promo code has been applied!
                      </Alert>
                    </Grid>
                  </Grid>
                </div>
              </ShowIf>
              <ShowIf condition={!hasDiscount && plan.interval === "year" || plan.interval === "one_time"}>
                <PromoCodeFields/>
              </ShowIf>
              <PaymentSummary
                plan={plan}
                onClick={onStartSubscription}
                total={total}
                loading={loading[subscribe]}
              />
            </div>
          </Grid>
        </ShowIf>
      </>
    </div>
  )
};

const PaymentSummary = ({plan, total, loading, onClick}) => {

  const interval = plan.interval;
  const description = plan.interval === "one_time"? " credit pack" : "subscription";
  const message = plan.interval === "one_time"? "Add Credits" : "Subscribe";
  const billedInterval = interval === "one_time"? "once" : interval+"ly";

  return (
    <div className="payment-summary">
      <div className="row">
        <b>Your {description}</b>
      </div>
      <div className="row">
        <div className="grow">{plan.name} plan</div>
        <div>${plan.amount} billed {billedInterval}</div>
      </div>
      <hr/>
      <div className="row">
        <div className="grow"><b>Pay Now</b></div>
        <div>${total}</div>
      </div>
      <div className="row">
        <div className="plan-action text-center">
          <MyButton
            id="subscription.subscribe-plan"
            trackOptions={plan}
            fullWidth
            color="secondary"
            size="large"
            confirmLabel="Downgrade"
            loading={loading}
            onClick={onClick}
            className="action-button">
            {message}
          </MyButton>
        </div>
      </div>
    </div>
  )
}

const addPromoCode = "addPromoCode";

const PromoCodeFields = () => {

  const {call, loading} = useContext(APIContext);
  const [success, setSuccess] = useState(false);

  async function onAddCode(values) {
    let response = await call(addPromoCode, {code: values.code});
    if (response.ok) {
      setSuccess(response.body.value);
    }
  }

  return (
    <div className="promo-code py-4">
      <Formik
        initialValues={{code: ""}}
        onSubmit={onAddCode}
        className="w-100"
        validationSchema={ValidationSchema}
      >
        <Form>
          <div className="promo-fields">
            <FormikTextField
              name="code"
              variant="outlined"
              size="small"
              placeholder="Promo code"
              disabled={loading[addPromoCode]}
            />
            <MyButton id="subscription.promo-code-add" size="medium" color="secondary"
                      loading={loading[addPromoCode]}>
              Add Promo Code
            </MyButton>
          </div>
          {success && <Alert severity="success" className="mt-2 w100">
            Promo code added successfully: {success}
          </Alert>}
        </Form>
      </Formik>
    </div>
  );
}

const Header = (
    {
      activeStep, steps, goToStep
    }
  ) => {

    const StepIcon = ({active, completed, icon}) => {
      return (
        <div
          className={clsx(
            'd-50 transition-base d-flex align-items-center bg-gray-400 justify-content-center rounded',
            {
              'd-80 bg-secondary text-white shadow-primary-sm': active,
              'd-50 bg-success text-white shadow-success-sm': completed
            }
          )}>
          {completed ? <Check className="completed"/> : steps[icon - 1].icon}
        </div>
      );
    };

    return (
      <div>
        <Stepper
          className="stepper-horizontal-1"
          alternativeLabel
          activeStep={activeStep}
          connector={<StepConnector/>}>
          {steps.map((step, index) => (
            <Step
              key={step.name}
              onClick={() => goToStep(index)}>
              <StepLabel
                StepIconComponent={StepIcon}>
                {step.name}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </div>
    )
  }
;

export function showMessage(message, enqueueSnackbar, closeSnackbar) {
  let key = 'Subscription' + moment().unix();
  enqueueSnackbar(message, {
    key,
    variant: "success",
    autoHideDuration: 5000,
    onClick: () => closeSnackbar(key)
  });
}

const ValidationSchema = Yup.object().shape({
  code: Yup.string()
    .required('No code provided'),
});

export default SubscriptionFlow;
