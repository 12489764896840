import React, {useContext, useState, useEffect} from 'react';
import APIContext from 'context/APIContext';
import AuthContext from 'context/AuthContext';
import {Formik, Form} from "formik";
import {FormikTextField} from 'formik-material-fields';
import {useHistory, useLocation, useParams} from "react-router";
import MyButton from "components/Controls/MyButton";
import * as Yup from "yup";
import {NavLink} from "react-router-dom";
import logoLarge from "assets/images/logo-white.png";
import CacheContext, {DEFAULT_CACHE} from "context/CacheContext";
import config from "config/config.json";
import ErrorBadge from "components/common/ErrorBadge";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import qs from "qs";
import ShowIf from "components/common/ShowIf";
import {Alert} from "@material-ui/lab";
import TagManager from "react-gtm-module";
import CreditCardForm from "components/common/CreditCardForm";
import usePersistedState from "hooks/usePersistedState";
import {usePostHog} from "posthog-js/react";
import PerformanceUtils from "../../helpers/PerformanceUtils";

const registerPaywall = "registerPaywall";
const registerPaywallTeam = 'registerPaywallTeam';
const getRegistrationEmail = 'getRegistrationEmail';
const startRegistration = 'startRegistration';

const STATES = {
  start: 'start',
  credentials: 'credentials',
  payment: 'payment',
  team: 'team',
}

const START_URL = "/register";
const CREDENTIALS_URL = "/register/credentials";
const PAYMENT_URL = "/register/payment";
const TEAM_URL = "/register/team";

const RegisterPaywall = () => {
  const location = useLocation();
  return <RegisterPaywallContent key={location.pathname}/>;
}

const MESSAGES = {
  [STATES.start]: "Join thousands of game studios already crafting tomorrow's hits with Ludo",
  [STATES.credentials]: "Please choose a password",
  [STATES.payment]: "Start Your 7-Day Free Trial Now!"
}

const RegisterPaywallContent = () => {

  const location = useLocation();
  const params = useParams();
  const posthog = usePostHog();
  const consent = true;
  const queryString = qs.parse(location.search, {ignoreQueryPrefix: true});
  const code = queryString?.code;
  const teamId = params.teamId;
  const teamEmail = params.email;

  const isStart = location.pathname === START_URL;
  const isPayment = location.pathname === PAYMENT_URL;
  const isCredentials = location.pathname === CREDENTIALS_URL;
  const isTeam = location.pathname.startsWith(TEAM_URL);

  const {call} = useContext(APIContext);
  const {setCache, cache} = useContext(CacheContext);
  const {setAuth} = useContext(AuthContext);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [codeError, setCodeError] = useState();
  const [state, setState] = useState(isPayment ? STATES.payment : STATES.start);
  const [successMessage, setSuccessMessage] = useState();
  const [values, setValues] = usePersistedState('register.values', {}, true);

  useEffect(() => {
    if (!!config.GOOGLE_TAG_ID) {
      TagManager.dataLayer({
        dataLayer: {
          event: 'start-registration',
        },
      });
    }
    posthog?.capture('start-registration');
  }, [])

  useEffect(() => {
    if (isStart) {
      setState(STATES.start);
    } else if (isPayment) {
      setState(STATES.payment);
    } else if (isCredentials) {
      setState(STATES.credentials);
    } else if (isTeam) {
      setState(STATES.team);
    }
  }, [isPayment, isCredentials, isStart, isTeam]);

  useEffect(() => {
    if (state === STATES.payment && (!values.password || !values.name)) {
      if (!values.code) {
        setState(STATES.start);
      } else {
        setState(STATES.credentials);
      }
    }
  }, [state]);

  useEffect(() => {
    if (code && code !== values.code) {
      posthog?.capture('confirm-email-registration');
      call(getRegistrationEmail, {code}, {setError: setCodeError}).then(response => {
        if (response.ok) {
          setValues({...values, email: response.body.value, code, name: response.body.value2});
        }
      });
    }
  }, [code, values.code]);

  useEffect(() => {
    if (teamId && teamEmail) {
      setValues({...values, teamId, email: teamEmail});
    }
  }, [teamId, teamEmail]);

  async function startRegistrationWrapper(values) {
    setLoading(true);
    setValues({email: values.email});
    let response = await call(startRegistration, {email: values.email, paywall: true}, {setError});
    if (response.ok) setSuccessMessage('Please check your email to continue your Ludo registration!');
    posthog?.capture('submit-email-registration');
    setLoading(false);
  }

  async function onSubmitCredentials(credentials) {
    setValues({...values, ...credentials});
    history.push(PAYMENT_URL);
    setState(STATES.payment);
  }

  async function onFinishPayment(token) {
    setError();
    setLoading(true);

    let auth = {
      name: values.name,
      password: values.password,
      payment_token: token,
      code: values.code
    };

    let response = await call(registerPaywall, {auth});
    setLoading(false);
    if (response.ok) {

      posthog?.capture('add-credit-card');
      if (!!config.GOOGLE_TAG_ID) {
        TagManager.dataLayer({
          dataLayer: {
            event: 'add-credit-card',
            user_data: {
              email_address: values.email,
              sha256_email_address: await PerformanceUtils.sha256(values.email)
            }
          },
        });
      }

      let joinTeamId = cache.joinTeamId;
      localStorage.clear();
      sessionStorage.clear();
      onLoginPayload(response.body, joinTeamId)
    } else {
      setError(response.body.message)
    }
  }

  async function onSubmitTeam(credentials) {
    setError();
    setLoading(true);

    let auth = {
      name: credentials.name || values.name,
      password: credentials.password || credentials.password,
      team_id: credentials.teamId || values.teamId,
      email: credentials.email || values.email,
    };

    let response = await call(registerPaywallTeam, {auth});
    setLoading(false);
    if (response.ok) {
      localStorage.clear();
      sessionStorage.clear();
      onLoginPayload(response.body)
    } else {
      setError(response.body.message)
    }
  }

  function onLoginPayload(payload) {
    setAuth(payload, false);
    let selectedProjectId = payload.user.selected_project || DEFAULT_CACHE.selectedProjectId;
    let path = cache.unauthenticatedPath || "/";
    setCache({
      ...DEFAULT_CACHE,
      selectedProjectId,
      projectsOpen: !selectedProjectId,
      unauthenticatedPath: undefined,
    });
    if (consent) {
      if (!!config.GOOGLE_TAG_ID) {
        TagManager.dataLayer({
          dataLayer: {
            event: 'registration',
          },
        });
      }
      posthog?.capture('registration');
    }
    history.push(path);
  }

  return (
    <div className="app-wrapper bg-gradient min-vh-100 register">
      <div className="app-main min-vh-100 top-bg-content">
        <div className="app-content p-0">
          <div className="app-content--inner d-flex align-items-center">
            <div className="app-content--inner__wrapper">
              <div className="flex-grow-1 w-100 d-flex align-items-center">
                <div className="bg-composed-wrapper--content pb-5 px-2 mb-5">
                  <div className="auth-form-wrapper">
                    <div className="text-center mb-4 pb-2">
                      <h1 className="display-4 font-weight-bold font-size-xxxxl">
                        <img
                          width="225"
                          alt="Ludo"
                          className="d-block m-auto logo"
                          src={logoLarge}
                        />
                        {MESSAGES[state]}
                      </h1>
                    </div>
                    <ShowIf condition={state === STATES.start}>
                      <StartRegistrationForm
                        initialValues={values}
                        onSubmit={startRegistrationWrapper}
                        loading={loading}
                        error={error}
                        successMessage={successMessage}
                        email={values.email}
                      />
                    </ShowIf>
                    <ShowIf condition={values.email && state === STATES.credentials}>
                      <CredentialsForm
                        initialValues={values}
                        onSubmit={onSubmitCredentials}
                        loading={loading}
                        error={error}
                        successMessage={successMessage}
                      />
                    </ShowIf>
                    <ShowIf condition={values.email && state === STATES.team}>
                      <CredentialsForm
                        initialValues={values}
                        onSubmit={onSubmitTeam}
                        loading={loading}
                        error={error}
                        successMessage={successMessage}
                      />
                    </ShowIf>
                    <ShowIf condition={state === STATES.payment && !codeError}>
                      <span className="info">Enter your card details to start your 7-day free trial. You won't be charged until after the trial ends, at which point you'll be enrolled in the $20/month Indie plan. Remember, you can switch plans or cancel anytime.
                      <span className="info mt-2">
                         <a
                           href="https://ludo.ai/pricing" target="_blank">You can find out more about our plans here</a></span>
                      </span>
                      <PaymentForm
                        onSubmit={onFinishPayment}
                        name={values.name}
                        loading={loading}
                        error={error}
                      />
                    </ShowIf>
                    <ErrorBadge error={codeError}/>
                    {state === STATES.start && <div className="text-center pt-4">
                      Already on Ludo?{' '}
                      <NavLink to="/login" className="text-white font-weight-bold">
                        Login here
                      </NavLink>
                    </div>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const StartRegistrationForm = ({onSubmit, loading, error, successMessage, email = ""}) => {
  return (<Formik
      initialValues={{email}}
      onSubmit={onSubmit}
      validationSchema={StartValidationSchema}
    >
      {({isValid, errors, dirty, validateField, setFieldValue}) => (
        <Form>
          <div className="text-center">
            Enter your email below. We’ll email you a link for verification.
          </div>
          <div className="mb-2 mt-2 pt-4">
            <label className="font-weight-bold mb-1 caps">
              Enter your email
            </label>
            <FormikTextField
              name="email"
              variant="outlined"
              size="small"
              fullWidth
              type="email"
              disabled={loading}
            />
          </div>
          <ErrorBadge error={error}/>

          <ShowIf condition={!!successMessage}>
            <Alert severity="success" className="mt-5 mb-2">
              {successMessage}
            </Alert>
          </ShowIf>

          <ShowIf condition={!successMessage}>
            <div className="text-center">
              <MyButton
                className="mt-4 mb-2 font-weight-bold blue"
                style={{width: "100%"}}
                loading={loading}
              >
                Get Started!
              </MyButton>
            </div>
          </ShowIf>
        </Form>
      )}
    </Formik>
  );
}

const CredentialsForm = ({onSubmit, loading, error, successMessage, initialValues}) => {

  const [showPassword, setShowPassword] = useState(false);

  return (<Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={PasswordValidationSchema}
    >
      {({isValid, errors, dirty, validateField, setFieldValue}) => (
        <Form>
          <div className="mb-4">
            <label className="font-weight-bold mb-1 caps">
              Email
            </label>
            <FormikTextField
              name="email"
              variant="outlined"
              size="small"
              fullWidth
              type="email"
              disabled={true}
            />
          </div>
          <div className="mb-4">
            <label className="font-weight-bold mb-1 caps">
              Full Name
            </label>
            <FormikTextField
              name="name"
              variant="outlined"
              size="small"
              fullWidth
              disabled={loading}
            />
          </div>
          <div className="mb-4">
            <div className="d-flex justify-content-between position-relative">
              <label className="font-weight-bold mb-1">
                Password (6 or more characters)
              </label>
              <div
                className="d-flex"
                style={{position: "absolute", right: "5px", top: 0, color: "white", cursor: "pointer"}}
                onClick={() => setShowPassword(!showPassword)}
              >
                <div className="mr-3">
                  {showPassword ? <VisibilityOff/> : <Visibility/>}
                </div>
                <span style={{top: "1px", position: "relative", width: "35px", textAlign: "right"}}>
                  {showPassword ? "Hide" : "Show"}
                </span>
              </div>
            </div>
            <FormikTextField
              name="password"
              variant="outlined"
              size="small"
              fullWidth
              type={showPassword ? "text" : "password"}
              disabled={loading}
            />
          </div>
          <ErrorBadge error={error}/>

          <ShowIf condition={!!successMessage}>
            <Alert severity="success" className="mt-5 mb-2">
              {successMessage}
            </Alert>
          </ShowIf>

          <div className="mt-4 text-white mb-4 opacity-7">
          <span>By proceeding, you agree to our <a tabIndex="-1" href="https://ludo.ai/privacy-policies"
                                                   target="_blank"
                                                   className="text-white underline">Terms of Service and Privacy Policy</a></span>
          </div>

          <ShowIf condition={!successMessage}>
            <div className="text-center">
              <MyButton
                className="mt-4 mb-2 font-weight-bold blue"
                style={{width: "100%"}}
                loading={loading}
              >
                Get Started!
              </MyButton>
            </div>
          </ShowIf>
        </Form>
      )}
    </Formik>
  );
}

const PaymentForm = ({onSubmit, loading, error, name}) => {
  return (
    <div className="payment-form">

      <CreditCardForm
        whiteForm={false}
        onTokenGenerated={onSubmit}
        onCancel={() => {
        }}
        slim={true}
        showStripeText={true}
        buttonText="Start My Free Trial"
        name={name}
        loading={loading}
      />
      <ErrorBadge error={error}/>
      {/*<div className="text-center">
        <MyButton
          className="mt-5 mb-2 font-weight-bold blue"
          style={{width: "100%"}}
          loading={loading}
        >
          Complete Registration
        </MyButton>
      </div>*/}
    </div>
  );
}

export default RegisterPaywall;

const StartValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Must be a valid email')
    .required('No email provided'),
});

const PasswordValidationSchema = Yup.object().shape({
  name: Yup.string()
    .required('No name provided'),
  email: Yup.string()
    .email('Must be a valid email')
    .required('No email provided'),
  password: Yup.string()
    .required('No password provided')
    .min(6, 'Password is too short - should be 6 characters minimum'),
});
