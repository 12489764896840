import {useBus, useListener} from "react-bus";
import React, {useContext, useEffect, useMemo, useState} from "react";
import ShowIf from "components/common/ShowIf";
import {GeneratingButton} from "components/Controls/MyButton";
import {MenuTopBar} from "pages/GDD3/GDDSideMenu";
import CacheContext from "context/CacheContext";
import {TextResult} from "pages/FeatureGenerator";
import {FAVORITE_TYPES, filterFavorites} from "pages/Favorites";
import APIContext from "context/APIContext";
import {
  AutoAwesomeOutlined,
  BuildOutlined,
  BurstModeOutlined,
  FavoriteOutlined, ModeEditOutlined,
  SearchOutlined,
  Sync, TryOutlined
} from "@mui/icons-material";
import GDDContext from "context/GDDContext";
import PerformanceUtils from 'helpers/PerformanceUtils';
import {Chip, CircularProgress, Grid} from "@material-ui/core";
import {prepareGeneratedImage} from "pages/ImageGenerator";
import ImageGallery from "components/common/ImageGallery";
import {
  MoodboardFavorites,
  MoodboardGenerator,
  MoodboardSearch,
} from "pages/GDD3/GDDSideMenu/MoodboardMenu";
import UniversalInput, {convertUniversalInput} from "components/Controls/UniversalInput";
import {Form, Formik} from "formik";
import SocketContext from "context/SocketContext";
import Chat from "components/common/Chat";
import {GeneratedImage} from "../../../components/common/GeneratedGameCard";

const DEFAULT_ARRAY = [];

const suggestProjectGeneratedImagesComponent = 'suggestProjectGeneratedImagesComponent';
const generateImagesForGenerations = 'generateImagesForGenerations';
const suggestProjectText = 'suggestProjectText';
const suggestProjectTextFollowUp = 'suggestProjectTextFollowUp';

var requestId;

export const MENUS = {
  suggestions: {
    id: 'suggestions',
    buttonLabel: <span className="px-2">
      <AutoAwesomeOutlined className="font-size-lg mr-2"/>
      Suggestions
    </span>
  },
  new: {
    id: 'new',
    buttonLabel: <span className="px-2">
      <BuildOutlined className="font-size-lg mr-2"/>
      Generate New
    </span>
  },
  expand: {
    id: 'suggestions',
    buttonLabel: <span className="px-2">
      <AutoAwesomeOutlined className="font-size-lg mr-2"/>
      Expand
    </span>
  },
  chat: {
    id: 'chat',
    buttonLabel: <span className="px-2">
      <TryOutlined className="font-size-lg mr-2"/>
      Ask Ludo
    </span>
  },
  favorites: {
    id: 'favorites',
    label: 'Favorites',
    buttonLabel: <FavoriteOutlined/>
  }
}

export const IMAGE_MENUS = {
  suggestionsGenerated: {
    id: 'suggestionsGenerated',
    buttonLabel: <span className="px-2">
      <AutoAwesomeOutlined className="font-size-lg mr-2"/>
      Suggestions
    </span>
  },
  editImage: {
    id: 'editImage',
    label: 'Edit Image',
    buttonLabel: <ModeEditOutlined/>
  },
  generatorImage: {
    id: 'generatorImage',
    label: 'Image Generator',
    buttonLabel: <BurstModeOutlined/>
  },
  searchImage: {
    id: 'searchImage',
    label: 'Search',
    buttonLabel: <SearchOutlined/>
  },
  favoritesImage: {
    id: 'favoritesImage',
    label: 'Favorites',
    buttonLabel: <FavoriteOutlined/>
  },
}

export const IMAGE_MENUS2 = {
  editImage: {
    id: 'editImage',
    buttonLabel: <span className="px-2">
      <ModeEditOutlined className="font-size-lg mr-2"/>
      Edit Image
    </span>
  }
}

const GameElementsMenu = ({component, gdd}) => {

  const {menu} = useContext(GDDContext);

  const section = useMemo(() => {
    return gdd.sections.find(({name}) => name === component.section);
  }, [gdd, component.section]);

  return (
    <div className="menu-section">
      <MenuTopBar/>
      <div className="menu-section-content">

        <ShowIf condition={menu?.option === IMAGE_MENUS.searchImage.id}>
          <MoodboardSearch section={menu?.section} value={section?.value} gdd={gdd}/>
        </ShowIf>
        <ShowIf condition={menu?.option === IMAGE_MENUS.favoritesImage.id}>
          <MoodboardFavorites section={menu?.section} value={section?.value}/>
        </ShowIf>
        <ShowIf condition={menu?.option === IMAGE_MENUS.generatorImage.id}>
          <MoodboardGenerator key="generate-elements" section={menu?.section} value={section?.value}
                              artStyle={gdd.art_style} gdd={gdd} imageType={menu?.component?.section}/>
        </ShowIf>
        <ShowIf condition={menu?.option === IMAGE_MENUS.editImage.id}>
          <MoodboardGenerator key="edit-elements" section={menu?.section} value={section?.value}
                              artStyle={gdd.art_style} gdd={gdd} initialImage={menu?.image || menu?.element?.image}/>
        </ShowIf>
        <ShowIf condition={menu?.option === IMAGE_MENUS.suggestionsGenerated.id}>
          <ImageSuggestions section={section} menu={menu}/>
        </ShowIf>

        <ShowIf condition={menu?.option === MENUS.chat.id}>
          <Chat section={component.section} element={menu.element?.text || section?.value?.text}/>
        </ShowIf>

        <ShowIf condition={menu?.option === MENUS.suggestions.id}>
          <GameElementSuggestions
            component={component}
            gdd={gdd}
            section={section}
            key={component?.section}
          />
        </ShowIf>
        <ShowIf condition={menu?.option === MENUS.new.id}>
          <GameElementSuggestions
            component={component}
            gdd={gdd}
            section={section}
            key={component?.section}
            showForm={true}
          />
        </ShowIf>
        <ShowIf condition={menu?.option === MENUS.favorites.id}>
          <GameElementFavorites component={component} gdd={gdd} section={section}/>
        </ShowIf>
      </div>
    </div>
  );
}

var imageGenerationRequestId = undefined;

const ImageSuggestions = ({section, menu}) => {
  const bus = useBus();
  const {call} = useContext(APIContext);
  const {cache} = useContext(CacheContext);
  const {selectedProjectId} = cache;

  const [isLoading, setIsLoading] = useState(true);
  const [suggestions, setSuggestions] = useState();

  function onHover(value) {
    bus.emit(`${section.id}.hover`, {image: value});
  }

  useEffect(() => {
    loadSuggestions();

    return () => {
      if (imageGenerationRequestId) {
        call('cancelGeneration', {generationId: imageGenerationRequestId}, {hideErrorMessage: true});
      }
    }
  }, [])

  function onClick(value) {
    bus.emit(`${section?.id}.clickImage`, {image: value});
    let newSuggestions = (suggestions || []).filter(({url}) => url !== value.url);
    setSuggestions(newSuggestions);
  }

  async function loadSuggestions() {
    setSuggestions([]);
    setIsLoading(true);

    const component = menu?.element || (section?.value?.text ? {text: section.value.text} : {});
    component.type = component.type || menu?.component?.section;

    let payload = {
      id: selectedProjectId,
      request_id: PerformanceUtils.generateId(),
      includeGdd: true,
      component,
    };
    imageGenerationRequestId = payload.id;

    setTimeout(async () => {
      let response = await call(suggestProjectGeneratedImagesComponent, payload);
      imageGenerationRequestId = undefined;
      if (response.ok) {
        setSuggestions(response.body.map(prepareGeneratedImage));
      }
      setIsLoading(false);
    }, 300);
  }

  return (
    <div className="suggestions p-3 d-flex flex-column">
      <ShowIf condition={suggestions?.length > 0}>
        <span className="explanation m-3">Add suggested images to the game element</span>
      </ShowIf>
      <ImageGallery
        minImages={2}
        images={suggestions || DEFAULT_ARRAY}
        onImageClick={true}
        onImageClickFunc={onClick}
        onHover={onHover}
        showGenerate={false}
        contextMenuOverrides={{
          generateSimilar: null
        }}
      />
      <GeneratingButton
        id="moodboard.load-suggestions"
        className="mx-auto mt-3"
        color="secondary"
        onClick={loadSuggestions}
        loadProgressSecs={5}
        loading={isLoading}
        loadingText="Generating..."
      >
        <Sync
          className="font-size-lg mx-auto mt-1"
        />
        Refresh Suggestions
      </GeneratingButton>
    </div>
  );
}

export const GameElementFavorites = ({component, gdd, section}) => {

  const bus = useBus();

  const {cache} = useContext(CacheContext);
  const {allFavorites = DEFAULT_ARRAY} = cache;

  const mechanics = useMemo(() => filterFavorites(allFavorites, FAVORITE_TYPES.mechanic, 'text'), [allFavorites]);
  const features = useMemo(() => filterFavorites(allFavorites, FAVORITE_TYPES.feature, 'text'), [allFavorites]);
  const text = useMemo(() => filterFavorites(allFavorites, FAVORITE_TYPES.text, 'text'), [allFavorites]);

  const sectionElementsText = useMemo(() => {
    let section = gdd?.sections?.find(section => section.name === component.section);
    return section?.value?.text || section?.value?.elements?.map(({text}) => text);
  }, [gdd, component]);

  const gameElements = useMemo(() => {
    let results = {};
    let total = 0;
    text.forEach(result => {
      results[result.type] = results[result.type] || [];
      results[result.type].push(result);
      total++;
    });
    mechanics.forEach(result => {
      results["mechanics"] = results["mechanics"] || [];
      results["mechanics"].push(result);
      total++;
    });
    features.forEach(result => {
      results["features"] = results["features"] || [];
      results["features"].push(result);
      total++;
    });

    Object.keys(results).forEach(key => {
      results[key] = (results[key] || []).filter(favorite => !(sectionElementsText || "").includes(favorite.text))
    });

    return results;
  }, [mechanics, features, text, sectionElementsText]);


  function onClick(favorite) {
    bus.emit(`${section.id}.click`, {text: favorite.text, image: favorite?.image, isNew: true});
    onHover();
  }

  function onHover(favorite) {
    bus.emit(`${section.id}.hover`, {text: favorite?.text, image: favorite?.image, isNew: true});
  }

  const label = component.label || component.section;

  const explanation = `Add new ${label} from favorites`;

  return (
    <div className="favorites-list px-5 py-3">
      <ShowIf condition={gameElements[component.section]?.length === 0}>
        <span className="explanation">No {label} favorites found</span>
      </ShowIf>
      <ShowIf condition={gameElements[component.section]?.length > 0}>
        <span className="explanation">{explanation}</span>
      </ShowIf>
      <div onMouseLeave={() => onHover(undefined)}>
        <TextResult
          columnsCountBreakPoints={{600: 1}}
          type={component.section}
          data={gameElements[component.section]}
          onClick={onClick}
          onHover={onHover}
        />
      </div>
    </div>
  );
}

const GameElementSuggestions = ({component, section, gdd, showForm = false}) => {

  const bus = useBus();
  const {track} = useContext(SocketContext);
  const {call, loading} = useContext(APIContext);
  const {cache} = useContext(CacheContext);
  const {menu} = useContext(GDDContext);
  const {selectedProjectId, generationTypes = DEFAULT_ARRAY} = cache;

  const selectedElement = menu?.element;
  const selectedText = component.multiple_allowed ? selectedElement?.text : section?.value?.text;
  const selectedId = selectedElement?.id;

  const [isLoading, setIsLoading] = useState(false);
  const [suggestions, setSuggestions] = useState();
  const [suggestionsId, setSuggestionsId] = useState(selectedId);
  const [clickId, setClickId] = useState();
  const [savedHints, setSavedHints] = useState();

  const autoSuggest = !showForm && (suggestionsId === selectedId || menu?.clickId !== clickId);

  useListener(`${section.id}.typed`, () => setSuggestions([]));

  useEffect(() => {
    return () => {
      if (imageGenerationRequestId) {
        call('cancelGeneration', {generationId: imageGenerationRequestId}, {hideErrorMessage: true});
      }
    }
  }, []);

  useEffect(() => {
    if (autoSuggest) {
      loadSuggestions(selectedText);
    }
  }, [selectedText, autoSuggest]);

  useEffect(() => {
    setSuggestions([]);
  }, [menu?.clickId]);

  useEffect(() => {
    if (component.followups && selectedElement?.id) {
      setSuggestions([])
    }
  }, [selectedElement?.id, component.followups])

  async function loadSuggestions(text = selectedText, hints) {

    setSuggestionsId();
    setSuggestions();
    setClickId(menu?.clickId);
    setIsLoading(true);
    let currentRequestId = PerformanceUtils.generateId();
    requestId = currentRequestId;

    let payload = {
      id: selectedProjectId,
      type: component.section,
      includeGdd: true,
    };

    let newSuggestions;
    setTimeout(async () => {
      if (component.followups && !!text) {
        let response = await call(suggestProjectTextFollowUp, {...payload, data: {text}});
        if (response.ok && requestId === currentRequestId) {
          newSuggestions = response.body.map((element, index) => {
            return {
              id: PerformanceUtils.generateId(),
              index,
              text: element.text,
              element,
              isNew: false
            }
          });
        }
      } else {
        payload.payload = {hints};
        let response = await call(suggestProjectText, payload);
        if (response.ok && requestId === currentRequestId) {
          newSuggestions = response.body.map((element, index) => {
            return {
              id: PerformanceUtils.generateId(),
              index,
              text: element.text,
              element,
              isNew: true,
            }
          });
        }
      }

      if (newSuggestions) {
        setSuggestions(newSuggestions);
        if (newSuggestions[0]?.isNew) {
          generateImages(newSuggestions, true);
        }
      }
      setIsLoading(false);
    }, 300);
  }

  async function generateImages(suggestions = [], noCredits = false) {

    const type = suggestions[0]?.type || component.section;

    if (!generationTypes.includes(type)) return;

    let data = {
      request_id: PerformanceUtils.generateId(noCredits ? '00000000' : undefined),
      components: suggestions.map(suggestion => {
        return {
          ...suggestion.element,
          type
        }
      }),
      art_style: gdd.art_style,
      context_gdd: gdd,
      filters: {},
    }
    if (gdd.platform) data.filters.platforms = [gdd.platform];
    imageGenerationRequestId = data.request_id;
    let response = await call(generateImagesForGenerations, {data});
    imageGenerationRequestId = undefined;
    if (response.ok) {

      let changedSuggestions = suggestions.map((suggestion, index) => {
        let image = prepareGeneratedImage(response.body[index]);
        suggestion.image = image.originalImage;
        return suggestion;
      });

      setSuggestions(prevState => {
        return (prevState || []).map(suggestion => {
          return changedSuggestions.find(({id}) => id === suggestion.id) || suggestion;
        })
      });
    }
  }

  function onHover(value) {
    bus.emit(`${section.id}.hover`, value);
  }

  function onClick(value) {
    let isNew = component.followups ? value?.isNew : true;
    let currentSuggestions = [...suggestions];
    bus.emit(`${section.id}.click`, {...value, isNew});
    bus.emit(`${section.id}.hover`, {text: undefined, isNew});
    if (component.multiple_allowed) {
      if (isNew) {
        let updatedElements = currentSuggestions.filter(({text}) => text !== value.text);
        setSuggestions(updatedElements);
      } else {
        setSuggestions([]);
        setIsLoading(true);
        if (!autoSuggest)
          loadSuggestions(selectedText + value.text);
      }
    } else {
      setSuggestions([]);
      setIsLoading(true);
      if (!autoSuggest)
        loadSuggestions(selectedText + value.text);
    }
  }

  function onClickedButton() {
    loadSuggestions(undefined, savedHints);
  }

  async function onCopy(text) {
    track('gdd.elements.copy-text', {id: component.section, text});
    await navigator.clipboard.writeText(text);
  }

  const ellipsis = selectedText ? "... " : "";
  const addLabel = ellipsis ? "Add Text" : "Add to Concept";
  const label = component.label || component.section;
  const explanation = !!ellipsis ? `Expand existing ${label}` : `Add new ${label}`;

  function generate(values) {
    let hints = convertUniversalInput(values.search).hints;
    setSavedHints(hints);
    loadSuggestions("", hints);
  }

  return (
    <>
      <div className="suggestions d-flex flex-column">
        <ShowIf condition={suggestions?.length > 0}>
          {explanation ? <span className="explanation">{explanation}</span> : null}
        </ShowIf>

        <ShowIf condition={showForm}>
          <div className="form-wrapper pb-3">
            <Formik
              initialValues={{search: []}}
              onSubmit={generate}
            >
              {formik => (
                <Form>
                  <div className="d-flex flex-column">
                    <Grid container>
                      <Grid
                        item
                        container
                        justifyContent="flex-start"
                        alignItems="flex-end"
                      >
                        <Grid item sm={12} md={12} className="input-fields-wrapper">
                          <div className="d-flex input-fields">
                            <UniversalInput
                              name="search"
                              label="Type a description or keywords"
                              formik={formik}
                              onSetData={(data) => {
                                formik.setFieldValue("search", data);
                              }}
                              allowed={["text"]}
                              value={formik.values.search}
                            />
                          </div>
                          <div className="d-flex align-self-center">
                            <GeneratingButton
                              id="gdd-elements.generate"
                              loading={loading[suggestProjectText]}
                              loadProgressSecs={10}
                              style={{margin: 0}}
                              trackOptions={{
                                ...formik.values,
                                search: convertUniversalInput(formik.values.search),
                              }}
                            />
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </ShowIf>

        <div className="d-flex flex-column suggestions-wrapper">
          <div onMouseLeave={() => onHover(undefined)}>
            {!!suggestions && suggestions.map(suggestion => (
              <div
                className="suggestion"
                onMouseEnter={() => onHover(suggestion)}
                key={suggestion.text}
              >
                <span className="option-index">Option {suggestion.index + 1}</span>
                {suggestion.image ?
                  <GeneratedImage image={suggestion.image}
                                  background={true}/> : (loading[generateImagesForGenerations] ?
                    <div className="spinner"><CircularProgress className="text-white"/></div> : null)}
                <span>{ellipsis}{(suggestion.text || "").trim()}</span>
                <div className="actions">
                  <Chip
                    onClick={() => onCopy(suggestion.text)}
                    className="copy-chip font-weight-bold w-fit"
                    label="Copy"
                  />
                  <div className="add">
                    <Chip
                      onClick={() => onClick(suggestion)}
                      className="text-white font-weight-bold w-fit"
                      label={addLabel}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <ShowIf condition={(showForm && !isLoading) || !showForm}>
          <GeneratingButton
            key={isLoading + requestId}
            id="elements.load-suggestions"
            className="load-more-button"
            onClick={onClickedButton}
            loadProgressSecs={10}
            loading={isLoading}
          >
            <Sync
              className="font-size-lg mr-2"
            />
            {(suggestions || []).length > 0 ? "Refresh" : "Load"} Suggestions
          </GeneratingButton>
        </ShowIf>
      </div>
    </>
  )
}

export default GameElementsMenu;
