import React, {useContext, useState, useEffect, useMemo} from 'react';
import APIContext from 'context/APIContext';
import AuthContext from 'context/AuthContext';
import {Formik, Form} from "formik";
import {FormikTextField} from 'formik-material-fields';
import {useHistory, useLocation, useParams} from "react-router";
import MyButton from "components/Controls/MyButton";
import * as Yup from "yup";
import {NavLink} from "react-router-dom";
import logoLarge from "assets/images/logo-white.png";
import CacheContext, {DEFAULT_CACHE} from "context/CacheContext";
import config from "config/config.json";
import ErrorBadge from "components/common/ErrorBadge";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import qs from "qs";
import ShowIf from "components/common/ShowIf";
import {Alert} from "@material-ui/lab";
import TagManager from "react-gtm-module";
import usePersistedState from "hooks/usePersistedState";
import {usePostHog} from "posthog-js/react";
import './style.scss';
import {CircularProgress, Grid} from "@material-ui/core";
import {GoogleLogin} from "../Login";

const registerPaywall3 = "registerPaywall3";
const registerPaywallTeam = 'registerPaywallTeam';
const checkEmailValid = 'checkEmailValid';

const STATES = {
  start: 'start',
  team: 'team',
  success: 'success',
}

const START_URL = "/register";
const TEAM_URL = "/register/team";
const SUCCESS_URL = "/register/success";

const RegisterPaywall3 = () => {
  const location = useLocation();
  return <RegisterPaywallContent key={location.pathname}/>;
}

const MESSAGES = {
  [STATES.start]: "Join thousands of game studios already crafting tomorrow's hits with Ludo",
}

const RegisterPaywallContent = () => {

  const location = useLocation();
  const params = useParams();
  const posthog = usePostHog();
  const consent = true;
  const queryString = qs.parse(location.search, {ignoreQueryPrefix: true});
  const emailQs = queryString?.email;
  const sessionId = queryString?.sessionId;
  const teamId = params.teamId;
  const teamEmail = params.email;

  const isStart = location.pathname === START_URL;
  const isTeam = location.pathname.startsWith(TEAM_URL);
  const isSuccess = location.pathname === SUCCESS_URL;

  const {call, loading} = useContext(APIContext);
  const {setCache, cache} = useContext(CacheContext);
  const {setAuth} = useContext(AuthContext);
  const history = useHistory();
  const [loadingOAuth, setLoadingOAuth] = useState(false);
  const [loadingReq, setLoadingReq] = useState(false);
  const [error, setError] = useState();
  const [codeError, setCodeError] = useState();
  const [state, setState] = useState(STATES.start);
  const [successMessage, setSuccessMessage] = useState();
  const [values, setValues] = usePersistedState('register.values', {email: emailQs}, true);

  useEffect(() => {
    if (emailQs)
      setValues({...values, email: emailQs});
  }, [emailQs]);

  useEffect(() => {
    if (!!config.GOOGLE_TAG_ID) {
      TagManager.dataLayer({
        dataLayer: {
          event: 'start-registration',
        },
      });
    }
    posthog?.capture('start-registration');
  }, [])

  useEffect(() => {
    if (isStart) {
      setState(STATES.start);
    } else if (isTeam) {
      setState(STATES.team);
    } else if (isSuccess) {
      setState(STATES.success);
    }
  }, [isStart, isTeam, isSuccess]);

  useEffect(() => {
    if (teamId && teamEmail) {
      setValues({...values, teamId, email: teamEmail});
    }
  }, [teamId, teamEmail]);

  async function onSubmitCredentials(credentials) {
    let newValues = {...values, ...credentials};
    setValues(newValues);
    posthog?.capture('submit-email-registration');
    let response = await call(checkEmailValid, {
      email: {value: newValues.email}
    });
    if (response.ok) {
      await onSubmitRegistration(newValues);
    }
  }

  async function onSubmitRegistration(values) {
    setError();
    setLoadingReq(true);

    let auth = {
      name: values.name,
      email: values.email,
      password: values.password,
    };

    let response = await call(registerPaywall3, {auth});
    setLoadingReq(false);
    if (response.ok) {
      let joinTeamId = cache.joinTeamId;
      localStorage.clear();
      sessionStorage.clear();
      onLoginPayload(response.body, joinTeamId)
    } else {
      setError(response.body.message)
    }
  }

  async function onSubmitTeam(credentials) {
    setError();
    setLoadingReq(true);

    let auth = {
      name: credentials.name || values.name,
      password: credentials.password || credentials.password,
      team_id: credentials.teamId || values.teamId,
      email: credentials.email || values.email,
    };

    let response = await call(registerPaywallTeam, {auth});
    setLoadingReq(false);
    if (response.ok) {
      localStorage.clear();
      sessionStorage.clear();
      onLoginPayload(response.body)
    } else {
      setError(response.body.message)
    }
  }

  function onLoginPayload(payload) {
    setAuth(payload, false);
    let selectedProjectId = payload.user.selected_project || DEFAULT_CACHE.selectedProjectId;
    let path = cache.unauthenticatedPath || "/";
    setCache({
      ...DEFAULT_CACHE,
      selectedProjectId,
      projectsOpen: !selectedProjectId,
      unauthenticatedPath: undefined,
    });
    if (consent) {
      if (!!config.GOOGLE_TAG_ID) {
        TagManager.dataLayer({
          dataLayer: {
            event: 'registration',
          },
        });
      }
    }
    posthog?.capture('registration');
    history.push(path);
  }

  const responseGoogle = async (data) => {
    if (data.credential) {
      setLoadingOAuth(true);
      let token = {value: data.credential};
      let response = await call('authenticateGoogle', {token, register: 'true'});
      if (response.ok) {
        if (response.body?.user._id) {
          onLoginPayload(response.body);
        } else {
          history.push('/register?email='+response.body.user.email);
        }
      } else {
        setLoadingOAuth(false);
      }
    }
  }

  return (
    <div className="app-wrapper bg-gradient min-vh-100 register register-paywall2">
      <div className="app-main min-vh-100 top-bg-content">
        <div className="app-content p-0">
          <div className="app-content--inner d-flex align-items-center">
            <div className="app-content--inner__wrapper">
              <div className="flex-grow-1 w-100 d-flex align-items-center">
                <div className="bg-composed-wrapper--content pb-5 px-2 mb-5">
                  <div className="auth-form-wrapper">
                    <div className="text-center mb-4 pb-2">
                      <h1 className="display-4 font-weight-bold font-size-xxxxl">
                        <img
                          width="225"
                          alt="Ludo"
                          className="d-block m-auto logo"
                          src={logoLarge}
                        />
                        {MESSAGES[state]}
                      </h1>
                    </div>
                    <ShowIf condition={!!loadingOAuth}>
                      <div className="text-align-center m-4 d-flex flex-column place-content-center align-items-center">
                        <CircularProgress size={55} className="text-white" title="Please wait..."/>
                        <span>Please wait...</span>
                      </div>
                    </ShowIf>
                    <ShowIf condition={!loadingOAuth}>
                      <ShowIf condition={state === STATES.start}>
                        <CredentialsForm
                          initialValues={values}
                          onSubmit={onSubmitCredentials}
                          loading={loading[checkEmailValid] || loading[registerPaywall3]}
                          error={error}
                          successMessage={successMessage}
                          responseGoogle={responseGoogle}
                        />
                      </ShowIf>
                      <ShowIf condition={values.email && state === STATES.team}>
                        <CredentialsForm
                          initialValues={values}
                          onSubmit={onSubmitTeam}
                          loading={loading[checkEmailValid]}
                          error={error}
                          successMessage={successMessage}
                          responseGoogle={responseGoogle}
                        />
                      </ShowIf>
                      <ShowIf condition={state === STATES.success}>
                        <center>
                          <CircularProgress color="white" size={55}/>
                        </center>
                      </ShowIf>
                      <ErrorBadge error={codeError}/>
                      {state === STATES.start && <div className="text-center pt-4">
                        Already on Ludo?{' '}
                        <NavLink to="/login" className="text-white font-weight-bold">
                          Login here
                        </NavLink>
                      </div>}
                    </ShowIf>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const CredentialsForm = ({onSubmit, loading, error, successMessage, initialValues, responseGoogle}) => {

  const [showPassword, setShowPassword] = useState(false);

  return (
    <>
      <Grid container spacing={1} justifyContent="center">
        <GoogleLogin onGoogleSignIn={responseGoogle}/>
      </Grid>
      <div className="text-white text-center my-4">or</div>
      <Formik
        key={initialValues}
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={PasswordValidationSchema}
      >
        {({isValid, errors, dirty, validateField, setFieldValue}) => (
          <Form>
            <div className="mb-4">
              <label className="font-weight-bold mb-1 caps">
                Email
              </label>
              <FormikTextField
                name="email"
                variant="outlined"
                size="small"
                fullWidth
                type="email"
              />
            </div>
            <div className="mb-4">
              <label className="font-weight-bold mb-1 caps">
                Name
              </label>
              <FormikTextField
                name="name"
                variant="outlined"
                size="small"
                fullWidth
                disabled={loading}
              />
            </div>
            <div className="mb-4">
              <div className="d-flex justify-content-between position-relative">
                <label className="font-weight-bold mb-1">
                  Password (6 or more characters)
                </label>
                <div
                  className="d-flex"
                  style={{position: "absolute", right: "5px", top: 0, color: "white", cursor: "pointer"}}
                  onClick={() => setShowPassword(!showPassword)}
                >
                  <div className="mr-3">
                    {showPassword ? <VisibilityOff/> : <Visibility/>}
                  </div>
                  <span style={{top: "1px", position: "relative", width: "35px", textAlign: "right"}}>
                    {showPassword ? "Hide" : "Show"}
                  </span>
                </div>
              </div>
              <FormikTextField
                name="password"
                variant="outlined"
                size="small"
                fullWidth
                type={showPassword ? "text" : "password"}
                disabled={loading}
              />
            </div>
            <ErrorBadge error={error}/>

            <ShowIf condition={!!successMessage}>
              <Alert severity="success" className="mt-5 mb-2">
                {successMessage}
              </Alert>
            </ShowIf>

            <div className="mt-4 text-white mb-4 opacity-7">
            <span>By proceeding, you agree to our <a tabIndex="-1" href="https://ludo.ai/privacy-policies"
                                                     target="_blank"
                                                     className="text-white underline">Terms of Service and Privacy Policy</a></span>
            </div>

            <ShowIf condition={!successMessage}>
              <div className="text-center">
                <MyButton
                  className="mt-4 mb-2 font-weight-bold blue"
                  style={{width: "100%"}}
                  loading={loading}
                >
                  Get Started!
                </MyButton>
              </div>
            </ShowIf>
          </Form>
        )}
      </Formik>
    </>
  );
}

export default RegisterPaywall3;

const PasswordValidationSchema = Yup.object().shape({
  name: Yup.string()
    .required('No name provided'),
  email: Yup.string()
    .email('Must be a valid email')
    .required('No email provided'),
  password: Yup.string()
    .required('No password provided')
    .min(6, 'Password is too short - should be 6 characters minimum'),
});
