import React, {useContext, useMemo, useState} from "react";
import AuthContext from "context/AuthContext";
import APIContext from "context/APIContext";
import CacheContext from "context/CacheContext";
import _ from "lodash";
import PerformanceUtils from "helpers/PerformanceUtils";
import {CircularProgress, IconButton} from "@material-ui/core";
import {ArrowBackIosNewOutlined, CloseOutlined, SportsEsportsOutlined} from "@mui/icons-material";
import MyButton from "components/Controls/MyButton";
import {GDDModal, getNumberAllowedProjects, isProjectEditable} from "pages/GDD3/Helpers";
import backgroundImage from 'assets/images/background/concept-header-gradient.png';
import './style.scss';

const DEFAULT_ARRAY = [];
const addProject = 'addProject';

const AddToGDD = ({onClose, game, image, element}) => {

  const {auth} = useContext(AuthContext);
  const {cache} = useContext(CacheContext);
  const {projects = DEFAULT_ARRAY} = cache;
  const [project, setProject] = useState();

  const numberAllowedProjects = useMemo(() => {
    return getNumberAllowedProjects(auth);
  }, [auth]);

  let className = "add-to-gdd-modal";
  if (!!project) className += " project";
  return (
    <GDDModal
      open={true}
      onClose={onClose}
      title={!project && "Add to Game Concept"}
      className={className}
    >

      {!project && <ProjectList
        projects={projects}
        numberAllowedProjects={numberAllowedProjects}
        onClicked={setProject}
      />}

      {project && <ProjectDetails
        project={project}
        game={game}
        image={image}
        element={element}
        onBack={() => setProject()}
      />}

    </GDDModal>
  )
}

const STATUS_TYPES = {
  loading: "loading",
  added: "added",
}

const ProjectDetails = ({project, onBack, game, image, element}) => {

  const {call} = useContext(APIContext);
  const {cache, setCache} = useContext(CacheContext);
  const {gddComponents} = cache;

  const [buttonStatus, setButtonStatus] = useState({});

  const sectionInfo = element ? gddComponents[element?.type] : (game ? gddComponents.games : gddComponents.moodboard);

  async function addToGdd(sectionIndex) {

    let newProject = {...project};

    if (game) newProject = addToArray(newProject, sectionIndex, 'games', game._id);
    if (image) addToArray(newProject, sectionIndex, 'images', image);
    if (element) {
      let sectionInfo = gddComponents[element.type];
      if (sectionInfo) {
        if (sectionInfo.multiple_allowed) {
          addToArray(newProject, sectionIndex, 'elements', {...element, _id: PerformanceUtils.generateId()});
        } else {
          addToValue(newProject, sectionIndex, element.text);
        }
      }
    }

    let response = await call(addProject, {project: newProject});
    if (response.ok) {
      setCache(prevState => {
        return {
          ...prevState,
          projects: PerformanceUtils.editOrAdd(response.body, prevState.projects, '_id'),
        }
      });
    }
  }

  function addToArray(project, sectionIndex, arrayName, data) {
    let sections = project.gdd2.sections || [];
    let section = sections[sectionIndex];
    if (!!section) {
      section.value[arrayName] = _.uniq([...(section.value[arrayName] || []), data]);
    } else {
      let newSection = {
        _id: PerformanceUtils.generateId(),
        id: PerformanceUtils.generateId(),
        name: sectionInfo.section,
        value: {
          title: sectionInfo.label,
          [arrayName]: [data]
        }
      };
      sections = [...sections, newSection];
    }
    project.gdd2.sections = sections;
    return project;
  }

  function addToValue(project, sectionIndex, text) {
    let sections = project.gdd2.sections || [];
    let section = sections[sectionIndex];
    if (!!section) {
      section.value.text += `\n\n${text}`;
    } else {
      sections = [...sections, {
        _id: PerformanceUtils.generateId(),
        name: sectionInfo.section,
        value: {
          text: text
        }
      }];
    }
    project.gdd2.sections = sections;
    return project;
  }

  function onAddToSection(index) {
    setButtonStatus(prevState => {
      return {
        ...prevState,
        [index]: {
          type: STATUS_TYPES.loading,
          value: 0
        }
      }
    });
    incrementLoading(index, () => {
      completeLoading(index);
      addToGdd(index);
    });
  }

  function incrementLoading(index, onComplete = completeLoading, value = 0, increment = 10, interval = 100) {

    let handle;

    if (value < 100) {
      handle = setTimeout(() => {
        incrementLoading(index, onComplete, value + increment, increment);
      }, interval);
    } else if (value === 100) {
      setTimeout(() => {
        onComplete(index);
      }, interval);
    }

    if (value <= 100) {

      setButtonStatus(prevState => {
        if (prevState[index]?.type !== STATUS_TYPES.loading) {
          clearTimeout(handle)
          return prevState;
        }

        return {
          ...prevState,
          [index]: {
            type: STATUS_TYPES.loading,
            value
          }
        }
      });
    }

  }

  function cancelButton(index) {
    setButtonStatus(prevState => {
      return {...prevState, [index]: undefined};
    });
  }

  function completeLoading(index) {
    setButtonStatus(prevState => {
      return {...prevState, [index]: {type: STATUS_TYPES.added}};
    });
  }

  const gdd = project.gdd2;

  const headerUrl = gdd.header?.url;
  const iconUrl = gdd.icon?.url;
  const title = gdd.sections[0]?.value?.title || project.name;

  let sectionName = game ? "Game" : image ? "Image" : "Element";

  if (element) {
    sectionName = sectionInfo.label || sectionName;
  }

  function canAddToSection(section) {
    return section.name === sectionInfo.section;
  }

  const filteredSections = (gdd.sections || []).filter(canAddToSection);
  const allowNew = !(element && filteredSections.length > 0);

  return (
    <div className="project-content">
      <div className="top-left">
        <IconButton onClick={onBack}>
          <ArrowBackIosNewOutlined className="font-size-xxl pointer text-white"/>
        </IconButton>
      </div>
      <div className="header">
        <Background url={headerUrl}/>
        <span>{title}</span>
        <div className="icon-wrapper">
          {iconUrl ? <div className="icon-image" style={{backgroundImage: `url("${iconUrl}")`}}/> :
            <div className="icon-image empty"><SportsEsportsOutlined/></div>}
        </div>
      </div>
      <div className="sections">
        <span className="title">Select Section to Add to:</span>
        <ul>
          {gdd.sections.map((section, index) => {
            const title = section.name === "summary" ? "Summary" : section.value?.title || gddComponents[section.name]?.label
            const canAdd = canAddToSection(section);
            if (!canAdd) return null;
            return (
              <li>
                <div className="section-name">{title}</div>
                {canAdd && <LoadingButton
                  buttonStatus={buttonStatus}
                  index={index}
                  onCancel={cancelButton}
                  onConfirm={onAddToSection}
                />}
              </li>
            )
          })}
          {allowNew && <li>
            <div className="section-name text-secondary font-weight-bold">Add New {sectionName} Section</div>
            <LoadingButton
              buttonStatus={buttonStatus}
              index={gdd.sections.length}
              addText="+"
              onCancel={cancelButton}
              onConfirm={onAddToSection}
            />
          </li>}
        </ul>
      </div>
      <div className="footer">
        <a href={`/game-concept/${project._id}`} target="_blank" rel="noreferrer" className="ml-1">
          <MyButton
            color="secondary"
            disabled={false}
            style={{marginTop: 0, width: "250px"}}
            onClick={undefined}
          >
            Go To Game Concept
          </MyButton>
        </a>
        <Background url={headerUrl}/>
      </div>
    </div>
  )
}

const LoadingButton = ({buttonStatus, index, addText = "Add", onCancel, onConfirm}) => {

  const isLoading = buttonStatus[index]?.type === STATUS_TYPES.loading;
  const isAdded = buttonStatus[index]?.type === STATUS_TYPES.added;
  const loadingValue = buttonStatus[index]?.value;
  let buttonClassName = "secondary";
  if (isLoading || isAdded) buttonClassName = "grey";

  return (
    <MyButton
      className={buttonClassName}
      onClick={isAdded ? undefined : () => (isLoading ? onCancel(index) : onConfirm(index))}
    >
      {isLoading ? (
        <>
          <CircularProgress
            variant="determinate"
            size={25}
            value={loadingValue}
          />
          <CloseOutlined className="stop font-size-lg pointer"/>
        </>
      ) : (isAdded ? <span className="text-secondary">Added</span> :
        <span className="text-white">{addText}</span>)}
    </MyButton>
  )
}

const Background = ({url}) => {

  let backgroundClassname = "background";
  if (url) backgroundClassname += " has-image";

  let backgroundGradientClassname = "background-gradient";
  if (url) backgroundGradientClassname += " has-image";

  return (
    <>
      <div className={backgroundGradientClassname}/>
      <div className={backgroundClassname} style={{backgroundImage: `url(${url || backgroundImage})`}}/>
    </>
  )
}

const ProjectList = ({projects, numberAllowedProjects, onClicked}) => {
  return (
    <div className="m-auto modal-content">
      <ul>
        {projects.map(project => {

          const isEditable = isProjectEditable(projects, project._id, numberAllowedProjects);
          const projectName = project.gdd2.sections[0]?.value?.title || project.name;

          let icon = project?.gdd2?.icon?.url;

          return (
            <li key={project._id} className="d-flex align-items-center mb-2">
              <div className="icon">
                {icon ? <img src={icon} alt={projectName}/> : <SportsEsportsOutlined/>}
              </div>
              <span className="project-name flex-grow-1 pr-2">{projectName}</span>
              <MyButton
                color="secondary"
                disabled={!isEditable}
                style={{marginTop: 0, width: "150px"}}
                onClick={() => onClicked(project)}
              >
                {isEditable ? "Select" : "Read-only"}
              </MyButton>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default AddToGDD;
