import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  CircularProgress,
  Grid,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
  Chip,
  Tooltip,
  Avatar,
  IconButton,
} from "@material-ui/core";
import APIContext from "context/APIContext";
import FormikChipSelect from "components/Controls/FormikChipSelect";
import { Form, Formik } from "formik";
import PageTitle from "components/layout-components/PageTitle";
import MyButton, { GeneratingButton } from "components/Controls/MyButton";
import CacheContext from "context/CacheContext";
import usePersistedState from "hooks/usePersistedState";
import FormikPersist from "components/utils/FormikPersist";
import * as Yup from "yup";
import ShowIf from "components/common/ShowIf";
import PerformanceUtils from "helpers/PerformanceUtils";
import { useListener } from "react-bus";
import UniversalInput, {
  ChangeDataOnLocation,
  convertUniversalInput,
  gameToUniversalOption,
  textToUniversalOption,
  topicToUniversalOption,
} from "components/Controls/UniversalInput";
import { FilterPanel, FiltersButton } from "pages/Search";
import { FormikSelectField } from "formik-material-fields";
import "./style.scss";
import GeneratedGameCard, {
  GeneratedGameDetailPanel,
} from "components/common/GeneratedGameCard";
import _ from "lodash";
import SocketContext from "context/SocketContext";
import DismissableMessage from "components/common/DismissableMessage";
import AuthContext from "context/AuthContext";
import moment from "moment";
import {
  AddOutlined,
  AutoAwesomeOutlined,
  DeleteOutline,
  PersonOutlined,
  SyncOutlined,
  TrendingUpOutlined,
} from "@mui/icons-material";
import LoadingTip, { LOADING_TIPS_SECTIONS } from "components/utils/LoadingTip";
import { convertProxyUrl } from "components/common/ImageGallery";
import { prepareGeneratedImage } from "pages/ImageGenerator";
import ExpandableGrid from "helpers/ExpandableGrid";
import { capitalize } from "@mui/material";

const searchForGenerations = "searchForGenerations";
const generateNew = "generateNew";
const generateImagesForGenerations = "generateImagesForGenerations";
const cancelGeneration = "cancelGeneration";
const generationEvent = "generationEvent";
const getTrendsKeywords = "getTrendsKeywords";
const getGamesInformation = "getGamesInformation";
const getLudoScore = "getLudoScore";

const BATCH_SIZE = 3;

let cancelBatches = [];

const MODES = [
  {
    value: "none",
    label: "💡 Regular Mode",
    description: "Generates based on your inputs only",
  },
  {
    value: "trends",
    label: "🚀 Trendy Mode",
    description: "Combines your inputs with random trending games",
  },
  {
    value: "random",
    label: "🤯 Ludicrous Mode",
    description: "Combines your inputs with random out-of-the-box concepts",
  },
];

const IDEATOR_EVENT_TYPES = {
  dislike_seed: "dislike_seed",
  dislike_follow_up: "dislike_follow_up",
  refresh_follow_ups: "refresh_follow_ups",
  generate_more: "generate_more",
  refresh_seeds: "refresh_seeds",
  clicked_seed: "clicked_seed",
  clicked_follow_up: "clicked_follow_up",
  like_seed: "like_seed",
};
const MODELS_SEED = ["t5", "gpt-2", "gpt-j"];
const MODELS_FOLLOWUP = ["gpt-2", "gpt-j", "gpt-3"];

const DEFAULT_OBJECT = {};
const DEFAULT_ARRAY = [];

const GameGenerator = ({
  fullVersion = true,
  onClickSeed,
  onHoverSeed,
  overrideInitial,
}) => {
  const { track } = useContext(SocketContext);
  const { call } = useContext(APIContext);
  const { cache } = useContext(CacheContext);
  const { projects, selectedProjectId, generationOptions } = cache;

  const keyAdd = fullVersion ? "" : "full";

  const [currentBatchId, setCurrentBatchId] = useState(undefined);
  const [progress, setProgress] = useState({});
  const [seeds, setSeeds, loadingSeeds] = usePersistedState(
    "GameGenerator.seeds" + keyAdd,
    DEFAULT_ARRAY
  );
  const [currentFormValues, setCurrentFormValues] = usePersistedState(
    "GameGenerator.currentFormValues" + keyAdd,
    DEFAULT_OBJECT
  );
  const [loadingGenerateNew, setLoadingGenerateNew] = useState(false);

  const [models, setModels] = useState({
    seed: MODELS_SEED[0],
    followUp: MODELS_FOLLOWUP[0],
  });

  const project = useMemo(() => {
    return (
      (projects || []).find((project) => project._id === selectedProjectId) ||
      {}
    );
  }, [projects, selectedProjectId]);

  function cancelGenerationWrapper() {
    if (currentBatchId) {
      cancelBatches.push(currentBatchId);
      call(
        cancelGeneration,
        { generationId: currentBatchId },
        { hideErrorMessage: true }
      );
      setLoadingGenerateNew(false);
    }
    setCurrentBatchId(undefined);
  }

  function trackGenerationEvent(type, otherFields = {}) {
    let data = {
      type,
      ...otherFields,
    };

    function removeBlendedGames(seed) {
      return { ...seed, blended_games: undefined };
    }

    if (data.seed) data.seed = removeBlendedGames(data.seed);
    if (data.generation) data.generation = removeBlendedGames(data.generation);
    if (data.seeds) data.seeds = data.seeds.map(removeBlendedGames);
    if (data.other_seeds)
      data.other_seeds = data.other_seeds.map(removeBlendedGames);

    track("ideator." + type.split("_").join("-"), data);
    if (type !== IDEATOR_EVENT_TYPES.generate_more) {
      call(generationEvent, { data }).catch((err) => console.log(err));
    }
  }

  async function onLoadMoreSeeds() {
    trackGenerationEvent(IDEATOR_EVENT_TYPES.generate_more, {
      ...currentFormValues,
    });
    return generate(currentFormValues);
  }

  const generate = async (values) => {
    let {
      search,
      genres,
      platform = "Mobile",
      mixing_mode,
      art_style,
      perspective,
    } = values;
    setCurrentFormValues({
      search,
      genres,
      platform,
      mixing_mode,
      art_style,
      perspective,
    });

    let data = {
      request_id: PerformanceUtils.generateId(),
      filters: {
        genres: (genres || []).filter((genre) => !!genre),
        platform,
        n: 3,
      },
      art_style,
      perspective,
      ...convertUniversalInput(search),
      model: showModels ? models.seed : undefined,
      mixing_mode,
    };

    setCurrentBatchId(data.request_id);
    setLoadingGenerateNew(true);
    await generateBatch(generateNew, data, generationOptions.numberGenerations);
    setLoadingGenerateNew(false);
    setCurrentBatchId(undefined);
  };

  async function generateBatch(
    ep,
    data,
    total,
    currentResults = [],
    imageOptions
  ) {
    let toGo = total - currentResults.length;
    setProgress((currentResults.length / total) * 100);
    if (toGo > 0) {
      let currentBatchSize = Math.min(BATCH_SIZE, toGo);
      data.filters.n = currentBatchSize;
      data.previous_generations = currentResults;
      setProgress(
        calculateProgress(currentResults.length, total, currentBatchSize)
      );
      let response = await call(ep, { data });
      if (cancelBatches.includes(data.request_id)) return;
      if (response.ok) {
        let newResults = [...currentResults, ...response.body];

        const imageArtStyle =
          newResults[0].image?.selected_style !== "Any style"
            ? newResults[0].image?.selected_style
            : undefined;
        const optionsArtStyle =
          imageOptions?.art_style !== "Any style"
            ? imageOptions?.art_style
            : undefined;

        const shouldDeleteImages =
          !!optionsArtStyle &&
          !!imageArtStyle &&
          imageArtStyle !== optionsArtStyle;

        if (shouldDeleteImages) {
          newResults = newResults.map((result) => {
            delete result.image;
            return result;
          });
        }

        setSeeds((prevState) => [...newResults, ...prevState]);
        return generateBatch(ep, data, total, newResults, imageOptions);
      }
    } else {
      setProgress(calculateProgress(currentResults.length, total));
    }
  }

  function onHover(seed) {
    if (onHoverSeed) return onHoverSeed(seed);
  }

  function clearSeeds() {
    setSeeds([]);
  }

  const showModels = false;

  let className = "w-100 game-ideator";
  className += fullVersion ? " full" : " slim";

  return (
    <div className={className}>
      {fullVersion && (
        <PageTitle
          titleHeading="Game Ideator"
          titleDescription="Generate game ideas based on your own words or other games."
        >
          {/*<IdeatorTutorial/>*/}
        </PageTitle>
      )}
      <div className="generator-wrapper">
        <div className="form-wrapper pb-3">
          <DismissableMessage
            id="26052022-mixing-mode"
            message={
              <span>
                You can now spice up your generations with new generation modes,
                try selecting them below!
              </span>
            }
            displayUntil="26062022"
            style={{
              width: "fit-content",
              maxWidth: "100%",
              paddingRight: "30px",
              margin: "0 auto 15px auto",
            }}
          />
          <GameGeneratorForm
            generate={generate}
            project={project}
            loading={loadingGenerateNew}
            progress={progress}
            onCancel={cancelGenerationWrapper}
            fullVersion={fullVersion}
            overrideInitial={overrideInitial}
            onChangeArtStyle={(style) => {
              setCurrentFormValues((prevState) => {
                return {
                  ...prevState,
                  art_style: style,
                };
              });
            }}
            onChangePerspective={(perspective) => {
              setCurrentFormValues((prevState) => {
                return {
                  ...prevState,
                  perspective,
                };
              });
            }}
          />
          <ShowIf condition={showModels}>
            <div className="d-flex">
              <FormControl>
                <InputLabel>Seed Model</InputLabel>
                <Select
                  name="seed"
                  value={models.seed}
                  style={{ width: "200px" }}
                  defaultValue={models.seed}
                  onChange={(event) => {
                    let seed = event.target.value;
                    setModels((prevState) => {
                      return {
                        ...prevState,
                        seed,
                      };
                    });
                  }}
                >
                  {MODELS_SEED.map((model) => (
                    <MenuItem key={model} value={model}>
                      {model}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl>
                <InputLabel>Follow-up Model</InputLabel>
                <Select
                  name="followUp"
                  label="Follow-up Model"
                  value={models.followUp}
                  style={{ width: "200px", marginLeft: "15px" }}
                  defaultValue={models.followUp}
                  onChange={(event) => {
                    let followUp = event.target.value;
                    setModels((prevState) => {
                      return {
                        ...prevState,
                        followUp,
                      };
                    });
                  }}
                >
                  {MODELS_FOLLOWUP.map((model) => (
                    <MenuItem key={model} value={model}>
                      {model}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </ShowIf>
        </div>
        <div className="main-generator-content">
          <LoadingTip
            style={{ marginTop: "10px", marginBottom: "30px" }}
            section={LOADING_TIPS_SECTIONS.gameIdeator}
            visible={loadingGenerateNew || seeds?.length === 0}
            key={loadingGenerateNew}
          />
          {seeds?.length > 0 && (
            <div onClick={clearSeeds} className="pointer clear-seeds">
              <span className="gradient-text2 soft font-weight-bold">
                Clear History
              </span>
              <DeleteOutline className="text-secondary ml-2" />
            </div>
          )}
          <div className="seeds" onMouseLeave={() => onHover()}>
            {!loadingSeeds && (
              <GeneratedGamesGrid
                games={seeds}
                onClick={onClickSeed}
                keyAdd={keyAdd}
                fullVersion={fullVersion}
                setGames={setSeeds}
                currentFormValues={currentFormValues}
                childrenStart={seeds?.length > 0  && <div className="more-ideas">
                    <IconButton
                      className="icon-button"
                      onClick={() => onLoadMoreSeeds()}
                      disabled={loadingGenerateNew}
                    >
                      {loadingGenerateNew ? (
                        <CircularProgress size={35} />
                      ) : (
                        <Tooltip
                          title="Generate More Ideas"
                          arrow
                          PopperProps={{
                            className:
                              "MuiTooltip-popper MuiTooltip-popperArrow secondary",
                          }}
                          placement="top"
                        >
                          <AddOutlined className="round" secondary />
                        </Tooltip>
                      )}
                    </IconButton>
                  </div>
                }
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

let gettingMissingData = false;
const errorGames = {};

export const GeneratedGamesGrid = ({
  games,
  setGames,
  onClick,
  allowScroll,
  keyAdd = "",
  fullVersion = true,
  currentFormValues = DEFAULT_OBJECT,
  childrenStart,
}) => {
  const { call } = useContext(APIContext);

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [extraData, setExtraData, loadingExtraData, deleteExtraData] =
    usePersistedState("GamesGrid.extraData1" + keyAdd, DEFAULT_ARRAY);

  function isMissingScore(game) {
    if (errorGames[game.id]) return false;
    return (
      game.score?.score === undefined ||
      game.score?.timestamp < moment().add(-1, "day").unix()
    );
  }

  const missingData = useMemo(() => {
    return games
      .filter((game) => !game.image?.url || isMissingScore(game))
      .slice(0, 10);
  }, [games]);

  function updateGames(updated) {
    setGames((prevState) => {
      return prevState.map((existingGame) => {
        return (
          updated.find((game) => game.id === existingGame.id) || existingGame
        );
      });
    });
  }

  useEffect(() => {
    gettingMissingData = false;
  }, []);

  async function getMissingData(generations) {
    if (gettingMissingData) return;
    gettingMissingData = true;

    try {
      let missingScore = generations.filter((game) => isMissingScore(game));
      let missingImages = generations.filter((game) => !game.image?.url);

      async function doGetLudoScore(generations) {
        if (generations.length === 0) return [];
        let response = await call(getLudoScore, { data: { generations } });
        if (response.ok) {
          //soft change the score to show on the UI
          generations.forEach((generation, index) => {
            generation.score = response.body[index];
          });
        } else {
          generations.forEach((generation) => {
            errorGames[generation.id] = true;
          });
        }
        return response;
      }

      let [scoreResponse, imageResponse] = await Promise.all([
        doGetLudoScore(missingScore),
        missingImages.length > 0
          ? call(generateImagesForGenerations, {
              data: {
                request_id: PerformanceUtils.generateId("00000000"),
                generations: missingImages,
                art_style: currentFormValues?.art_style,
                perspective:
                  currentFormValues?.perspective ||
                  missingImages[0]?.perspective,
                filters: {
                  genres: missingImages[0]?.genres,
                  platform: missingImages[0]?.perspective,
                },
              },
            })
          : [],
      ]);

      let updatedScore = missingScore.map((game, index) => {
        if (!scoreResponse.ok) return game;
        return {
          ...game,
          score: scoreResponse.body[index],
        };
      });

      let updatedImages = missingImages
        .map((generation, index) => {
          if (!imageResponse.ok) return undefined;
          return {
            ...generation,
            image: prepareGeneratedImage(imageResponse.body[index])
              .originalImage,
          };
        })
        .filter((game) => game !== undefined);

      gettingMissingData = false;

      if (updatedScore.length > 0 || updatedImages.length > 0) {
        let updatedGenerations = generations.map((generation) => {
          let matchingScore = updatedScore.find(
            (game) => game.id === generation.id
          );
          let matchingImage = updatedImages.find(
            (game) => game.id === generation.id
          );
          delete generation.search_results;
          return {
            ...generation,
            score: matchingScore?.score || generation.score,
            image: matchingImage?.image || generation.image,
          };
        });
        updateGames(updatedGenerations);
      }
    } catch (err) {
      gettingMissingData = false;
    }
  }

  useEffect(() => {
    if (missingData.length > 0) {
      getMissingData(missingData);
    }
  }, [missingData, currentFormValues.art_style, currentFormValues.perspective]);

  useEffect(() => {
    if (!games?.length || games?.length > 1000) {
      deleteExtraData();
    }
  }, [games]);

  useEffect(() => {
    if (!loadingExtraData) {
      let missing = [];
      let embedded = [];
      games.forEach((generation) => {
        if (extraData[generation.id] === undefined) {
          let len = Object.values(generation.search_results || {}).reduce(
            (prev, current) => prev + current.length,
            0
          );
          if (len > 0) {
            embedded.push(generation);
          } else missing.push(generation);
        }
      });

      if (embedded.length > 0) {
        handleEmbeddedGenerationsInfo(JSON.parse(JSON.stringify(embedded)));
      }
      if (missing.length > 0) {
        searchGenerationsInfo(missing);
      }
    }
  }, [games, extraData, loadingExtraData]);

  async function onNewImage(game) {
    let response = call(generateImagesForGenerations, {
      data: {
        request_id: PerformanceUtils.generateId(),
        generations: [game],
        art_style: currentFormValues?.art_style || game.image?.selected_style,
        perspective:
          currentFormValues?.perspective || game.image?.selected_perspective,
        filters: {
          genres: game.image?.selected_genres || game.genres,
          platform: game.image?.platform || game.platform,
        },
      },
    });

    setGames((prevState) => {
      return prevState.map((result) => {
        if (result.id === game.id) delete result.image;
        return result;
      });
    });

    if (response.ok) {
      let resultImage = prepareGeneratedImage(response.body[0]).originalImage;
      setGames((prevState) => {
        return prevState.map((result) => {
          if (result.id === game.id) result.image = resultImage;
          return result;
        });
      });
    }
  }

  async function handleEmbeddedGenerationsInfo(generations) {
    let newExtraData = {};
    let searchResults = [];

    let gameIds = [];

    generations.forEach((generation, index) => {
      searchResults.push(generation.search_results);
      newExtraData[generation.id] = generation.search_results;
      generation.search_results.similar_games?.forEach((game) =>
        gameIds.push(game.id)
      );
      generation.search_results.trending_games?.forEach((game) =>
        gameIds.push(game.id)
      );
    });

    gameIds = _.uniq(gameIds);

    let [responseTrends, responseGames] = await Promise.all([
      searchGenerationsTrends(searchResults),
      call(getGamesInformation, { data: { ids: gameIds } }),
    ]);

    if (responseTrends) {
      let games = responseGames.ok ? responseGames.body : [];
      let gamesById = {};
      games.forEach((game) => {
        gamesById[game._id] = game;
      });

      generations.forEach((generation) => {
        newExtraData[generation.id] = {
          ...(newExtraData[generation.id] || {}),
          trending_games: generation.search_results.trending_games?.map(
            (game) => {
              return {
                ...game,
                ...(gamesById[game.id] || {}),
              };
            }
          ),
          similar_games: generation.search_results.similar_games?.map(
            (game) => {
              return {
                ...game,
                ...(gamesById[game.id] || {}),
              };
            }
          ),
        };
      });

      responseTrends.forEach((result) => {
        if (result.infos) {
          newExtraData[result.game_id] = {
            infos: result.infos,
          };
        }
      });
    }

    setExtraData((prevState) => {
      return {
        ...prevState,
        ...newExtraData,
      };
    });
  }

  async function searchGenerationsTrends(generationInfo) {
    let country = "United States";
    let genre = undefined;
    let ids = _.uniq(
      _.flatten(
        generationInfo.map(({ similar_games, trending_games }) => [
          ...similar_games,
          ...trending_games,
        ])
      ).map((game) => game.id)
    );
    let responseTrends = await call("getGamesTrends", {
      data: { ids },
      genre,
      location: country,
    });
    if (responseTrends.ok) {
      return responseTrends.body;
    }
  }

  async function searchGenerationsInfo(generations) {
    let result = await call(searchForGenerations, { data: { generations } });
    if (result.ok) {
      generations = generations.map((generation, index) => {
        return {
          ...generation,
          search_results: result.body[index],
        };
      });
      handleEmbeddedGenerationsInfo(generations);
    }
  }

  useEffect(() => {
    setSelectedIndex(0);
  }, [games?.length]);

  return (
    games?.length > 0 && (
      <ExpandableGrid
        data={games}
        selectedIndex={selectedIndex}
        onSelectedIndexChange={setSelectedIndex}
        childrenStart={childrenStart}
        renderItem={(game, index) => (
          <GeneratedGameCard
            game={game}
            onImageClickFunc={
              onClick ? () => onClick(game) : () => setSelectedIndex(index)
            }
            onClick={
              onClick ? () => onClick(game) : () => setSelectedIndex(index)
            }
            onGenerateImage={
              onNewImage
                ? (event) => {
                    event.stopPropagation();
                    onNewImage(game);
                  }
                : undefined
            }
            smallVersion={true}
            index={index}
            className={selectedIndex === index ? "selected" : ""}
          />
        )}
        renderDetail={(game, index) =>
          game && (
            <GeneratedGameDetailPanel
              key={game.id}
              game={game}
              allowScroll={allowScroll}
              extraData={extraData}
              currentIndex={index}
              totalNumber={games.length}
              onNext={() => setSelectedIndex(index + 1)}
              onPrevious={() => setSelectedIndex(index - 1)}
              smallVersion={!fullVersion}
              onClose={() => setSelectedIndex(undefined)}
            />
          )
        }
      />
    )
  );
};

const GameGeneratorForm = ({
  generate,
  project = DEFAULT_OBJECT,
  loading,
  progress,
  onCancel,
  fullVersion,
  overrideInitial,
  onChangeArtStyle,
  onChangePerspective,
}) => {
  const { auth } = useContext(AuthContext);
  const { call } = useContext(APIContext);
  const { cache } = useContext(CacheContext);
  const {
    genres = DEFAULT_ARRAY,
    platforms = DEFAULT_ARRAY,
    perspectives = DEFAULT_ARRAY,
    generationStyles = DEFAULT_ARRAY,
  } = cache;
  const [persistedData, setPersistedData] = useState();
  const [examples, setExamples] = useState(DEFAULT_ARRAY);

  const initialValues = useMemo(
    () => ({
      search: [],
      genres: overrideInitial?.genres || auth.user.genres || [],
      platform: auth.user.platform || "Mobile",
      mixing_mode: MODES[0].value,
      generateImages: true,
      art_style: generationStyles[0],
      perspective: overrideInitial?.perspective || perspectives[0],
    }),
    []
  );

  const formKey =
    "GameGenerator2" +
    project._id +
    (fullVersion ? "" : "side-panel") +
    JSON.stringify(initialValues || {});
  const [collapsed, setCollapsed] = usePersistedState(
    formKey + ".collapsed",
    true,
    true
  );

  const chipSize = {
    xs: 12,
    md: fullVersion ? 3 : 12,
    sm: fullVersion ? 6 : 12,
  };

  useEffect(() => {
    if (persistedData) {
      let platform = persistedData.values?.platform || initialValues.platform;
      let genres = persistedData.values?.genres || initialValues.genres;
      getTrendsKeywordsWrapper(platform, genres);
    }
  }, [persistedData, initialValues]);

  async function getTrendsKeywordsWrapper(platform, genres) {
    let data = {
      filters: {
        genres: (genres || []).filter((genre) => !!genre),
        platform,
        n: 15,
      },
    };
    let response = await call(getTrendsKeywords, { data });
    if (response.ok) {
      setExamples(
        response.body.map((r) => ({
          ...r,
          keyword: r.keyword
            .split(" ")
            .map((t) => capitalize(t))
            .join(" "),
        }))
      );
    }
  }

  function addGameFromListener(game, formik) {
    let search = [...formik.values.search, gameToUniversalOption(game)];
    search = _.uniqBy(search, "id");
    formik.setFieldValue("search", search);
  }

  function overrideChangeData(data, formik) {
    if (data.topic) {
      let values = {
        ...formik.values,
        search: [topicToUniversalOption(data.topic)],
      };
      formik.setValues(values);
      generate(values);
    }
  }

  return (
    <Formik
      key={formKey}
      initialValues={initialValues}
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={BySentenceValidationSchema}
      onSubmit={generate}
    >
      {(formik) => (
        <>
          <FormikPersist
            name={formKey}
            onLoad={(data) => setPersistedData(data)}
          />
          <ShowIf condition={!!persistedData}>
            {fullVersion ? (
              <ChangeDataOnLocation
                onAction={generate}
                initialValues={initialValues}
                shouldOverride={(data) => !!data.topic}
                override={overrideChangeData}
                fields={["search", "genres"]}
              />
            ) : null}
            <OnDataReceived
              listener="ideatorAddGame"
              formik={formik}
              onListen={addGameFromListener}
            />
          </ShowIf>
          <Form>
            <div className="d-flex flex-column">
              <Grid container>
                <Grid
                  item
                  container
                  justifyContent="flex-start"
                  alignItems="flex-end"
                >
                  <Grid
                    item
                    sm={12}
                    md={12}
                    xs={12}
                    className="input-fields-wrapper"
                  >
                    <div className="d-flex input-fields">
                      <FormikSelectField
                        className="mode-field"
                        name="mixing_mode"
                        label="Mode"
                        options={MODES.map(({ value, label, description }) => {
                          return {
                            value,
                            label: (
                              <div className="d-flex flex-column">
                                <span className="font-weight-bold">
                                  {label}
                                </span>
                                <span
                                  className="description ml-4 pl-1"
                                  style={{ opacity: 0.8 }}
                                >
                                  {description}
                                </span>
                              </div>
                            ),
                          };
                        })}
                        fullWidth
                      />
                      <UniversalInput
                        name="search"
                        label="Leave blank, or type keywords, phrases, or game titles"
                        formik={formik}
                        onSetData={(data) => {
                          formik.setFieldValue("search", data);
                        }}
                        value={formik.values.search}
                        allowed={[
                          "text",
                          "game",
                          "generated_game",
                          "gdd",
                          "topic",
                        ]}
                      />
                    </div>
                    <Examples examples={examples} formik={formik} />
                    <Grid item xs={12} sm={12} md={12}>
                      <FiltersButton
                        collapsed={collapsed}
                        setCollapsed={setCollapsed}
                        page="ideator"
                      />
                    </Grid>
                    <ShowIf condition={!collapsed}>
                      <div className="filters-form">
                        <Grid
                          item
                          xs={chipSize.xs}
                          sm={chipSize.sm}
                          md={chipSize.md}
                        >
                          <FormikChipSelect
                            name="genres"
                            title="Genres"
                            values={genres}
                            onChange={(genres) => {
                              let { platform } = formik.values;
                              getTrendsKeywordsWrapper(platform, genres);
                            }}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={chipSize.xs}
                          sm={chipSize.sm}
                          md={chipSize.md}
                        >
                          <FormikSelectField
                            className="mt-4"
                            name="platform"
                            label="Platform"
                            options={platforms.map((platform) => {
                              return { value: platform, label: platform };
                            })}
                            fullWidth
                            onChange={(event) => {
                              let { genres } = formik.values;
                              let platform = event.target.value;
                              getTrendsKeywordsWrapper(platform, genres);
                            }}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={chipSize.xs}
                          sm={chipSize.sm}
                          md={chipSize.md}
                        >
                          <FormikSelectField
                            name="art_style"
                            label="Art Style"
                            className="mt-4"
                            options={generationStyles.map((value) => {
                              return {
                                value,
                                label: (
                                  <div className="d-flex flex-column">
                                    <span className="font-weight-bold">
                                      {value}
                                    </span>
                                  </div>
                                ),
                              };
                            })}
                            onChange={(event) =>
                              onChangeArtStyle(event.target.value)
                            }
                            fullWidth
                          />
                        </Grid>
                        <Grid
                          item
                          xs={chipSize.xs}
                          sm={chipSize.sm}
                          md={chipSize.md}
                        >
                          <FormikSelectField
                            name="perspective"
                            label="Perspective"
                            className="mt-4"
                            options={perspectives.map((value) => {
                              return {
                                value,
                                label: (
                                  <div className="d-flex flex-column">
                                    <span className="font-weight-bold">
                                      {value}
                                    </span>
                                  </div>
                                ),
                              };
                            })}
                            onChange={(event) =>
                              onChangePerspective(event.target.value)
                            }
                            fullWidth
                          />
                        </Grid>
                      </div>
                    </ShowIf>
                    <ShowIf condition={collapsed}>
                      <Grid item sm={12} md={12}>
                        <FilterPanel
                          onChange={(name, value) => {
                            if (name === "genres") {
                              getTrendsKeywordsWrapper(
                                formik.values.platform,
                                value
                              );
                            } else if (name === "platform") {
                              getTrendsKeywordsWrapper(
                                value,
                                formik.values.genres
                              );
                            }
                          }}
                          onExpand={() => setCollapsed(false)}
                        />
                      </Grid>
                    </ShowIf>
                  </Grid>
                </Grid>
                <div className="d-flex">
                  <GeneratingButton
                    id="ideator.generate"
                    label="Start New Generation"
                    className="gradient"
                    loading={loading}
                    progress={progress}
                    onCancel={onCancel}
                    style={{ margin: 0, marginLeft: "15px" }}
                    trackOptions={{
                      ...formik.values,
                      search: convertUniversalInput(formik.values.search),
                      fullVersion: !!fullVersion,
                    }}
                    loadProgressSecs={5}
                  />
                </div>
              </Grid>
            </div>
          </Form>
        </>
      )}
    </Formik>
  );
};

export const Examples = ({
  formik,
  examples,
  number = 5,
  mainIcon = true,
  onRefresh,
}) => {
  const { call } = useContext(APIContext);
  const [resampleKey, setResampleKey] = useState(1);
  const [games, setGames] = useState({});

  useEffect(() => {
    let ids = examples
      .filter((example) => getType(example) === "game")
      .map(({ link }) => link);
    if (ids.length > 0) {
      call(getGamesInformation, { data: { ids } }).then((response) => {
        if (response.ok) {
          let result = {};
          response.body.forEach((game) => {
            result[game._id] = game;
          });
          setGames(result);
        }
      });
    }
  }, [examples]);

  const sampledExamples = useMemo(() => {
    return weightedShuffle([...examples]);
  }, [examples, resampleKey]);

  const filteredExamples = useMemo(() => {
    let values = formik.values.search;
    return sampledExamples.filter((example) => {
      let exampleText = example.keyword.toLowerCase();
      return !values.find((value) => {
        let valueText = value.label.toLowerCase();
        return example.link === value.id || valueText.includes(exampleText);
      });
    });
  }, [formik.values.search, sampledExamples]);

  function getType(example) {
    let type = example.type;
    if (!type) {
      type = example.from_topic ? "topic" : "game";
    }
    return type;
  }

  function onResample() {
    if (onRefresh) return onRefresh();
    setResampleKey(resampleKey + 1);
  }

  function onClickedExample(example) {
    let type = getType(example);
    let value;
    if (type === "topic") {
      value = topicToUniversalOption(example.topic);
    } else if (type === "game") {
      let game = games[example.link];
      if (game) {
        value = gameToUniversalOption(game);
      } else {
        value = textToUniversalOption(example.keyword);
      }
    } else if (type === "keyword") {
      value = textToUniversalOption(example.keyword);
    } else if (type === "image-prompt") {
      value = textToUniversalOption(example.keyword);
    }

    if (value) formik.setFieldValue("search", [...formik.values.search, value]);
  }

  function getTooltip(example) {
    return example.origin || "Ludo Suggestion";
  }

  function getAvatar(example) {
    let type = getType(example);
    if (type === "topic") {
      return (
        <div className="topic-icon">
          <TrendingUpOutlined className="font-size-xl trending" />
        </div>
      );
    } else if (type === "game") {
      let url = convertProxyUrl(games[example.link]?.icon);
      return url ? (
        <Avatar
          className="mr-0"
          src={url}
          style={{
            width: "28px",
            height: "28px",
            marginLeft: "5px",
          }}
        />
      ) : null;
    } else if (type === "keyword") {
      return (
        <div className="topic-icon">
          <PersonOutlined className="font-size-xl trending" />
        </div>
      );
    } else if (type === "image-prompt") {
      return (
        <div className="topic-icon">
          <AutoAwesomeOutlined className="font-size-xl trending" />
        </div>
      );
    }
  }

  return (
    filteredExamples.length > 0 && (
      <Grid item xs={12} sm={12} md={12} className="mb-0">
        <div className="examples">
          {mainIcon && (
            <span className="description">
              <Tooltip
                arrow
                placement="top"
                title="Ludo Suggestions"
                PopperProps={{
                  disablePortal: true,
                  className:
                    "MuiTooltip-popper MuiTooltip-popperArrow secondary",
                }}
              >
                <AutoAwesomeOutlined className="text-tertiary" />
              </Tooltip>
            </span>
          )}
          <div className="chips">
            {filteredExamples.slice(0, number).map((example) => (
              <div className="chip-wrapper" key={example.keyword}>
                <Tooltip
                  title={getTooltip(example)}
                  arrow
                  PopperProps={{
                    className:
                      "MuiTooltip-popper MuiTooltip-popperArrow secondary",
                  }}
                  placement="top"
                >
                  <span>
                    <Chip
                      key={example.keyword}
                      label={example.keyword}
                      onClick={() => onClickedExample(example)}
                      avatar={getAvatar(example)}
                    />
                  </span>
                </Tooltip>
              </div>
            ))}
          </div>
          <span className="description">
            <Tooltip
              arrow
              placement="top"
              title="Refresh Keywords"
              PopperProps={{
                disablePortal: true,
                className: "MuiTooltip-popper MuiTooltip-popperArrow secondary",
              }}
            >
              <IconButton onClick={onResample}>
                <SyncOutlined className="text-blue" />
              </IconButton>
            </Tooltip>
          </span>
        </div>
      </Grid>
    )
  );
};

const OnDataReceived = ({ listener, formik, onListen }) => {
  useListener(listener, (data) => onListen(data, formik));
  return null;
};

export default GameGenerator;

const BySentenceValidationSchema = Yup.object().shape({ search: Yup.array() });

export function calculateProgress(dataLength, total, buffer = 0) {
  return {
    value: (dataLength / total) * 100,
    buffer: 100,
  };
}

function weightedShuffle(items) {
  let totalWeight = items.reduce((acc, item) => acc + item.weight, 0);
  const chosen = [];

  while (items.length > 0) {
    items.forEach((item) => {
      item.normalizedWeight = item.weight / totalWeight;
    });

    let accNormWeights = items
      .map((item) => item.normalizedWeight)
      .reduce((acc, normWeight, i) => {
        acc.push((acc[i - 1] || 0) + normWeight);
        return acc;
      }, []);

    let rand = Math.random();
    let chosenIndex = accNormWeights.findIndex(
      (accNormWeight) => rand < accNormWeight
    );

    chosenIndex = chosenIndex === -1 ? items.length - 1 : chosenIndex;

    chosen.push(items[chosenIndex]);
    items.splice(chosenIndex, 1);

    totalWeight = items.reduce((acc, item) => acc + item.weight, 0);
  }

  return chosen;
}
