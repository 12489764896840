import React, {useContext, useEffect, useMemo, useState} from 'react';
import {Dialog, Tooltip} from "@material-ui/core";
import Achievements , {getAchievementStepId} from "pages/Achievements";
import MyButton from "components/Controls/MyButton";
import SocketContext from "context/SocketContext";
import {GAME_DETAILS_PANEL_TABS} from "components/common/GameDetailsPanel";
import _ from 'lodash';
import AuthContext from "context/AuthContext";
import APIContext from "context/APIContext";
import AchievementDoneIcon from 'assets/images/icons/mission_done.png';
import AchievementsIcon from 'assets/images/icons/achievements-icon.png';
import AchievementsHalfIcon from 'assets/images/icons/achievements-half-icon.png';
import Achievements100Icon from 'assets/images/icons/achievements-100-icon.png';
import CacheContext from "context/CacheContext";
import {CloseOutlined} from "@mui/icons-material";

const updateUserInfo = 'updateUserInfo';
const DEFAULT_ARRAY = [];

const AchievementsBar = () => {

  const {auth, setAuth} = useContext(AuthContext);
  const {call} = useContext(APIContext);
  const {track, addTrackListener} = useContext(SocketContext);
  const {cache, setCacheValue, setCache} = useContext(CacheContext);
  const {achievementsOpen = false} = cache;

  const completedAchievements = auth.user.missions_completed || DEFAULT_ARRAY;

  function setOpen(val) {
    setCacheValue('achievementsOpen', val);
  }

  useEffect(() => {
    addTrackListener('AchievementsBar', onLog);
  }, [cache.achievementToasts, completedAchievements]);

  const achievements = useMemo(() => {
    return [
      {
        "id": "research",
        "name": "Market Ninja",
        "steps": [
          {
            "id": "check-charts",
            "name": "Check the current Top Charts",
            "completed": "Checked the Top Charts",
            "description": "1 - Click on the **Top Charts Blender** option in the **Research Section** of the **Left Side Navigation**\n\n2 - Change the **Genre** input to check out a different chart",
            "link": "/top-charts",
            checkCompleted: log => {
              return log.action === "top-charts.load" && log.options.genre !== "All";
            }
          },
          {
            "id": "game-trends",
            "name": "View a Game Detail's Trend Tab",
            "completed": "Viewed the Trend Tab",
            "description": "1 - Click on a **Game Thumbnail** on any page in Ludo to open its **Game Details Right Side Panel**.\n\n2 - Select the **Trends Tab** (Next to the **Overview Tab**).\n\nPro Tip: You might have to try multiple games since many games are not in the top charts and **don't** have a **Trends Tab**.",
            "link": "/",
            checkCompleted: log => {
              return log.action === "detailsPanel-game.change-tab" && log.options.tab === GAME_DETAILS_PANEL_TABS[1];
            }
          },
          {
            "id": "search-topic",
            "name": "Search a Topic in New Releases",
            "completed": "Searched the New Releases",
            "description": "1 - Click on the **New Releases Tab** at the top of the **Daily Trends Screen**.\n\n2 - Type in search terms and launch the search to see the latest releases related to your search prompt.",
            "link": "/trending",
            checkCompleted: log => {
              return log.action === "clicked-button" &&
                log.options.id === "trends.search" &&
                log.options.tab === 1;
            }
          },
          {
            "id": "market-analysis",
            "name": "Compare an Idea to the Market",
            "completed": "Compared an Idea to the Market",
            "description": "1 - Click on the **Market Analysis** option in the **Trends Section** of the **Left Side Navigation**\n\n2 - Type in search terms and launch the search to see how similar games compare to the overall market.",
            "link": "/market-analysis",
            checkCompleted: log => {
              return log.action === "trends.compare-to-market" ||
              (log.action === "clicked-button" &&
                log.options.id === "competitive-analysis.analyse" &&
                ((log.options.search?.hints || []).length >0 ||
                  (log.options.search?.game_ids || []).length >0 ||
                  (log.options.search?.generations || []).length >0
                ))
            }
          }
        ]
      },
      {
        id: "inspiration",
        name: "Research Wizard",
        steps: [
          {
            id: "search-screen",
            name: "Search for Games or Images",
            "completed": "Searched a Game or Image",
            description: "1 - Click on the **Search** option in the **Left Side Navigation**\n\n2 - Add a search term on the **Search Bar** and click the **Search!** button",
            link: '/search',
            checkCompleted: log => {
              return log.action === "clicked-button" &&
                log.options?.id === "search.search";
            }
          },
          {
            id: "game-favorites",
            name: "Add a Searched Game to your Favorites",
            "completed": "Added a Game to the Favorites",
            description: "1 - Launch a search from the **Search Screen**.\n\n2 - Select the **Add to Favorites** 💙 option from the **Contextual Menu** or the **Game Details Right Side Panel** (accessed by clicking on a **Game Thumbnail**) of one of the games in the **Search Results**.",
            link: '/search',
            checkCompleted: log => {
              return log.action === "favorite.add" && !!log.options.id;
            }
          },
          {
            id: "image-favorites",
            name: "Add a Searched Image to your Favorites",
            completed: "Added an Image to the Favorites",
            description: "1 - Launch a search from the **Search Screen**.\n\n2 - Select the **Add to Favorites** 💙 option on a **Game Thumbnail** or in its **Game Details Right Side Panel** (accessed by clicking on its **Thumbnail**).",
            link: '/search',
            checkCompleted: log => {
              return log.action === "favorite.add" && !!log.options.image;
            }
          },
          {
            id: "similar-games",
            name: "Search for Similar Games",
            completed: "Searched for Similar Games",
            description: "Select the **Search Similar Games** option on any game thumbnail in Ludo.\n\n*Pro Tip:* Use the game's **Contextual Menu** at the top right of its **Thumbnail** or its **Game Details Right Side Panel**, accessed by clicking on it's thumbnail.",
            link: '/search',
            checkCompleted: log => {
              return log.action.endsWith('game.search-similar');
            }
          },
          {
            id: "similar-images",
            name: "Search for Similar Images",
            completed: "Searched for Similar Images",
            description: "1 - Click on any game **Thumbnail** to open the **Game Details Right Side Panel**.\n\n2 - In the **Overview** tab, click on any game image to open the **Image Details Right Side Panel** and automatically search for other similar images.\n\n*Pro Tip:* You can continue the exploration by clicking on any of the images shown in the **Image Details Right Side Panel**.",
            link: '/search',
            checkCompleted: log => {
              return log.action.endsWith('image.search-similar');
            }
          },
        ]
      },
      {
        id: "gamestorming",
        name: "Ideation Master",
        steps: [
          {
            id: "ideator",
            name: "Generate a Game Idea",
            completed: "Generated a Game Idea",
            description: "1 - From the **Game Ideator Screen**, type a game description in English or type in the name of an existing game, find it and click on it in the **Autocomplete Dropdown List** that appears below **the Search Field**.\n\n2 - Launch the generation.\n\n*Pro Tip:* You can add multiple games or search terms if you want, and Ludo will try to combine them.",
            link: '/game-ideator',
            checkCompleted: log => {
              return log.action === "clicked-button" &&
                log.options.id === "ideator.generate" &&
                log.options.fullVersion === true;
            }
          },
          {
            id: "imagegen",
            name: "Generate a Game Screenshot",
            completed: "Generated a Game Screenshot",
            description: "1 -In the **Image Ideator** option in the **Left Side Navigation**., type one or two sentences in English describing your game.\n\n2 - Click the **Start New Generation** button",
            link: '/image-generator',
            checkCompleted: log => {
              return log.action === "clicked-button" && log.options?.id === "image-generator.generate";
            }
          },
          {
            id: "videogen",
            completed: "Generated a Gameplay Video",
            name: "Generate a Gameplay Video",
            description: "1 - In the **Video Generator** screen, type one or two sentences in English describing your game.\n\n2 - Click the **Start New Generation** button",
            link: '/video-generator',
            checkCompleted: log => {
              return log.action === "clicked-button" && log.options?.id === "video-generator.generate";
            }
          },
          {
            id: "top-charts",
            name: "Generate a Game Idea By Combining Games from the Top Charts",
            completed: "Combined Games from the Top Charts",
            description: "1 - Go to the **Top Charts Screen** and select one top chart.\n\n2 - Click on the **Add to Ideator Icon** 💡 next to 2 games found in a chart to add them to the **Game Ideator Right Side Panel**, and start a new generation.\n\n3 - If you want to try other combinations, you can remove the selected games from the input bar, and select others.\n\n*Pro Tip:* You can **restart new generations** multiple times (using the same inputs) until you find an idea that you like.",
            link: '/top-charts',
            checkCompleted: log => {
              return log.options.page === "/top-charts" &&
                log.action === "clicked-button" &&
                log.options.id === "ideator.generate" &&
                log.options.fullVersion === false &&
                (log.options.search?.game_ids || []).length >= 1;
            }
          },
          {
            id: "ideator-step",
            name: "Generate a Game Idea with the Step-by-Step Ideator",
            completed: "Used the Step-by-Step Ideator",
            description: "1 - Go to the main page of Ludo click **Start**.\n\n2 - Follow the multiple steps.\n\n3 - Click the **Generate Game Ideas** button.",
            link: '/',
            checkCompleted: log => {
              return log.action === "clicked-button" &&
                log.options.id === "step-generator.generate";
            }
          }
        ]
      },
      {
        id: "concept",
        name: "Concept Lord",
        steps: [
          {
            id: "ideate",
            name: "Start a Game Concept with the Game Ideator",
            completed: "Ideated from the Game Concept",
            description: "1 - Click on the **Game Title and Description Section** of the **Game Concept Screen** and select **Game Ideator** in the **Game Summary Right Side Panel**.\n\n2 - Enter some keywords and launch a **New Generation**.\n\n3 - Select one that you like to add it to your game concept.",
            link: "/game-concept",
            checkCompleted: log => {
              return log.action === "gdd.summary.replace-idea";
            }
          },
          {
            id: "game-concept",
            name: "Add a new Section to your Game Concept",
            completed: "Added a Section to the Game Concept",
            description: "1 - Click on the **Game Concept** option in the **Project Section** of the **Left Side Navigation**.\n\n2 - Hover your mouse on the dotted line, click the **Add Section** ➕ icon and choose a section from the **Right Side Panel**",
            link: "/game-concept",
            checkCompleted: log => {
              return log.action === "gdd.menu.clicked"
            }
          },
          {
            id: "add-element",
            name: "Add a Game Element to your Game Concept",
            completed: "Added a Game Element to the Game Concept",
            description: "1 - Click on the **Add Section** ➕ icon below and select any **Game Element** from the **Create and Add Game Elements Section** of the **Add Section Right Side Panel**.\n\n2 - Select the type of **Game Element** that you want to add to your game concept.\n\n3 - Click on **Ludo Suggestions** in the **Right Side Panel** for Ludo to generate game elements relevant to your game concept.\n\n4 - Click one that you like to add it to your game concept. Everything that you add to the game concept will influence future Ludo suggestions!\n\n*Pro Tip:* Don't hesitate to **Refresh** the suggestions to see more options.",
            link: "/game-concept",
            checkCompleted: log => {
              return log.action === "gdd.elements.click-idea";
            }
          },
          {
            id: "search",
            name: "Search for Similar Existing Games",
            completed: "Searched for Similar Existing Games",
            description: "1 - Click on the **Add Section** ➕ icon below any **Section** in the **Game Concept Screen** and select **Similar Games** in the **Right Side Panel**.\n\n2 - Click on **Ludo Suggestions** for Ludo to show you existing games similar to your game concept.\n\n3 - Add the games that you find revelant to your game concept by clicking on them.",
            link: "/game-concept",
            checkCompleted: log => {
              return log.action === "gdd.games.add-game";
            }
          }
        ]
      }
    ]
  }, []);

  const total = useMemo(() => {
    return achievements.map(({steps}) => steps.length).reduce((acc, cur) => acc + cur, 0);
  }, [achievements])

  const completed = useMemo(() => {
    let allIds = _.flatten(achievements.map((achievement) => {
      return achievement.steps.map(step => getAchievementStepId(achievement, step));
    }));
    return completedAchievements.filter(id => allIds.includes(id));
  }, [achievements, completedAchievements]);

  const progress = (completed.length / total) * 100;

  async function onAchievementStepCompleted(achievement, step, id) {
    track('achievement.completed', {id});
    let missions_completed = auth.user.missions_completed || [];
    missions_completed.push(id);
    missions_completed = _.uniq(missions_completed);
    achievementStepCompletedNotification(achievement, step, id);
    let response = await call(updateUserInfo, {data: {missions_completed}});
    if (response.ok) {
      setAuth({user: {...response.body}}, true, false);
    }
  }

  function achievementStepCompletedNotification(achievement, step, id) {
    setCache(prevState => {
      return {
        ...prevState,
        achievementToasts: [...(prevState.achievementToasts || []), {achievement, step, id}]
      }
    })
  }

  function onLog(log) {
    setTimeout(() => {
      achievements.forEach(achievement => {
        achievement.steps.forEach(step => {
          let id = getAchievementStepId(achievement, step);
          let completed = (auth.user.missions_completed || []).includes(id);
          if (!completed && step.checkCompleted(log))
            onAchievementStepCompleted(achievement, step, id);
        });
      });
    }, 100);
  }

  let icon = AchievementsIcon;
  if(progress === 100) {
    icon = Achievements100Icon;
  } else if(progress > 0) {
    icon = AchievementsHalfIcon;
  }

  return (
    <>
      <div className="achievements-counter counter" onClick={() => setOpen(true)}>
        <Tooltip
          arrow
          placement="bottom"
          title={
            <span>Master all of Ludo's features with Achievements!</span>
          }
          PopperProps={{className: "MuiTooltip-popper MuiTooltip-popperArrow secondary"}}
        >
          <div className="progress-wrapper">
            <div className="bar">
              <span>
                {Math.floor(progress)}%
              </span>
            </div>
            <div className="achievement-icon">
              <img src={icon} alt="Achievements"/>
            </div>
          </div>
        </Tooltip>
      </div>
      <Dialog
        open={achievementsOpen}
        onClose={() => setOpen(false)}
        className="full-screen-modal no-scroll"
      >
        <Achievements
          achievements={achievements}
          onClose={() => setOpen(false)}
          progress={progress}
          completed={completed}
          total={total}
        />
      </Dialog>
    </>
  );
}

export const AchievementToasts = ({}) => {

  const {cache, setCacheValue, setCache} = useContext(CacheContext);
  const {achievementToasts = DEFAULT_ARRAY} = cache;
  const [isClosing, setIsClosing] = useState({});

  function onClickAchievements() {
    setCacheValue('achievementToasts', []);
    setCacheValue('achievementsOpen', true);
  }

  function onClose(data) {
    setIsClosing(prevState => {
      return {
        ...prevState, [data.id]: true
      }
    });
    setTimeout(() => {
      setCache(prevState => {
        return {
          ...prevState,
          achievementToasts: [...(prevState.achievementToasts || [])].filter(item => item?.id !== data.id)
        }
      });
      setIsClosing(prevState => {
        return {
          ...prevState, [data.id]: false
        }
      });
    }, 200);
  }

  return (
    <div className="achievement-toasts">
      {achievementToasts.map((data, index) =>
        <AchievementToast
          key={data.id + !!isClosing[data.id]}
          data={data}
          onClose={onClose}
          onClickAchievements={onClickAchievements}
          isClosing={!!isClosing[data.id]}
        />)}
    </div>
  )
}

const AchievementToast = ({data, onClose, onClickAchievements, isClosing}) => {

  useEffect(() => {
    let timeout = setTimeout(() => {
      if (!isClosing) onClose(data);
    }, 10000);

    return () => {
      clearTimeout(timeout);
    }
  }, [isClosing])

  let className = "achievement-toast animate__animated"
  if (isClosing) className += " animate__zoomOut";
  else className += " animate__slideInRight";

  return (
    <div className={className}>
      <div className="back clickable" onClick={() => onClose(data)}>
        <CloseOutlined
          className="font-size-xl"
        />
      </div>
      <div className="top-line">
        <img src={AchievementDoneIcon} alt="Achievements"/>
        <div className="content">
          <div className="title">Achievement Unlocked!</div>
          <div className="description">{data.step.completed}</div>
        </div>
        <div className="right">
          <MyButton size="small" onClick={onClickAchievements} style={{width: "130px"}} color="secondary">
            All Achievements
          </MyButton>
        </div>
      </div>
    </div>
  )
}

export default AchievementsBar;
