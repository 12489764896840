import React, {useContext, useState, useRef, useEffect, useMemo} from 'react';
import './style.scss';
import APIContext from "context/APIContext";
import CacheContext from "context/CacheContext";
import logo from "assets/images/logo-square.png";
import {IconButton, Tooltip} from "@material-ui/core";
import {AutoAwesomeOutlined, CloseOutlined, SyncOutlined, WarningAmberOutlined} from "@mui/icons-material";
import _ from "lodash";
import PerformanceUtils from "helpers/PerformanceUtils";
import SocketContext from "context/SocketContext";
import ContentEditable from "react-contenteditable";
import striptags from "striptags";
import ReactMarkdown from "react-markdown";

const cancelGeneration = 'cancelGeneration';
const chatGdd = 'chatGdd';
export const END_OF_TEXT = '<|endoftext|>';
var requestId;

const Chat = ({section, element}) => {

  const {call} = useContext(APIContext);
  const {track} = useContext(SocketContext);
  const {cache, setCacheValue} = useContext(CacheContext);
  const [messages, setMessages] = useState([]);
  const [value, setValue] = useState("");
  const [waiting, setWaiting] = useState(false);
  const [followUps, setFollowUps] = useState([]);
  const {selectedProjectId, streamingMessage, gddComponents} = cache;

  const [shouldSubmit, setShouldSubmit] = useState(false);
  const [conversationId] = useState(PerformanceUtils.generateId());
  const [chosenPlaceholder, setChosenPlaceHolder] = useState("Write your question or request here...");

  const inputRef = useRef(null);
  const chatRef = useRef(null);

  useEffect(() => {
    if (shouldSubmit) {
      setShouldSubmit(false);
      sendChatMessage(value);
    }
  }, [shouldSubmit, value, messages, selectedProjectId]);

  useEffect(() => {
    if (!waiting) {
      inputRef.current?.focus();
    }
  }, [waiting]);

  useEffect(() => {

    sendChatMessage("", true);

    return () => {
      onCancel();
    }
  }, []);

  async function onCancel() {
    if (requestId)
      call(cancelGeneration, {generationId: requestId}, {hideErrorMessage: true});
    setWaiting(false);
  }

  async function onRegenerate() {
    let oldMessages = [...messages];
    oldMessages.splice(-1);
    let userMessage = oldMessages.splice(-1)[0];
    return sendMessages(oldMessages, userMessage, true);
  }

  async function sendChatMessage(text = value, force = false) {

    if (!force && (!text || waiting)) return;

    text = text.split('<br>').join('\n');
    text = striptags(text).split('&nbsp;').join(' ');

    const userMessage = {
      role: "user",
      text,
      selected_section: section,
      selected_element: element,
    }

    const oldMessages = [...messages];
    return sendMessages(oldMessages, userMessage);
  }

  async function sendMessages(oldMessages, currentMessage, regenerate = false) {

    setValue("");

    const isEmpty = !currentMessage?.text;

    let newMessages = currentMessage?.text ? [...oldMessages, currentMessage] : [...oldMessages];

    track('gdd.chat.send-message', {
      ...currentMessage,
      history_size: oldMessages.length,
      regenerate: regenerate || undefined,
      project_id: selectedProjectId
    });

    setMessages(newMessages);
    setCacheValue('streamingMessage', []);
    if (!isEmpty) setWaiting(true);

    setTimeout(() => {
      chatRef.current.scroll({ top: chatRef.current.scrollHeight });
    }, 300);

    requestId = PerformanceUtils.generateId(regenerate || isEmpty ? '00000000' : undefined);

    setFollowUps([]);

    let data = {
      id: selectedProjectId,
      data: {
        conversation_id: conversationId,
        request_id: requestId,
        message: currentMessage.text,
        message_history: oldMessages,
        selected_section: section,
        selected_element: element,
        is_regeneration: !!regenerate
      }
    };

    let response = await call(chatGdd, data);

    if (response.ok && data.data.request_id === requestId) {
      let message = undefined;
      if (response.body?.text && response.body?.role) {
        message = {...response.body};
      }
      if (response.body?.followups) {
        setFollowUps(response.body.followups || []);
      }
      if(message) {
        delete message.followups;
        setMessages([...newMessages, message]);
      }

      setCacheValue('streamingMessage', []);
      setWaiting(false);
    }
  }

  function onClickFollowUp(text) {
    sendChatMessage(text)
  }

  const cleanedStreamingMessage = useMemo(() => {
    return _.sortBy(_.uniqBy((streamingMessage || []).filter(data => data.requestId === requestId), 'index'), ['index']);
  }, [streamingMessage]);

  const label = gddComponents[section]?.label;
  const labelElement = section === "title" || section === "summary" ? undefined : (!element ? "section" : "element");

  const thisDescription = label && labelElement ? `${label} ${labelElement}` : (label ? `${label}` : "Game Concept");

  function setNewValue(value = "") {
    setValue(value);
  }

  return (
    <div className="chat">
      <div className="chat-content"  ref={chatRef}>

        <div className="top-banner">
          <div className="logo-wrapper">
            <img
              width="80"
              alt="Ludo"
              className="logo"
              src={logo}
            />
          </div>
          <div className="top-text">What would you like <span className="purple">know or do</span> about this <span
            className="blue">{thisDescription}</span>?
          </div>
        </div>

        {messages.length === 0 && <div className="disclaimer">
          <div className="disclaimer-content">
            Ludo can make mistakes. Please check results for accuracy.
          </div>
        </div>}
        <div className="messages">
          {messages.map((message, index) => {

            let className = "message";
            if (message.role === "user") className += " own";

            const isAssistant = message.role === "assistant";
            const showRegenerate = index === messages.length - 1 && !waiting && isAssistant;

            return (<div key={index} className={className}>
              <span className="message-author">{message.role}</span>
              <div className="body-wrapper">
                <pre className="message-body">
                  <ReactMarkdown children={message.text}/>
                </pre>
                {!showRegenerate && isAssistant && <div className="regenerate"/>}
                {showRegenerate &&
                  <Tooltip
                    title="Regenerate response"
                    arrow
                    PopperProps={{className: "MuiTooltip-popper MuiTooltip-popperArrow secondary"}}
                    placement="top"
                  >
                    <IconButton onClick={onRegenerate} className="regenerate">
                      <SyncOutlined
                        className="font-size-xxl pointer text-tertiary"
                      />
                    </IconButton>
                  </Tooltip>}
              </div>
            </div>)
          })}
          {(waiting && messages[messages.length - 1]?.role === "user") && <div className="message waiting">
            <span className="message-author">assistant</span>
            <div className="body-wrapper">
              {<pre
                className="message-body">
                <ReactMarkdown children={cleanedStreamingMessage?.map(({tokens}) => tokens.split(END_OF_TEXT).join('')).join('')}/>
                </pre>}
              <div className="regenerate"/>
            </div>
          </div>}
          {followUps.length > 0 && <FollowUps followUps={followUps} onClick={onClickFollowUp}/>}
        </div>
      </div>

      <div className="input-container">
        <ContentEditable
          key="123"
          innerRef={inputRef}
          className="input-box"
          disabled={waiting}
          placeholder={chosenPlaceholder}
          html={value || ""}
          onChange={event => waiting ? undefined : setNewValue(event.target.value)}
          onKeyDown={event => {
            if (event.key === 'Enter' || event.code === "Enter") {
              if (!event.shiftKey) {
                setShouldSubmit(true);
                event.preventDefault();
                event.stopPropagation();
              }
            }
          }}
        />
        {waiting &&
          <div className="cancel-wrapper">
            <Tooltip
              title="Cancel request"
              arrow
              PopperProps={{className: "MuiTooltip-popper MuiTooltip-popperArrow secondary"}}
              placement="top"
            >
              <IconButton onClick={onCancel} className="cancel">
                <CloseOutlined
                  className="font-size-xxl pointer text-tertiary"
                />
              </IconButton>
            </Tooltip>
          </div>
        }
      </div>

    </div>
  );
}

const FollowUps = ({followUps, onClick}) => {
  return (
    <div className="follow-ups">
      {followUps.map((followUp, index) => {
        return (<span key={index} className="follow-up" onClick={() => onClick(followUp)}>
          <AutoAwesomeOutlined className="font-size-lg mr-2"/> {followUp}
          </span>)})}
    </div>
  )
}

export default Chat;
