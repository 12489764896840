import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Editable, FAIcon } from "pages/GDD3/Helpers";
import ImageGallery, { downloadImage } from "components/common/ImageGallery";
import ShowIf from "components/common/ShowIf";
import _ from "lodash";
import { AddOutlined } from "@mui/icons-material";
import { Menu } from "pages/GDD3/GDDGameSummary";
import { MENUS, IMAGE_MENUS } from "pages/GDD3/GDDSideMenu/MoodboardMenu";
import { useListener } from "react-bus";
import GDDContext from "context/GDDContext";
import FileUpload, { toBase64 } from "components/Controls/FileUpload";
import APIContext from "context/APIContext";
import { AddImage } from "pages/GDD3/GDDGameElement";

const DEFAULT_ARRAY = [];

const GDDMoodboard = ({ component, active, changeGdd, section, value }) => {
  const ref = useRef();
  const addRef = useRef();

  const { menu, openMenu, preview } = useContext(GDDContext);
  const [selected, setSelected] = useState();

  useEffect(() => {
    if (active && !value?.images?.length) {
      setSelected("add");
    }
  }, [active, value]);

  useEffect(() => {
    if (!active) setSelected(false);
  }, [active]);

  useEffect(() => {
    if (active && ref.current) {
      //ref.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [active]);

  useEffect(() => {
    if (value?.title === undefined) {
      changeText("Image Gallery", "title");
    }
  }, [value]);

  const onClick = useCallback(
    (image) => addImage(image),
    [section, value, changeGdd]
  );
  useListener(`${section}.click`, onClick);

  function addImages(images) {
    if (images?.length > 0) {
      let newImagesValue = [...(value?.images || []), ...images];
      let newValue = {
        ...(value || {}),
        images: _.uniqBy(newImagesValue, "url"),
      };
      changeGdd(section, newValue, true);
    }
  }

  function addImage(image) {
    if (image) {
      return addImages([image]);
    }
  }

  function removeImage(image) {
    if (image) {
      let newValue = {
        ...(value || {}),
        images: (value?.images || []).filter(({ url }) => image.url !== url),
      };
      changeGdd(section, newValue, true);
    }
  }

  function changeText(text, field = "text") {
    let newValue = {
      ...(value || {}),
      [field]: text,
    };
    changeGdd(section, newValue, true);
  }

  function onClickedOption(option, image) {
    openMenu({ option, component, section });
  }

  let className = "section moodboard";
  if (active) className += " active";
  if ((value?.images || []).length === 0) className += " empty";
  else className += " keep-together";

  let menuWrapperClassName =
    "menu-wrapper d-flex flex-column w-fit hide-preview";
  if (menu?.component?.section === section) menuWrapperClassName += " open";
  if (selected === "add") menuWrapperClassName += " selected";

  let titleClassName = "menu-wrapper editable section-subtitle";
  if (selected === "title") titleClassName += " selected";

  let textClassName = "menu-wrapper editable description";
  if (selected === "text") textClassName += " selected";

  function onClickedImageOption(option, image) {
    if (option === "download") {
      downloadImage(image);
    } else if (option === "delete") {
      removeImage(image);
    } else {
      openMenu({ option, component, section, image });
    }
  }

  const imageWrapper = useCallback(
    (image, width, height, imageComponent) => {
      let id = image.id || image.url;
      return (
        <AddImage
          component={component}
          onClick={() => setSelected(id)}
          onClickImage={() => setSelected(id)}
          selected={id === selected}
          menus={IMAGE_MENUS}
          onClickedOption={(option) => onClickedImageOption(option, image)}
          image={image}
          allowUpload={false}
          imageComponent={imageComponent}
          style={{ width, height }}
        />
      );
    },
    [selected]
  );

  return (
    <div className={className} ref={ref}>
      <div className="left-content">
        {preview ? (
          <span className="section-subtitle">{value?.title}</span>
        ) : (
          <div className={titleClassName}>
            <Editable
              className="w-100"
              value={value?.title}
              setValue={(value = "") => changeText(value, "title")}
              placeholder="Image Gallery"
              onFocus={() => setSelected("title")}
              onBlur={() => setSelected()}
            />
          </div>
        )}
        <div className={textClassName}>
          <Editable
            className="w-100"
            value={value?.text}
            setValue={(value = "") => changeText(value)}
            placeholder="Enter text (optional)"
            onFocus={() => setSelected("text")}
            onBlur={() => setSelected()}
          />
        </div>
      </div>
      <div className="right-content">
        <ImageGallery
          images={value?.images || DEFAULT_ARRAY}
          minImages={4}
          onImageClick={false}
          hideActions={true}
          showNSFW={true}
          imageWrapper={imageWrapper}
        />
        <div
          className={menuWrapperClassName}
          ref={addRef}
          onClick={() => setSelected("add")}
        >
          <Menu id="moodboard" options={MENUS} onClick={onClickedOption} />
          <div className="add-image">
            <ShowIf condition={selected === "add"}>
              <UploadImage addImages={addImages} />
            </ShowIf>
            <ShowIf condition={selected !== "add"}>
              <AddOutlined />
            </ShowIf>
          </div>
        </div>
      </div>
    </div>
  );
};

const uploadImage = "uploadImage";

const UploadImage = ({ addImages, uploadText }) => {
  const { call } = useContext(APIContext);

  async function onFilesUpdated(files) {
    if (files.length > 0) {
      let binaryStrings = await Promise.all(
        files.map((file) => toBase64(file, false))
      );
      let data = { files: binaryStrings };
      let response = await call(uploadImage, { data });
      if (response.ok) {
        addImages(response.body);
      }
    }
  }

  return (
    <FileUpload
      hideFiles={true}
      title=""
      onFilesUpdated={onFilesUpdated}
      uploadText={uploadText}
    />
  );
};

export default GDDMoodboard;
