import React, {useContext} from "react";
import AuthContext from "context/AuthContext";
import ShowIf from "components/common/ShowIf";
import MyButton from "components/Controls/MyButton";
import {SUBSCRIPTION_STATUS} from "scenes/SubscriptionPage";
import moment from 'moment';
import APIContext from "context/APIContext";
import {isFreePlan} from "scenes/SubscriptionPage/Plans";
import {Circle, CircleOutlined} from "@mui/icons-material";

const subscribe = 'subscribe';

const PlanCard = ({plan, onSelectPlan, hideActions = false, confirmText, special = false, clickAnywhere = false}) => {

  const {loading} = useContext(APIContext);
  const {auth} = useContext(AuthContext);
  const {subscription, user} = auth;
  const paywall = user.paywall;

  let {metadata} = plan;
  let {description = ""} = metadata;

  description = description
    .split(',')
    .map(text => {
      let split = text.split('_INFO_');
      let [description, info] = split;
      return {
        text: description.replace('_NO_', '').replace('_LIMITED_', ''),
        disabled: description.includes('_NO_'),
        limited: description.includes('_LIMITED_'),
        help: info
      }
    });

  function onClickSubscribe() {
    if (!!plan.href_onclick) {
      window.location = plan.href_onclick;
      return;
    }
    onSelectPlan(plan);
  }

  let subscriptionStatus = subscription?.status || SUBSCRIPTION_STATUS.unsubscribed;

  const isTrialing = subscriptionStatus === SUBSCRIPTION_STATUS.trialing;

  let buttonDisabled = false;
  let subscribeMessage = plan.interval === "one_time" ? "Add Credits" : "Subscribe Now";
  const isSamePlanAsSub = (plan.name === subscription?.plan?.name || plan.id === subscription?.plan?.id) && plan.interval === subscription?.plan?.interval;

  if ((isTrialing || !paywall) && (isFreePlan(plan) || isSamePlanAsSub)) {
    buttonDisabled = true;
    subscribeMessage = subscription.trial_end ? `Starts in ${moment.unix(subscription.trial_end).diff(moment(), 'days')} days` : "Active";
  } else if (isTrialing) {
    //allow the user to subscribe even before the trial expires
    buttonDisabled = false;
  } else if (isSamePlanAsSub) {
    subscribeMessage = 'Currently Active'
    buttonDisabled = true;
  }

  let {amount, percent_off} = plan;
  if (plan.interval === "year") {
    amount /= 12;
    amount = ("" + amount).split('.')[0];
  }

  let promoAmount = percent_off ? amount * ((100.0 - percent_off) / 100.0) : 0;

  const recurring = plan.interval !== 'one_time';

  let cardClassName = "plan card-box-hover";
  if (recurring) cardClassName += " recurring";
  if (!buttonDisabled && clickAnywhere) cardClassName +=" pointer"

  return (
    <div className="plan-wrapper">
      {special && <span className={"special-tag "+special.className}>{special.label}</span>}
      <div className={cardClassName} onClick={!buttonDisabled && clickAnywhere ? () => onClickSubscribe(plan) : undefined}>
        <div className="card-content">
          <div className="name">
            <div className="box">
              {plan.icon && <img src={plan.icon}/>}
            </div>
            <h3 className="display-name">{plan.name}</h3>
          </div>
          <span className="description ">{plan.description}</span>
          <div className="price-content">
            <ShowIf condition={amount > 0}>
              {!promoAmount ? <span className="amount">${amount}</span> :
                <span className="amount promo"><strike>${amount}</strike>&nbsp;${promoAmount}</span>}
            </ShowIf>
            {plan.interval === "one_time" ? <span className="period">pay as you go</span> :
            <span className="period">/&nbsp;mo paid {`${plan.interval}ly`}</span>}
            {!!plan.metadata.amount_text && <span className="amount-text">{plan.metadata.amount_text}</span>}
          </div>
          <ul className="features list-unstyled text-left font-weight-bold font-size-sm">
            {description.map((desc, index) => {
              let color = desc.disabled ? "red" : (desc.limited ? "yellow" : "green");
              return (
                <li key={index}>
                    <span className="feature-icon">
                      {desc.disabled ?
                        <CircleOutlined className={"font-size-lg " + color}/> :
                        <Circle className={"font-size-lg " + color}/>}
                    </span>
                  {desc.text}
                  {desc.help && <span className="small">({desc.help})</span>}
                </li>
              )
            })}
          </ul>
          <ShowIf condition={!hideActions}>
            <div className="plan-action text-center">
              <MyButton
                id="subscription.subscribe-plan"
                trackOptions={plan}
                fullWidth
                color="secondary"
                size="large"
                confirmText={confirmText}
                confirmLabel="Downgrade"
                loading={loading[subscribe]}
                disabled={buttonDisabled}
                onClick={!buttonDisabled && !clickAnywhere ? () => onClickSubscribe(plan) : undefined}
                className="action-button">
                {subscribeMessage || <span>&nbsp;</span>}
              </MyButton>
            </div>
          </ShowIf>
        </div>
      </div>
    </div>
  );
};

export default PlanCard;
