import React, {useContext, useState, useEffect} from 'react';
import APIContext from 'context/APIContext';
import {CircularProgress, Grid} from '@material-ui/core';
import {useHistory, useParams} from "react-router";
import MyButton from "components/Controls/MyButton";
import ShowIf from "components/common/ShowIf";
import logoLarge from "assets/images/logo-white.png";
import CacheContext from "context/CacheContext";
import {useSnackbar} from "notistack";
import moment from "moment";
import AuthContext from "context/AuthContext";

const joinTeam = 'joinTeam';
const refreshToken = 'refreshToken';

const JoinTeam = () => {

  const {enqueueSnackbar, closeSnackbar} = useSnackbar();
  const {cache, setCacheValue} = useContext(CacheContext);
  const history = useHistory();
  const {call} = useContext(APIContext);
  const {auth, setAuth} = useContext(AuthContext);
  const params = useParams();
  const [isJoining, setIsJoining] = useState(false);
  const [user, setUser] = useState(undefined);

  const authOk = auth.ok;
  const expDateOk = auth.decodedToken?.exp < moment().unix();
  const teamId = params.teamId || cache.joinTeamId;

  const userId = (user || {})._id;
  const notInTeam = !((user || {}).teams || []).includes(teamId);

  useEffect(() => {
    if (authOk && expDateOk) {
      call(refreshToken).then(response => {
        if (response.ok) {
          setUser(response.body.user);
        }
      })
    } else {
      setUser(auth.user);
    }
  }, [authOk, expDateOk])

  useEffect(() => {
    if (userId && teamId && notInTeam) {
      join(teamId);
    }
  }, [userId, teamId, notInTeam])

  async function join(teamId) {
    setIsJoining(true);
    let response = await call(joinTeam, {id: teamId});
    let key = joinTeam + moment().unix();
    if (response.ok) {
      setAuth(response.body, false);
      enqueueSnackbar('Team joined successfully!', {
        key,
        variant: "success",
        autoHideDuration: 5000,
        onClick: () => closeSnackbar(key)
      });
    }
    setCacheValue('joinTeamId', undefined);
    setCacheValue('welcomeForm', true);
    setIsJoining(false);
    history.push("/");
  }

  function onClickLogin() {
    setCacheValue('joinTeamId', params.teamId);
    history.push('/login');
  }

  function onClickRegister() {
    setCacheValue('joinTeamId', params.teamId);
    history.push(`/register/team/${params.teamId}/${params.email}`);  }

  return (
    <div className="app-wrapper bg-gradient min-vh-100 join-team">
      <div className="app-main min-vh-100 top-bg-content">
        <div className="app-content p-0">
          <div className="app-content--inner d-flex align-items-center">
            <div className="flex-grow-1 w-100 d-flex align-items-center">
              <div className="bg-composed-wrapper--content pb-5 px-2">
                <div className="auth-form-wrapper">
                  <div className="w-100 pr-0 pr-lg-5">
                    <div className=" mt-3">
                      <span className="text-center">
                                    <h1 className="display-4 mb-4 font-weight-bold font-size-xxxxl">
                                <img
                                  width="450"
                                  alt="Ludo"
                                  className="d-block m-auto logo"
                                  src={logoLarge}
                                />
                                You've been invited to join a team on Ludo!
                              </h1>
                            </span>
                      <ShowIf condition={isJoining}>
                        <div className="text-align-center text-white m-4">
                          <CircularProgress size={55} className="text-white"/>
                          <br/>
                          <span>Joining team...</span>
                        </div>
                      </ShowIf>
                      <ShowIf condition={!auth.ok}>
                        <div className="text-align-center m-4 d-flex flex-column">
                          <MyButton
                            className="mx-auto blue"
                            onClick={onClickRegister}>
                            Create New Account
                          </MyButton>
                          <span className="mt-4">Or</span>
                          <MyButton
                            className="mx-auto mb-3 blue"
                            onClick={onClickLogin}>
                            Login
                          </MyButton>
                        </div>
                      </ShowIf>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JoinTeam;
