import React, {useEffect, useState} from "react";

const fabric = window.fabric;

export const Canvas = ({
                  width, height, onExport, brushSize = 10, className
                }) => {
  const [canvasState, setCanvasState] = useState({});

  useEffect(() => {
    setupCanvas(width, height);
  }, [width, height])

  useEffect(() => {
    if (canvasState.canvas) {
      canvasState.canvas.freeDrawingBrush.width = brushSize;
    }
  }, [brushSize, canvasState])

  useEffect(() => {

    const canvas = canvasState.canvas;

    const handleExport = () => {

      if (canvas) {

        const isCanvasErased = canvas.getObjects().some(obj => obj.eraser);

        if (!isCanvasErased) {
          return onExport(undefined);
        }

        const pngDataUrl = canvas.toDataURL({
          format: 'png',
          enableRetinaScaling: false,
          top: 1,
          left: 1,
          width: canvas.getWidth() - 1,
          height: canvas.getHeight() - 1
        });
        onExport(pngDataUrl, false);
      }
    };

    if (canvas) {
      handleExport();
      canvas.on('mouse:up', handleExport);
    }

    return () => {
      if (canvas) {
        canvas.off('mouse:up', handleExport);
      }
    };
  }, [canvasState.canvas]);


  async function setupCanvas(width, height) {
    let mainCanvas = new fabric.Canvas('mask-canvas', {
      height,
      width,
      renderOnAddRemove: true,
    });

    let rectOptions = {
      left: 0,
      top: 0,
      width: width,
      height: height,
      selectable: false,
      evented: false,
    }
    mainCanvas.add(new fabric.Rect({...rectOptions, fill: "white", erasable: false}));
    mainCanvas.add(new fabric.Rect({...rectOptions, fill: "black", erasable: true}));

    mainCanvas.isDrawingMode = true;
    mainCanvas.freeDrawingBrush = new fabric.EraserBrush(mainCanvas);
    mainCanvas.freeDrawingBrush.width = brushSize;
    mainCanvas.renderAll();
    setCanvasState({canvas: mainCanvas, width, height});
  }

  return (
    <div className={className} style={{width: `${width}px`, height: `${height}px`}}>
      <canvas id="mask-canvas" tabIndex="1"/>
    </div>
  );
}

let toolKey = 1;
const CIRCLE_RADIUS = 6;

export const PointsCanvas = ({
                        width, height, points, setPoints, className
                      }) => {
  const [canvasState, setCanvasState] = useState({});

  useEffect(() => {
    setupCanvas(width, height);
  }, [width, height]);

  useEffect(() => {
    const {canvas} = canvasState;

    if (canvas) {
      toolKey = toolKey + 1;
      let currentKey = toolKey;

      canvas.on('mouse:down', function (event) {
        if (currentKey === toolKey) {
          let pointMode = event.button === 1 ? 'positive' : (event.button === 3 ? 'negative' : undefined);
          if (!pointMode) return;

          const pointer = canvas.getPointer(event.e);
          let x = parseInt(Math.round(pointer.x));
          let y = parseInt(Math.round(pointer.y));

          if (x === null || y === null) return;

          let intersectedPoint = intersects(canvasState.canvas, x, y);
          if (intersectedPoint) {
            canvas.remove(intersectedPoint);
            setPoints({
              positive: [...points.positive].filter(p => p[0] !== intersectedPoint.left || p[1] !== intersectedPoint.top),
              negative: [...points.negative].filter(p => p[0] !== intersectedPoint.left || p[1] !== intersectedPoint.top)
            });
          } else {
            addPoint(canvasState.canvas, x, y, pointMode === "positive");
            let point = [x, y];
            setPoints({
              positive: pointMode === "positive" ? [...points.positive, point] : points.positive,
              negative: pointMode === "negative" ? [...points.negative, point] : points.negative,
            });
          }
        }
      });
    }
  }, [canvasState, points]);

  function intersects(canvas, x, y) {
    let result = undefined;
    let point = getPoint(canvas, x, y, true, 2, false);
    let objects = canvas.getObjects();
    objects.forEach((object) => {
      if (point.intersectsWithObject(object)) {
        result = object;
      }
    });
    return result;
  }

  function getPoint(canvas, x, y, positive = true, radius = CIRCLE_RADIUS, includeText = true) {
    const background = "white";
    const foreground = "black";

    const groupObjects = [];

    const circle = new fabric.Circle({
      radius,
      originX: 'center',
      originY: 'center',
      fill: background,
      stroke: foreground,
      strokeWidth: 1,
      selectable: false,
    });

    groupObjects.push(circle);

    if (includeText) {
      const text = new fabric.Text(positive ? "+" : "-", {
        fontSize: 17,
        originX: 'center',
        originY: 'center',
        fontColor: foreground,
        selectable: false,
      });
      groupObjects.push(text);
    }

    return new fabric.Group(groupObjects, {
      left: x,
      top: y,
      selectable: false,
      originX: 'center',
      originY: 'center',
    });
  }

  function addPoint(canvas, x, y, positive = true) {
    let point = getPoint(canvas, x, y, positive);
    canvas.add(point);
  }

  async function setupCanvas(width, height) {
    let mainCanvas = new fabric.Canvas('points-canvas', {
      height,
      width,
      fireRightClick: true,
      stopContextMenu: true,
      renderOnAddRemove: true,
    });
    mainCanvas.setBackgroundColor(null);

    points?.negative.forEach(point => addPoint(mainCanvas, point[0], point[1], false));
    points?.positive.forEach(point => addPoint(mainCanvas, point[0], point[1], true));

    mainCanvas.renderAll();
    setCanvasState({canvas: mainCanvas, width, height});
  }

  return (
    <div className={className} style={{width: `${width}px`, height: `${height}px`}} key="points-canvas">
      <canvas id="points-canvas" tabIndex="2"/>
    </div>
  );
}
