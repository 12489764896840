import React, {useContext, useEffect, useRef, useState} from "react";
import ShowIf from "components/common/ShowIf";
import {CircularProgress} from "@material-ui/core";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import './style.scss';
import SocketContext from "context/SocketContext";
import {ChevronLeft, ChevronRight} from "@mui/icons-material";

const LudoCarousel = ({id, itemWidth = 320, className, onVisibleIndex, ...props}) => {

  const ref = useRef();
  const {track} = useContext(SocketContext);
  const [width, setWidth] = useState();
  const [visible, setVisible] = useState(5)

  function handleResize() {
    let {clientWidth} = ref.current || {};
    if (clientWidth) setWidth(clientWidth);
  }

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [ref])

  let numberItems = Math.ceil((width || 700) / itemWidth);

  useEffect(() => {
    if(numberItems) {
      setVisible(prevState => {
        return Math.max(numberItems, prevState);
      });
    }
  }, [numberItems]);

  useEffect(() => {
    if (onVisibleIndex && visible) {
      onVisibleIndex(visible);
    }
  }, [visible])

  const responsive = React.useMemo(() => {
    return {
      size: {
        breakpoint: {
          min: 0,
          max: 99999999,
        },
        items: numberItems
      }
    }
  }, [numberItems]);

  function trackWrapper(action) {
    setVisible(props.children.length);
    track(`carousel-${id || ""}.${action}`, {number: (props.children || []).length});
  }

  let wrapperClassName = "ludo-carousel";
  if (className) wrapperClassName += " " + className;

  return (
    <div className={wrapperClassName} ref={ref}>
      <ShowIf condition={!!width}>
        <Carousel
          responsive={responsive}
          customLeftArrow={<CustomLeftArrow track={trackWrapper}/>}
          customRightArrow={
            <CustomRightArrow
              track={trackWrapper}
            />}
          {...props}
          slidesToSlide={numberItems}
        >
          {props.children}
        </Carousel>
      </ShowIf>
    </div>
  )
};

export const CustomLeftArrow = ({onClick, track, ...props}) => {
  return (
    <ShowIf condition={!props.disabled}>
      <div className="button-left hvr-grow" onClick={event => {
        if (track) track('left-click');
        onClick(event);
      }}>
        <ChevronLeft
          className="font-size-xxxxxxxl arrow-icon"
        />
      </div>
    </ShowIf>
  )
};

export const CustomRightArrow = ({onClick, track, onLoadMore, fetching, ...props}) => {
  return (
    <div className="button-right hvr-grow" onClick={event => {
      if (track) track(props.disabled ? 'right-click-load-more' : 'right-click');
      let result = props.disabled ? (onLoadMore ? onLoadMore(event) : undefined) : onClick(event)
    }}>
      {!!fetching ? <CircularProgress size={25} className="progress"/> : <ChevronRight
        className="font-size-xxxxxxxl arrow-icon"
      />}
    </div>
  )
};

export default LudoCarousel;
