import React, {useContext, useEffect, useMemo, useRef, useState} from 'react';
import APIContext from "context/APIContext";
import {
  CircularProgress, Tooltip,
} from "@material-ui/core";
import ShowIf, {ShowVisibleIf} from "components/common/ShowIf";
import './style.scss';
import CacheContext from "context/CacheContext";
import PerformanceUtils from "helpers/PerformanceUtils";
import TrendTopics from "pages/Trends/TrendTopics";
import {getGameScoreData} from "pages/Trends/TrendGames";
import * as Yup from "yup";
import MyButton from "components/Controls/MyButton";
import {TopChartsContent} from "pages/TopCharts";
import {Link} from "react-router-dom";
import usePersistedState from "hooks/usePersistedState";
import useOnScreen from "hooks/useOnScreen";
import {FormTab, FormTabs} from "components/common/FormTabs";
import GameGrid from "components/common/GameGrid";
import SocketContext from "context/SocketContext";
import logoText from "assets/images/logo-text-white.png";
import {Info} from "@mui/icons-material";
import {prepareGeneratedImage} from "pages/ImageGenerator";
import AuthContext from "context/AuthContext";
import {useParallax} from 'react-scroll-parallax';
import {GenreSelection, PlatformSelection} from "../../components/layout-components/SidebarMenu";
import {GeneratedGamesGrid} from "../../pages/GameGenerator";
import AssistantChat from "../../components/common/AssistantChat";

const generateNew = 'generateNew';
const getTopCharts = 'getTopCharts';
const getTrendGames = 'getTrendGames';
const getTrendTopics = 'getTrendTopics';
const cancelGeneration = 'cancelGeneration';

export const MISSING_GENRES_MAP = {"Fighting": "Action", "Hypercasual": "Casual", "Shooter": "Action", "Education": "Family"}

const MENU_ITEMS = {
  filters: 'Filters',
  generated: 'Generated for you',
  top: 'Top Charts',
  new: 'New Releases',
  trending: 'Trending Topics',
}

const HeadquartersWrapper = () => {

  const [trendOptions, setTrendOptions, loading1] = usePersistedState('Headquarters.trendOptions1', {});
  const [searchedOptions, setSearchedOptions, loading2] = usePersistedState('Headquarters.searchedOptions1');

  return (
    (!loading1 && !loading2) ? <Headquarters
      trendOptions={trendOptions}
      setTrendOptions={setTrendOptions}
      searchedOptions={searchedOptions}
      setSearchedOptions={setSearchedOptions}
    /> : null
  )
}

const Headquarters = ({trendOptions, setTrendOptions, searchedOptions, setSearchedOptions}) => {

  const {auth} = useContext(AuthContext);
  const {track} = useContext(SocketContext);
  const {cache} = useContext(CacheContext);
  const {credits} = cache;
  const [loading, setLoading] = useState({
    [MENU_ITEMS.filter]: false,
    [MENU_ITEMS.generated]: false,
    [MENU_ITEMS.top]: false,
    [MENU_ITEMS.new]: false,
    [MENU_ITEMS.trending]: false,
  });

  const [loaded, setLoaded] = useState(false);
  const [titleKey, setTitleKey] = useState();

  const genre = useMemo(() => {
    let genre = "All";
    if (!!auth.user.genres) {
      let genres = auth.user.genres;
      genre = genres[0] || "All";
      genre = MISSING_GENRES_MAP[genre] || genre;
    }
    return genre;
  }, [auth.user.genres]);

  const platform = useMemo(() => {
    return auth.user.platform || "Mobile";
  }, [auth.user.platform]);

  const filtersRef = useRef();
  const chartsRef = useRef();
  const trendsTopicsRef = useRef();
  const gameIdeasRef = useRef();
  const latestTrendsGamesRef = useRef();

  const filtersRefVisible = useOnScreen(filtersRef);
  const chartsRefVisible = useOnScreen(chartsRef);
  const trendsTopicsRefVisible = useOnScreen(trendsTopicsRef);
  const gameIdeasRefVisible = useOnScreen(gameIdeasRef);
  const latestTrendsGamesRefVisible = useOnScreen(latestTrendsGamesRef);

  const titles = [
    {
      ref: filtersRef,
      isVisible: filtersRefVisible,
      text: "Filters",
      loading: loading[MENU_ITEMS.filters]
    },
    {
      ref: gameIdeasRef,
      isVisible: gameIdeasRefVisible,
      text: MENU_ITEMS.generated,
      loading: loading[MENU_ITEMS.generated]
    },
    {
      ref: trendsTopicsRef,
      isVisible: trendsTopicsRefVisible,
      text: MENU_ITEMS.trending,
      loading: loading[MENU_ITEMS.trending]
    },
    {
      ref: chartsRef,
      isVisible: chartsRefVisible,
      text: MENU_ITEMS.top,
      loading: loading[MENU_ITEMS.top]
    },
    {
      ref: latestTrendsGamesRef,
      isVisible: latestTrendsGamesRefVisible,
      text: MENU_ITEMS.new,
      loading: loading[MENU_ITEMS.new]
    }
  ]

  let selectedTitle = undefined;
  titles.forEach(title => {
    if (title.isVisible && selectedTitle === undefined)
      selectedTitle = title.text;
  });

  function scrollTo(ref) {
    if (ref && ref.current)
      scrollToTargetAdjusted(ref);
  }

  function scrollToTargetAdjusted(ref) {
    var headerOffset = -10;
    var elementPosition = ref.current.getBoundingClientRect().top;
    var offsetPosition = elementPosition - headerOffset;
    document.getElementById("app-content--inner__wrapper").scrollBy({
      top: offsetPosition,
      behavior: "smooth"
    });
  }

  useEffect(() => {
    onSubmit({genre, platform})
  },[genre, platform])

  function onSubmit(values) {
    if (!PerformanceUtils.isEqual(values, searchedOptions) && values.genre) {
      setSearchedOptions(values);
      track('headquarters.input', {...values});
    }
  }

  function setLoadingWrapper(key, value) {
    setLoading(prevState => {
      return {
        ...prevState,
        [key]: value
      }
    });
  }

  const searchKey = useMemo(() => {
    return JSON.stringify(searchedOptions);
  }, [searchedOptions])

  let headerFormWrapperClassName = "header-form-wrapper d-flex flex-column";
  if (credits.max) headerFormWrapperClassName += " short";

  return (
    <div className="headquarters w-100">
      <div className="header" ref={filtersRef}>
        <div className="top-bg-content">
          <div className={headerFormWrapperClassName}>
              <div className="hq-form-wrapper">
                <img
                  alt="Ludo"
                  className="d-block m-auto logo"
                  src={logoText}
                />
                <div className="form">
                  <div className="d-flex flex-row input-fields">
                    <div className="info-text">
                      <span className="text">Tweaking these settings will shape your Ludo journey</span>
                      <Hint
                        hint="These will be your new defaults for all Ludo tools"
                        iconClassName="help text-white-important"
                        placement="bottom"
                      />
                    </div>
                    <GenreSelection/>
                    <PlatformSelection chips={true}/>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>
      <div className="menu d-flex flex-row sticky-top">
        {titles.map(({text, loading, ref}, index) => (
          <Title
            key={text + index + loading}
            name={text}
            loading={loading}
            onClick={() => scrollTo(ref)}
            selected={text === selectedTitle}
          />
        ))}
      </div>
      <div className="body">

        <AssistantChat/>

        {!!searchedOptions && <React.Fragment key={searchKey}>
          <GeneratedGamesData
            setLoading={setLoadingWrapper}
            searchedOptions={searchedOptions}
            innerRef={gameIdeasRef}
          />
          <TrendsTopicsWrapper
            setLoading={setLoadingWrapper}
            searchedOptions={searchedOptions}
            innerRef={trendsTopicsRef}
          />
          <NewReleases
            searchedOptions={searchedOptions}
            setLoading={setLoadingWrapper}
            innerRef={latestTrendsGamesRef}
          />
          <ChartGamesData
            setLoading={setLoadingWrapper}
            searchedOptions={searchedOptions}
            innerRef={chartsRef}
          />
        </React.Fragment>}
      </div>
    </div>
  );
}

const GeneratedGamesData = ({searchedOptions, innerRef, setLoading}) => {

  const {call} = useContext(APIContext);
  const [data, setData] = useState();

  const hasInput = false;
  const {genre, platform} = searchedOptions;

  useEffect(() => {
    setData();
    setLoading(MENU_ITEMS.generated, true);
    const {genre} = searchedOptions;
    let searchId = PerformanceUtils.generateId('00000000');

    let genres = [genre || "All"];

    let data = {
      request_id: searchId,
      filters: {
        platform: platform || 'Mobile',
        genres,
        n: 3
      },
    };

    call(
      generateNew, {data}).then(response => {
      if (response.ok) {
        setData(response.body);
      }
      searchId = null;
      setLoading(MENU_ITEMS.generated, false);
    });

    return () => {
      if (searchId)
        call(cancelGeneration, {generationId: searchId}, {hideErrorMessage: true})
    }
  }, [searchedOptions, platform]);

  const hint = `Generated by Ludo based on your input or on games that are trending in the ${genre !== "All" ? genre : "Overall"} charts`;
  const title = hasInput ?
    `Generated by Ludo based on your input` :
    `Generated by Ludo based on ${genre !== "All" ? genre : "Overall"} charts`;

  return (
    <div className="hq-section generated-games-data animate__animated animate__fadeIn" ref={innerRef}>
        <div className="section-title"><span>{title}</span><Hint hint={hint} placement="bottom"/></div>
        <div className="circle"/>
        <DecorationElements/>
        {!data && <BigCircularProgress height="360px"/>}
        {data && data.length > 0 &&
            <>
              <div className="generated-game-list">
              <GeneratedGamesGrid
                games={data}
                allowScroll={false}
                setGames={setData}
              />
            </div>
            <Link
              to="/game-ideator"
              title="Gamestorm New Concepts"
              className="link-platform tertiary">
              <MyButton id="headquarters.go-to-ideator" color="primary">
                <span className="ml-2">Gamestorm New Concepts</span>
              </MyButton>
            </Link>
          </>}
    </div>
  )
}

const DecorationElements = () => {
  return (
    <div className="decorations">
      <SmallCircle className="one" parallaxSpeed={10}/>
      <SmallCircle className="two" parallaxSpeed={-10}/>
      <Square className="one" parallaxSpeed={-10}/>
      <Square className="two" parallaxSpeed={-5}/>
      <Square className="three" parallaxSpeed={8}/>
    </div>
  )
}

const Square = ({style = {}, parallaxSpeed = 0, className = ""}) => {
  const parallax = useParallax({speed: parallaxSpeed});
  return (
    <div ref={parallax.ref}>
      <div className={"square " + className} style={style}>
        <div className="inner"/>
      </div>
    </div>
  );
}

const SmallCircle = ({style = {}, parallaxSpeed = 0, className = ""}) => {
  const parallax = useParallax({speed: parallaxSpeed});
  return (
    <div className={"small-circle " + className} style={style}
         ref={parallax.ref}/>
  );
}

const TrendsTopicsWrapper = ({searchedOptions, innerRef, setLoading}) => {

  const {track} = useContext(SocketContext);
  const [tab, setTab] = useState(0);
  const TAB_TITLES = [
    'Rising in the Charts',
    'New Releases'
  ];

  const hasInput = false;

  const {search, genre, platform} = searchedOptions;

  let title = hasInput ? `Related ${genre !== "All" ? genre : "Overall"} Trends` : `${genre !== "All" ? genre : "Overall"} Trends`;

  let hints = [
    `Rapidly increasing the number of players day by day in the App Store and Play Store Top Charts`,
    `${genre !== "All" ? genre + " game" : "Game"} topics that are have an increasing number of new game releases`
  ];

  function changeTab(index) {
    setTab(index)
    track('headquarters.topics.tab', {tab: TAB_TITLES[index]})
  }

  const sources = platform === "Mobile" ? undefined : "steam";

  return (
    <div className="hq-section trends-wrapper-data" ref={innerRef}>
      <div className="section-title"><span>{title}</span></div>
      <div className="tabs-wrapper">
        <FormTabs value={tab} className="mt-4 primary">
          <FormTab
            label={
              <span>
                <span>{TAB_TITLES[0]}</span>
                <Hint hint={hints[0]}/>
              </span>
            }
            onClick={() => changeTab(0)}
          />
          <FormTab
            label={
              <span className="tab-hint">
                <span>{TAB_TITLES[1]}</span>
                <Hint hint={hints[1]}/>
              </span>
            }
            onClick={() => changeTab(1)}
          />
        </FormTabs>
      </div>
      <ShowVisibleIf condition={tab === 0} style={{zIndex: 1, position: "relative"}}>
        <TrendTopicsData
          tab={0}
          link="Explore Trending Games"
          linkUrl="/trending/charts"
          linkState={{data: {genre, search, sources}}}
          searchedOptions={searchedOptions}
          type="charts"
          visible={tab === 0}
          setLoading={setLoading}
        />
      </ShowVisibleIf>
      <ShowVisibleIf condition={tab === 1} style={{zIndex: 1, position: "relative"}}>
        <TrendTopicsData
          tab={tab}
          searchedOptions={searchedOptions}
          link="Explore New Releases"
          linkUrl="/trending/latest"
          linkState={{data: {genre, search, sources}}}
          type="latest"
          visible={tab === 1}
          setLoading={setLoading}
        />
      </ShowVisibleIf>
    </div>
  );
}

const TrendTopicsData = ({title, hint, link, linkUrl, linkState, type, searchedOptions, visible, setLoading, tab}) => {

  const {call} = useContext(APIContext);
  const [topics, setTopics] = useState();

  useEffect(() => {
    setTopics();
  }, [searchedOptions]);

  useEffect(() => {
    const {genre, platform} = searchedOptions;
    const hasInput = false;
    if (visible && !topics) {
      setLoading(MENU_ITEMS.trending, true);

      const sources = platform === "Mobile" ? "appstore+playstore" : "steam";
      const selectedLocation = platform === "Mobile" && !hasInput ? "United States": undefined;
      let data = {genre, sources, n: 3, location: selectedLocation};
      if (tab === 0) data.sorting = 'growth_rate'

      call(getTrendTopics, {
        type,
        data
      }).then(response => {
        if (response.ok) setTopics(response.body);
        setLoading(MENU_ITEMS.trending, false);
      });
    }
  }, [searchedOptions, type, visible, topics])

  return (
    <>
      <ShowIf condition={!topics}>
        <BigCircularProgress height="1017px"/>
      </ShowIf>
      <ShowIf condition={!!topics}>
        <div className="hq-section topics-data">
          <ShowIf condition={!!title}>
            <div className="section-title"><span>{title}</span><Hint hint={hint}/></div>
          </ShowIf>
          <TrendTopics
            topics={topics}
            tab={0}
            genre={searchedOptions.genre}
            location="United States"
            carouselClassName="light-blue-background"
            sources="appstore+playstore"
          />
          <Link
            to={{
              pathname: linkUrl || "/trending",
              state: linkState
            }}
            title={link || "Explore Trending Topics"}
            className="link-platform">
            <MyButton id="headquarters.go-to-trends" color="primary">
              <span className="ml-2">{link || "Explore Trending Topics"}</span>
            </MyButton>
          </Link>
        </div>
      </ShowIf>
    </>
  );
}

const NEW_RELEASES_PARAMS_INPUT = {n: 9, location: undefined};
const NEW_RELEASES_PARAMS_NO_INPUT = {n: 9, location: "United States"};

const NewReleases = ({searchedOptions, setLoading, innerRef}) => {

  const hasInput = false;
  const {genre} = searchedOptions || {};

  const title = hasInput ?
    `Similar recently released ${genre !== "All" ? genre + " " : ""}games` :
    `Newly released ${genre !== "All" ? genre + " " : ""}games`;

  return (
    <div className="hq-section trend-games-data new-releases" ref={innerRef}>
      <DecorationElements/>
      <div className="section-title"><span>{title}</span></div>
      <TrendsGamesData
        searchedOptions={searchedOptions}
        link="Explore New Releases"
        linkUrl="/trending/latest"
        linkState={{data: {genre}}}
        style={{maxWidth: "800px"}}
        setLoading={setLoading}
        type="latest"
        menuItem={MENU_ITEMS.new}
        callParams={hasInput ? NEW_RELEASES_PARAMS_INPUT : NEW_RELEASES_PARAMS_NO_INPUT}
        visible={true}
      />
    </div>
  );
}

const TrendsGamesData = ({
                           subtitle,
                           link,
                           linkUrl = "/trending",
                           linkState,
                           searchedOptions,
                           className = "",
                           style,
                           setLoading,
                           menuItem,
                           callParams,
                           type,
                           visible,
  debug
                         }) => {

  const {call} = useContext(APIContext);
  const {genre} = searchedOptions;

  const [games, setGames] = useState([]);

  useEffect(() => {
    if (games.length === 0 && visible) {
      setLoading(menuItem, true);
      let {genre, platform} = searchedOptions;
      genre = MISSING_GENRES_MAP[genre] || genre;
      const sources = platform === "Mobile" ? "appstore+playstore" : "steam";
      const hasInput = false;
      const selectedLocation = platform === "Mobile" && !hasInput ? "United States": "All";
      let data = {genre, sources, ...callParams, location: selectedLocation};
      call(getTrendGames, {
        type,
        data
      }).then(response => {
        if (response.ok && response.body?.length > 0) setGames(response.body);
        setLoading(menuItem, false);
      })
    }
  }, [searchedOptions, menuItem, callParams, type, games, debug]);

  let sectionClassName = "hq-section trend-games-data animate__animated animate__fadeIn";
  if (className) sectionClassName += " " + className;

  const forceDate = type === "latest";
  const gridGames = useMemo(() => games.map(({game}) => game), [games]);
  const altText = useMemo(() => games.map(gameInfo => getGameScoreData(gameInfo, undefined, forceDate)), [games, forceDate]);

  return (
    <div className={sectionClassName}>
      {subtitle && <span className="subtitle font-size-md">{subtitle}</span>}
      {games.length === 0 && <BigCircularProgress height="787px"/>}
      {games.length > 0 && <>
        <div className="games-wrapper d-flex flex-row mx-auto my-4 pt-4 w-100" style={style}>
          <GameGrid
            games={gridGames}
            altText={altText}
            gameProps={{genre, location: "United States", lazyLoad: false}}
            includeStats={false}
          />
        </div>
        <Link
          title={link}
          to={{
            pathname: linkUrl,
            state: linkState
          }}
          className="link-platform mb-3">
          <MyButton id="headquarters.go-to-trends" color="primary">
            <span className="ml-2">{link}</span>
          </MyButton>
        </Link>
      </>}
    </div>
  )
}

const TRENDING_CHARTS_PARAMS = {n: 12, location: "United States"};

const ChartGamesData = ({searchedOptions, setLoading, innerRef}) => {

  const TAB_TITLES = {
    input: [
      'Rising in the Charts',
      'In The Top Charts'
    ],
    noInput: [
      'Rising in the Charts',
      'App Store Top Charts',
      'Play Store Top Charts',
      'Steam Top Charts'
    ]
  };

  const {track} = useContext(SocketContext);
  const [tab, setTab] = useState(0);
  const hasInput = false;
  const {genre, platform} = searchedOptions || {};

  const sources = platform === "Mobile" ? "appstore+playstore" : "steam";
  const selectedSource = platform === "Mobile" ? (tab === 1 ? 'appstore' : 'playstore' ) : 'steam'

  const title = hasInput ? `Related ${genre !== "All" ? genre : "Overall"} Chart Games` : `${genre !== "All" ? genre : "Overall"} Chart Games`;
  const hints = [
    hasInput ?
      `Similar games rising in ${genre !== "All" ? genre : "Overall"} charts` :
      `Fastest rising games in ${genre !== "All" ? genre : "Overall"} charts`,
    hasInput ? `Games in the top of the ${genre !== "All" ? genre : "Overall"} charts similar to your input` : `Games in the top of the ${genre !== "All" ? genre : "Overall"} charts`,
  ];

  function changeTab(index) {
    setTab(index);
    const titles = hasInput ? TAB_TITLES.input : TAB_TITLES.noInput;
    track('headquarters.charts.tab', {tab: titles[index]})
  }

  return (
    <div className="hq-section chart-games-data" ref={innerRef}>
      <div className="section-title"><span>{title}</span></div>
      <ShowIf condition={!hasInput}>
        <div className="tabs-wrapper">
          <FormTabs value={tab} className="mt-4 primary" key={"" + hasInput}>
            <FormTab
              label={
                <span>
                <span>{TAB_TITLES.input[0]}</span>
                <Hint hint={hints[0]}/>
              </span>
              }
              onClick={() => changeTab(0)}
            />
            {platform === "Mobile" &&
                <FormTab
                  label={
                    <span>
                    <span>{TAB_TITLES.noInput[1]}</span>
                    <Hint hint={hints[1]}/>
                  </span>
                  }
                  onClick={() => changeTab(1)}
                />}
            {platform === "Mobile" &&
              <FormTab
                label={
                  <span>
                    <span>{TAB_TITLES.noInput[2]}</span>
                    <Hint hint={hints[1]}/>
                  </span>
                }
                onClick={() => changeTab(2)}
              />
            }

            {platform === "Desktop" && <FormTab
              label={
                <span>
                <span>{TAB_TITLES.noInput[3]}</span>
                <Hint hint={hints[1]}/>
              </span>
              }
              onClick={() => changeTab(1)}
            />}
          </FormTabs>
        </div>
      </ShowIf>
      <ShowIf condition={hasInput}>
        <TrendsGamesData
          searchedOptions={searchedOptions}
          link="Explore Trending Games"
          linkUrl="/trending/charts"
          linkState={{data: {genre, sources}}}
          setLoading={setLoading}
          menuItem={MENU_ITEMS.top}
          callParams={TRENDING_CHARTS_PARAMS}
          type="charts"
          visible={tab === 0}
        />
      </ShowIf>
      <ShowIf condition={!hasInput}>
        <ShowVisibleIf condition={tab === 0} style={{zIndex: 1, position: "relative"}}>
          <TrendsGamesData
            searchedOptions={searchedOptions}
            link="Explore Trending Games"
            linkUrl="/trending/charts"
            linkState={{data: {genre, sources}}}
            setLoading={setLoading}
            menuItem={MENU_ITEMS.top}
            callParams={TRENDING_CHARTS_PARAMS}
            type="charts"
            visible={tab === 0}
            debug={true}
          />
        </ShowVisibleIf>
        <ShowVisibleIf condition={tab === 1 || tab === 2} style={{zIndex: 1, position: "relative"}}>
          <TopChartsData
            key={tab}
            source={selectedSource}
            link="Explore Charts"
            genre={selectedSource === "steam" ? undefined : genre}
            visible={tab > 0}
          />
        </ShowVisibleIf>
      </ShowIf>
    </div>
  )
}

const TopChartsData = ({link, title, hint, genre, visible, source = "appstore"}) => {
  const {call} = useContext(APIContext);
  const [charts, setCharts] = useState();

  useEffect(() => {
    if (!charts && visible) {
      genre = MISSING_GENRES_MAP[genre] || genre;
      const location = source === "steam" ? undefined: "United States";
      let filters = {genre, location, sources: [source], n: 5};
      call(getTopCharts, {filters}).then(response => {
        if (response.ok) setCharts(response.body);
      });
    }
  }, [visible, charts, genre, source])

  return (
    <>
      <ShowIf condition={!charts}>
        <BigCircularProgress height="487px"/>
      </ShowIf>
      <ShowIf condition={!!charts}>
        <div className="hq-section top-charts-data">
          <ShowIf condition={!!title}>
            <div className="section-title"><span>{title}</span><Hint hint={hint}/></div>
          </ShowIf>
          <TopChartsContent
            charts={charts}
            source={source}
            genre={genre}
            country="United States"
            defaultLimit={5}
            maxLimit={5}
            gameProps={{lazyLoad: false}}
          />
          <Link
            to="/top-charts"
            title={link}
            className="link-platform">
            <MyButton id="headquarters.go-to-top-charts" color="primary">
              <span className="ml-2">{link}</span>
            </MyButton>
          </Link>
        </div>
      </ShowIf>
    </>
  );
}

export const Hint = ({hint, placement = "top", iconClassName = ""}) => {
  return (
    <ShowIf condition={!!hint}>
      <span className="hint-wrapper">
        <Tooltip
          title={hint}
          arrow
          PopperProps={{
            disablePortal: true,
            className: "MuiTooltip-popper MuiTooltip-popperArrow secondary hint-tooltip"
          }}
          placement={placement}
        >
        <span className="hint">
          <Info className={"ml-1 text-secondary " + iconClassName}/>
        </span>
        </Tooltip>
      </span>
    </ShowIf>
  );
}

const BigCircularProgress = ({height = "120px"}) => (
  <div className="big-circular-progress" style={{height}}>
    <CircularProgress size={70}/>
  </div>
)

const Title = ({name, loading, onClick, selected}) => (
  <span className={selected ? "selected text-align-center" : "text-align-center"} onClick={onClick}>
      {name} <ShowIf condition={loading}><CircularProgress size={15}/></ShowIf>
    </span>
);

export default HeadquartersWrapper;

const ValidationSchema = Yup.object().shape({search: Yup.array()});
