import React, {useContext, useEffect, useMemo, useState} from 'react';
import {Avatar, Chip, Grid} from "@material-ui/core";
import {Form, Formik, useFormikContext} from "formik";
import PageTitle from "components/layout-components/PageTitle";
import APIContext from "context/APIContext";
import ShowIf from "components/common/ShowIf";
import {GeneratingButton} from "components/Controls/MyButton";
import usePersistedState from "hooks/usePersistedState";
import FormikPersist from 'components/utils/FormikPersist';
import * as Yup from "yup";
import CacheContext from "context/CacheContext";
import FormikChipSelect from "components/Controls/FormikChipSelect";
import UniversalInput, {ChangeDataOnLocation, convertUniversalInput} from "components/Controls/UniversalInput";
import {FormikCheckboxField, FormikSelectField} from "formik-material-fields";
import GameCard, {RELEASE_DATES, SOURCES} from "components/common/GameCard";
import './style.scss';
import AuthContext from "context/AuthContext";
import LoadingTip, {LOADING_TIPS_SECTIONS} from "components/utils/LoadingTip";
import LudoCarousel from "components/common/LudoCarousel";
import ImageGallery, {IconGallery, MyImage} from "components/common/ImageGallery";
import GameGrid from "components/common/GameGrid";
import {ExpandLess, ExpandMore, Tune} from "@mui/icons-material";
import {ASPECT_RATIOS} from "pages/ImageGenerator";
import {SORTING_LABELS, SOURCE_LABELS} from "pages/Trends";
import SocketContext from "context/SocketContext";
import restrictions from "config/restrictions.json";
import _ from 'lodash';

const searchEverything = 'searchEverything';

const DEFAULT_OBJECT = {};
const DEFAULT_ARRAY = [];

export const ALL_PLATFORMS = "All Platforms";

var wantRecommendations = true;

export const IMAGE_TYPES = {
  icon: {label: <span>🖼️ Icons</span>, value: 'icon', index: 1, nResults: 120},
  screenshot: {label: <span>📷 Screenshots</span>, value: 'screenshot', index: 0, nResults: 120},
}

export const MODES = {
  all: {label: <span>💎️ All</span>, text: "All", value: 'all', index: 0, nResults: 120},
  games: {label: <span>🎮 Games</span>, text: "Games", value: 'games', index: 1, nResults: 120},
  icon: {label: <span>🖼️ Icons</span>, text: "Icons", value: 'icon', index: 2, nResults: 120},
  screenshot: {label: <span>📷 Screenshots</span>, text: "Screenshots", value: 'screenshot', index: 3, nResults: 120},
}

var localFormik;

const Search = ({
                  fullVersion = true,
                  spacing,
                  gameWidth,
                  gameProps = DEFAULT_OBJECT,
                  imageProps = DEFAULT_OBJECT,
                  initialValues,
                  excludeImages,
                  excludeIds
                }) => {

  const {auth} = useContext(AuthContext);
  const {cache} = useContext(CacheContext);
  const {trialExpired} = cache;
  const {call, loading} = useContext(APIContext);
  const [clickedButton, setClickedButton] = useState(false);
  const [viewAll, setViewAll] = useState(initialValues?.search_mode || MODES.all.value);

  const defaultGenres = initialValues?.genres || auth.user.genres || [];
  const defaultPlatform = initialValues?.platform || auth.user.platform || ALL_PLATFORMS

  let searchKey = 'Search.searchedOptions' + (!fullVersion ? ".small." : "") + defaultGenres[0]+defaultPlatform+ (initialValues?.search_mode || "");
  let resultsKey = 'Search.results' + (!fullVersion ? ".small" : "") + defaultGenres[0]+defaultPlatform+(initialValues?.search_mode || "");

  const [searchedOptions, setSearchedOptions, loadingCacheOptions] = usePersistedState(searchKey, {});
  const [results, setResults, loadingCacheResults] = usePersistedState(resultsKey, {});

  function changeViewAll(mode) {
    localFormik?.setFieldValue("search_mode", mode);
    setViewAll(mode)
  }

  async function onSubmit(values, formik, recommendations = false, clickedButton = true) {

    if (!values) return;

    setClickedButton(clickedButton);

    const {search_mode} = values;

    setResults({})
    setSearchedOptions(values);
    setViewAll(search_mode);

    const isSuggestions = recommendations;

    let sources_filter = (values.selectedStores || []).map(storeName => {
      return Object.keys(SOURCES).find(id => {
        return SOURCES[id].label === storeName
      });
    });

    let data = {
      filters: {
        n: MODES[search_mode].nResults,
        genres_filter: values.genres,
        platforms_filter: [values.platform].filter(platform => platform !== ALL_PLATFORMS),
        sources_filter,
        style_filter: values.selectedStyles,
        color_filter: values.selectedColors,
        release_date_filter: values.selectedReleaseDate,
        trusted_developer_filter: values.topDevelopers,
        search_games: search_mode === MODES.games.value || search_mode === MODES.all.value,
        search_screenshots: search_mode === MODES.screenshot.value || search_mode === MODES.all.value,
        search_icons: search_mode === MODES.icon.value || search_mode === MODES.all.value,
      },
    };

    data = {...data, ...convertUniversalInput(values.search)};

    const showSuggestions = _.flatten(Object.keys(results).map(key => results[key])).length === 0 && wantRecommendations;
    wantRecommendations = false;

    if (isSuggestions && !showSuggestions) return;
    let response = await call(searchEverything, {data});
    if (!isSuggestions || (isSuggestions && showSuggestions)) {
      if (response.ok) {
        setResults(response.body);
      }
    }
  }

  const isLoading = loading[searchEverything];
  const loadingCache = loadingCacheResults || loadingCacheOptions;

  const hasResults = results?.games?.length > 0 || results?.screenshots?.length > 0 || results?.icons?.length > 0;
  const loadingVisible = !loadingCache && (isLoading || !hasResults);

  const {filteredIcons, filteredScreenshots} = useMemo(() => {
    function filter(data = [], type, number = 30) {
      let result = [...data];
      if (viewAll !== type)
        result = result.slice(0, number);
      if (excludeImages) {
        let excludeUrls = excludeImages.map(({url}) => url);
        result = result.filter(({url}) => !excludeUrls.includes(url)) || [];
      }
      return result;
    }

    return {
      filteredIcons: filter(results?.icons, MODES.icon.value, 30),
      filteredScreenshots: filter(results?.screenshots, MODES.screenshot.value, 15),
    }
  }, [results, excludeImages, viewAll]);

  const filteredGames = useMemo(() => {
    let result = [...(results?.games || [])];
    if (viewAll !== MODES.games.value)
      result = result.slice(0, 20);
    if (excludeIds) {
      result = result.filter(({_id}) => !excludeIds.includes(_id));
    }
    return result;
  }, [excludeIds, results, viewAll]);

  return (
    <div className="search">
      <ShowIf condition={fullVersion}>
        <PageTitle
          titleHeading="Search"
          titleDescription="Search for existing games, images and icons on multiple game stores."
        />
      </ShowIf>
      <div className="form-wrapper">
        <SearchForm
          onSubmit={onSubmit}
          fullVersion={fullVersion}
          hasResults={hasResults}
          isLoading={isLoading && clickedButton}
          loadingCache={loadingCache}
          initialValues={initialValues}
          setViewAll={setViewAll}
        />
      </div>
      <div className="position-relative">
        <LoadingTip
          style={{marginLeft: "60px", marginTop: "30px"}}
          section={LOADING_TIPS_SECTIONS.search}
          visible={loadingVisible}
          key={isLoading}
        />
        {hasResults && viewAll === MODES.games.value &&
          <div className="p-4">
            <GameGrid
              gameWidth={gameWidth}
              spacing={spacing}
              games={filteredGames}
              gameProps={{lazyLoad: true, ...gameProps}}
              lockAfterIndex={trialExpired ? restrictions.GAME_SEARCH_NUMBER : undefined}
            />
          </div>
        }
        {hasResults && viewAll === MODES.screenshot.value &&
          <div className="p-4">
            <ImageGallery
              images={filteredScreenshots}
              onImageClick={true}
              {...imageProps}
              blurredAfter={trialExpired ? restrictions.IMAGE_SEARCH_NUMBER : undefined}
              contextMenuOverrides={fullVersion ? undefined : {generateSimilar: null}}
            />
          </div>
        }
        {hasResults && viewAll === MODES.icon.value &&
          <div className="p-4">
            <IconGallery
              icons={filteredIcons}
              {...imageProps}
              blurredAfter={trialExpired ? restrictions.IMAGE_SEARCH_NUMBER : undefined}
            />
          </div>
        }
        {hasResults && viewAll === MODES.all.value ?
          <>
            {filteredGames.length > 0 && <div className="results-wrapper games-results">
              <div className="top-bar">
                <span className="small-title">Games</span>
                <span className="action" onClick={() => changeViewAll(MODES.games.value)}>View all</span>
              </div>
              <LudoCarousel
                id="trend-topic"
                className="games-carousel"
              >
                {filteredGames.map((game, index) => {
                  return <GameCard
                    key={game._id + index}
                    game={game}
                    lazyLoad={false}
                    genre={(searchedOptions?.genres || [])[0]}
                    {...gameProps}
                    //location={location}
                  />
                })}
              </LudoCarousel>
            </div>}
            {filteredIcons.length > 0 && <ImagesCarousel
              images={filteredIcons}
              type={MODES.icon.value}
              viewAll={changeViewAll}
              itemWidth={120}
            />}
            {filteredScreenshots.length > 0 && <ImagesCarousel
              images={filteredScreenshots}
              type={MODES.screenshot.value}
              viewAll={changeViewAll}
              carousel={false}
              imageProps={imageProps}
            />}
          </> : null}
      </div>
    </div>
  );
};

const ImagesCarousel = ({images, type, viewAll, itemWidth, carousel = true, imageProps}) => {
  return (
    <div className={"results-wrapper " + type + "-results"}>
      <div className="top-bar">
        <span className="small-title">{MODES[type].text}</span>
        <span className="action" onClick={() => viewAll(type)}>View all</span>
      </div>
      {carousel && <LudoCarousel
        className={type + "-carousel"}
        itemWidth={itemWidth}
      >
        {images.map((image, index) => {
          return (
            <div className="image-wrapper">
              <MyImage
                convertUrl={true}
                onImageClick={true}
                key={image.id || image.url}
                image={{src: image?.url, title: "", originalImage: image}}
              />
            </div>
          )
        })}
      </LudoCarousel>}
      {!carousel && <div className="p-4">
        <ImageGallery
          images={images}
          height={300}
          onImageClick={true}
          {...imageProps}
        />
      </div>}
    </div>
  )
}

const SearchForm = ({onSubmit, fullVersion = true, hasResults, isLoading, loadingCache, initialValues, setViewAll}) => {

  const {auth} = useContext(AuthContext);
  const {cache} = useContext(CacheContext);
  const {
    platforms = DEFAULT_ARRAY,
    colors = DEFAULT_ARRAY,
    styles = DEFAULT_ARRAY,
  } = cache;
  const [persistedData, setPersistedData] = useState();
  const allGenres = cache.genres || DEFAULT_ARRAY;

  const defaultGenres = initialValues?.genres || auth.user.genres || [];
  const defaultPlatform = initialValues?.platform || auth.user.platform || ALL_PLATFORMS

  let formKey = "Search.SearchGames4" + defaultGenres[0] + defaultPlatform+ (!fullVersion ? "-small" : "");
  if (initialValues) formKey += `-${initialValues.search_mode}` || "";
  const [collapsed, setCollapsed] = usePersistedState(formKey + ".collapsed", true, true);

  const stores = Object.values(SOURCES).map(({label}) => label);
  const convertedColors = useMemo(() => convertFormColors(colors), [colors]);

  const allPlatforms = useMemo(() => {
    return [
      ALL_PLATFORMS,
      ...platforms
    ]
  }, [platforms])

  const finalInitialValues = {
    search_mode: initialValues?.search_mode || MODES.all.value,
    search: [],
    genres: defaultGenres,
    platform: defaultPlatform,
    selectedReleaseDate: RELEASE_DATES[0].value,
    selectedStyles: [],
    selectedColors: [],
    selectedStores: [],
    topDevelopers: false
  };

  const chipSize = {
    xs: 12,
    md: fullVersion ? 4 : 12,
    sm: fullVersion ? 6 : 12,
  };

  function onLoadPersist(data, formik) {
    localFormik = formik;
    setPersistedData(data);
    if (!isLoading && !hasResults && !loadingCache && fullVersion) {
      onSubmit(finalInitialValues, undefined, true, false);
    }
  }

  return !loadingCache && <div key={formKey}>
    <Formik
      initialValues={finalInitialValues}
      onSubmit={onSubmit}
      className="w-100"
      validationSchema={fullVersion ? SearchValidationSchema : RequiredSearchValidationSchema}
    >
      {(formik) => (
        <>
          <FormikPersist name={formKey} onLoad={onLoadPersist}/>
          <ShowIf condition={!!persistedData}>
            <ChangeDataOnLocation
              onAction={onSubmit}
              initialValues={finalInitialValues}
              fields={["genres", "search"]}
            />
          </ShowIf>
          <Form>
            <div className="d-flex flex-column">
              <Grid
                container
                className="mb-0 p-0"
                alignItems="flex-end"
              >
                <Grid
                  item
                  container
                  justifyContent="flex-start"
                  className="mb-0 p-0"
                >
                  <Grid item sm={12} md={12} className="input-fields-wrapper">
                    <div className="d-flex input-fields">
                      {fullVersion && <FormikSelectField
                        className="mode-field"
                        name="search_mode"
                        label="MODE"
                        options={Object.keys(MODES).map(key => {
                          let {value, label} = MODES[key];
                          return {
                            value,
                            label: (
                              <div className="d-flex flex-column">
                                <span className="font-weight-bold">
                                  {label}
                                </span>
                              </div>)
                          }
                        })}
                        onChange={event => {
                          setViewAll(event.target.value);
                        }}
                        fullWidth
                      />}
                      <UniversalInput
                        label="Leave blank, or type keywords, phrases, or game titles"
                        name="search"
                        formik={formik}
                        onSetData={(data) => {
                          formik.setFieldValue("search", data);
                        }}
                        value={formik.values.search}
                        uploadMedia={true}
                        allowed={['text', 'game', 'generated_game', 'gdd', 'topic']}
                      />
                    </div>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <FiltersButton collapsed={collapsed} setCollapsed={setCollapsed} page="search"/>
                </Grid>
                <ShowIf condition={!collapsed}>
                  <div className="filters-form">
                  <Grid item xs={chipSize.xs} sm={chipSize.sm} md={chipSize.md}>
                    <FormikChipSelect
                      name="genres"
                      title="Genres"
                      values={allGenres}
                    />
                  </Grid>
                  <Grid item xs={chipSize.xs} sm={chipSize.sm} md={chipSize.md}>
                    <FormikSelectField
                      className="mt-4"
                      name="platform"
                      label="Platform"
                      options={allPlatforms.map(platform => {
                        return {value: platform, label: platform}
                      })}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={chipSize.xs} sm={chipSize.sm} md={chipSize.md}>
                    <FormikChipSelect
                      name="selectedStores"
                      title="Stores"
                      values={stores}
                    />
                  </Grid>
                  <Grid item xs={chipSize.xs} sm={chipSize.sm} md={chipSize.md}>
                    <FormikSelectField
                      className="mt-2"
                      name="selectedReleaseDate"
                      label="Release Date"
                      options={RELEASE_DATES}
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={chipSize.xs} sm={chipSize.sm} md={chipSize.md}>
                    <FormikChipSelect
                      name="selectedStyles"
                      title="Graphic Style"
                      itemClassName="text-capitalize"
                      chipClassName="text-capitalize"
                      values={styles}
                    />
                  </Grid>
                  <Grid item xs={chipSize.xs} sm={chipSize.sm} md={chipSize.md}>
                    <FormikChipSelect
                      name="selectedColors"
                      title="Colors"
                      className="color-picker"
                      showAvatar={(entry) => showColorAvatar(entry, convertedColors)}
                      menuClassName="color-picker"
                      itemClassName="text-capitalize no-hover-change color-picker-entry"
                      itemStyle={entry => {
                        let {rgb} = entry;
                        return {
                          fill: `rgb(${rgb[0]}, ${rgb[1]},${rgb[2]})`,
                        };
                      }}
                      checkboxStyle={entry => {
                        let {rgb, label} = entry;
                        let rgbColor = `rgb(${rgb[0]}, ${rgb[1]},${rgb[2]})`;
                        let border = label === "white" || label === "orange" ? "1px solid lightgrey" : "none";
                        return {
                          fill: rgbColor,
                          background: rgbColor,
                          color: rgbColor,
                          border
                        }
                      }}
                      options={convertedColors}
                    />
                  </Grid>
                  <Grid item xs={chipSize.xs} sm={chipSize.sm} md={chipSize.md}>
                    <FormikCheckboxField
                      name="topDevelopers"
                      className={"p-0 mr-0 checkbox " + (formik.values.topDevelopers ? "checked " : "")}
                      trueValue={true}
                      falseValue={false}
                      color="secondary"
                      label={
                        <span>Top Developers Only</span>
                      }
                    />
                  </Grid>
                  </div>
                </ShowIf>
                <ShowIf condition={collapsed}>
                  <Grid item sm={12} md={12}>
                    <FilterPanel
                      convertedColors={convertedColors}
                      defaultPlatformOption={ALL_PLATFORMS}
                      onExpand={() => setCollapsed(false)}
                    />
                  </Grid>
                </ShowIf>
              </Grid>
              <Grid item sm={12} md={12}>
                <div className="d-flex">
                  <GeneratingButton
                    id="search.search"
                    trackOptions={{...formik.values, search: convertUniversalInput(formik.values.search)}}
                    loading={isLoading}
                    loadingText="Searching..."
                    className="gradient"
                    loadProgressSecs={5}
                    style={{margin: 0}}
                  >
                    Search!
                  </GeneratingButton>
                </div>
              </Grid>
            </div>
          </Form>
        </>
      )}
    </Formik>
  </div>
}

export function convertFormColors(colors) {
  return colors.map(color => {
    return {
      label: color.name,
      value: color.name,
      rgb: color.rgb
    }
  })
}

function getTextColor(rgb) {
  const brightness = Math.round(((parseInt(rgb[0]) * 299) +
    (parseInt(rgb[1]) * 587) +
    (parseInt(rgb[2]) * 114)) / 1000);
  return (brightness > 125) ? 'black' : 'white';
}

export function showColorAvatar(entry, convertedColors = []) {
  let color = convertedColors.find(({label}) => label === entry);
  if (!!color) {
    let {rgb} = color;
    return (<Avatar style={{
      width: "30px",
      height: "30px",
      background: `rgb(${rgb[0]}, ${rgb[1]},${rgb[2]})`,
      color: getTextColor(rgb),
      border: "2px solid white",
    }}>
      &nbsp;
    </Avatar>);
  }
}

export const FilterPanel = ({convertedColors, defaultPlatformOption, onExpand, ignore = [], onChange}) => {
  const formik = useFormikContext();
  return (
        <FilterPanelData
          values={formik.values}
          convertedColors={convertedColors}
          defaultPlatformOption={defaultPlatformOption}
          onExpand={onExpand}
          ignore={ignore}
          formik={formik}
          onChange={onChange}
        />
  );
}

export const FilterPanelData = ({values, convertedColors, defaultPlatformOption, onExpand, ignore = [], formik, onChange}) => {

  const {cache} = useContext(CacheContext);
  const {generationStyles = []} = cache;

  return Object.keys(values).map(key => {
    let val = values[key];

    if (ignore.includes(key)) return null;
    if (key === "search" || key === "image_type" || key === "generated_type" || key === "search_mode") return null;

    if (key === "mask_image") {
      return val && <FilterChip
        key={key + val}
        onClick={onExpand}
        value="With mask"
      />
    } else if (key === "initial_video_size_crop") {
      return val?.filter(val => val !== 0).length > 0 && <FilterChip
        key={key + val}
        onClick={onExpand}
        value="Cropped"
      />
    } else if (key === "initial_video_time_crop") {
      return val?.length > 0 && <FilterChip
        key={key + val}
        onClick={onExpand}
        value={`Timestamps: ${val[0].toFixed(2)} to ${val[1].toFixed(2)}`}
      />
    } else if (key === "duration") {
      return val > 0 && <FilterChip
        key={key + val}
        onClick={onExpand}
        value={`Duration: ${val}`}
      />
    } else if (Array.isArray(val)) {
      return val.map((value, index) => {
        return <FilterChip
          key={value + index}
          className="color-chip"
          onClick={onExpand}
          value={value}
          showAvatar={key === "selectedColors" ? (entry) => showColorAvatar(entry, convertedColors) : null}
          onDelete={formik ? () => {
            let newVals = formik.values[key].filter(val => val !== value);
            formik.setFieldValue(key, newVals);
            if (onChange) onChange(key, newVals);
          } : undefined}
        />
      });
    } else if (key === "aspect_ratio") {
      return (ASPECT_RATIOS[val] && <FilterChip
          key={key + val}
          onClick={onExpand}
          value={ASPECT_RATIOS[val].labelCollapsed}
        />
      );
    } else if (key === "creative_mode") {
      return (
        <ShowIf condition={val !== false} key={key}>
          <FilterChip
            key={key + val}
            onClick={onExpand}
            value="Creative Mode"
          />
        </ShowIf>
      );
    } else if (key === "topDevelopers") {
      return (
        <ShowIf condition={val !== false} key={key}>
          <FilterChip
            key={key + val}
            onClick={onExpand}
            value="Top Developers Only"
          />
        </ShowIf>
      );
    } else if (key === "art_style") {
      return (
        <ShowIf condition={!!val} key={key}>
          <FilterChip
            key={key + val}
            onClick={onExpand}
            value={val}
            onDelete={formik && val !== generationStyles[0] ? () => {
              formik.setFieldValue(key, generationStyles[0]);
            } : undefined}
          />
        </ShowIf>
      );
    } else if (key === "selectedReleaseDate") {
      let label = (RELEASE_DATES.find(({value, label}) => value === val) || {}).label;
      return (
        <ShowIf condition={val !== RELEASE_DATES[0].value} key={key}>
          <FilterChip
            key={key + val}
            onClick={onExpand}
            value={label}
            onDelete={formik ? () => {
              formik.setFieldValue(key, RELEASE_DATES[0].value);
            } : undefined}
          />
        </ShowIf>
      );
    } else if (key === "selectedPlatform") {
      return (
        <FilterChip
          key={key + val}
          onClick={onExpand}
          value={val}
          onDelete={formik && !!defaultPlatformOption && val !== defaultPlatformOption ? () => {
            formik.setFieldValue(key, defaultPlatformOption);
          } : null}
        />
      );
    } else if (key === "description" || key === "mixing_mode" || key === "type_filter") {
      return null;
    } else if (typeof val === "string") {

      let displayVal = val;
      if (key === "location") {
        let valCapitalized = val.charAt(0).toUpperCase() + val.slice(1);
        displayVal = val.length <= 3 ? val.toUpperCase() : valCapitalized;
      } else if (key === "sources") {
        displayVal = SOURCE_LABELS[val];
      } else if (key === "elementType") {
        return null;
      } else if (key === "sorting") {
        displayVal = (SORTING_LABELS[0][val] || SORTING_LABELS[1][val])?.label;
      }

      return <FilterChip
        key={key + val}
        value={displayVal}
        onClick={onExpand}
      />;
    }
  });
}

export const FiltersButton = ({collapsed, setCollapsed, page}) => {
  const {track} = useContext(SocketContext);

  function setCollapsedWrapper(value) {
    track(`filters.${value ? 'hide' : 'show'}`, {page});
    setCollapsed(value);
  }

  return (
    <div className="filters-wrapper mt-3 mb-1 font-weight-bold clickable"
         onClick={() => setCollapsedWrapper(!collapsed)}>
      <div>
        <Tune className="font-size-lg mr-2"/>
        <ShowIf condition={collapsed}>
          <span>Show Filters</span>
        </ShowIf>
        <ShowIf condition={!collapsed}>
          <span>Hide Filters</span>
        </ShowIf>
      </div>
      {!collapsed ? <ExpandLess className="font-size-md ml-2 align-self-center"/> :
        <ExpandMore className="font-size-md ml-2 align-self-center"/>}
    </div>
  );
}

const FilterChip = ({value, showAvatar, onDelete, className = "", style = DEFAULT_OBJECT, onClick}) => {
  return (<Chip
    style={{zIndex: "1", ...style}}
    className={"mr-2 mb-2 " + className}
    icon={!!showAvatar ? showAvatar(value) : null}
    key={value}
    label={value}
    onMouseDown={(event) => {
      //event.stopPropagation();
    }}
    onDelete={onDelete}
    onClick={onClick}
  />)
}

export default Search;

const SearchValidationSchema = Yup.object().shape({
  genres: Yup.array(),
});

const RequiredSearchValidationSchema = Yup.object().shape({
  genres: Yup.array(),
  search: Yup.array()
})
