import React, {useContext, useState, useEffect, useMemo} from 'react';
import APIContext from 'context/APIContext';
import AuthContext from 'context/AuthContext';
import {Formik, Form, Field} from "formik";
import {FormikTextField} from 'formik-material-fields';
import {useHistory, useLocation, useParams} from "react-router";
import MyButton from "components/Controls/MyButton";
import * as Yup from "yup";
import {NavLink} from "react-router-dom";
import logoLarge from "assets/images/logo-white.png";
import CacheContext, {DEFAULT_CACHE} from "context/CacheContext";
import config from "config/config.json";
import ErrorBadge from "components/common/ErrorBadge";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import qs from "qs";
import ShowIf from "components/common/ShowIf";
import {Alert, Autocomplete} from "@material-ui/lab";
import TagManager from "react-gtm-module";
import CreditCardForm from "components/common/CreditCardForm";
import usePersistedState from "hooks/usePersistedState";
import {usePostHog} from "posthog-js/react";
import PerformanceUtils from "../../helpers/PerformanceUtils";
import './style.scss';
import moment from "moment";
import {CircularProgress} from "@material-ui/core";

import applePayLogo from "assets/images/icons/apple-pay.png";
import googlePayLogo from "assets/images/icons/google-pay.png";
import cashAppLogo from "assets/images/icons/cash-app.png";

const registerPaywall2 = "registerPaywall2";
const registerPaywallTeam = 'registerPaywallTeam';
const checkEmailValid = 'checkEmailValid';
const goToCheckout = 'goToCheckout';
const successPaywallSession = 'successPaywallSession';
const getPaymentPlans = 'getPaymentPlans';

const STATES = {
  start: 'start',
  payment: 'payment',
  team: 'team',
  success: 'success',
}

const START_URL = "/register";
const PAYMENT_URL = "/register/payment";
const TEAM_URL = "/register/team";
const SUCCESS_URL = "/register/success";

const RegisterPaywall = () => {
  const location = useLocation();
  return <RegisterPaywallContent key={location.pathname}/>;
}

const MESSAGES = {
  [STATES.start]: "Join thousands of game studios already crafting tomorrow's hits with Ludo",
  [STATES.payment]: "Start Your 7-Day Free Trial Now!"
}

const RegisterPaywallContent = () => {

  const location = useLocation();
  const params = useParams();
  const posthog = usePostHog();
  const consent = true;
  const queryString = qs.parse(location.search, {ignoreQueryPrefix: true});
  const emailQs = queryString?.email;
  const sessionId = queryString?.sessionId;
  const teamId = params.teamId;
  const teamEmail = params.email;

  const isStart = location.pathname === START_URL;
  const isPayment = location.pathname === PAYMENT_URL;
  const isTeam = location.pathname.startsWith(TEAM_URL);
  const isSuccess = location.pathname === SUCCESS_URL;

  const {call, loading} = useContext(APIContext);
  const {setCache, cache} = useContext(CacheContext);
  const {setAuth} = useContext(AuthContext);
  const history = useHistory();
  const [loadingReq, setLoadingReq] = useState(false);
  const [error, setError] = useState();
  const [codeError, setCodeError] = useState();
  const [state, setState] = useState(isPayment ? STATES.payment : STATES.start);
  const [successMessage, setSuccessMessage] = useState();
  const [values, setValues] = usePersistedState('register.values', {email: emailQs}, true);

  useEffect(() => {
    if (emailQs)
      setValues({...values, email: emailQs});
  }, [emailQs]);

  useEffect(() => {
    if (!!config.GOOGLE_TAG_ID) {
      TagManager.dataLayer({
        dataLayer: {
          event: 'start-registration',
        },
      });
    }
    posthog?.capture('start-registration');
  }, [])

  useEffect(() => {
    if (isStart) {
      setState(STATES.start);
    } else if (isPayment) {
      setState(STATES.payment);
    } else if (isTeam) {
      setState(STATES.team);
    } else if (isSuccess) {
      setState(STATES.success);
    }
  }, [isPayment, isStart, isTeam, isSuccess]);

  useEffect(() => {
    if (state === STATES.success && sessionId) {
      call(successPaywallSession, {session: {value: sessionId}}).then(async response => {
        if (response.ok) {
          posthog?.capture('stripe-checkout-success');
          posthog?.capture('add-credit-card');
          if (!!config.GOOGLE_TAG_ID) {
            TagManager.dataLayer({
              dataLayer: {
                event: 'add-credit-card',
                user_data: {
                  email_address: values.email,
                  sha256_email_address: await PerformanceUtils.sha256(values.email)
                }
              },
            });
          }
          onLoginPayload(response.body);
        }
      });
    }
  }, [state, sessionId])

  useEffect(() => {
    if (teamId && teamEmail) {
      setValues({...values, teamId, email: teamEmail});
    }
  }, [teamId, teamEmail]);

  async function onSubmitCredentials(credentials) {
    let newValues = {...values, ...credentials};
    setValues(newValues);
    posthog?.capture('submit-email-registration');
    let response = await call(checkEmailValid, {
      email: {value: newValues.email}
    });
    if (response.ok) {
      setState(STATES.payment);
    }
  }

  async function onFinishPayment(paymentValues) {
    setError();
    setLoadingReq(true);

    let auth = {
      name: values.name,
      email: values.email,
      password: values.password,
      payment_token: paymentValues.token,
    };

    let response = await call(registerPaywall2, {auth});
    setLoadingReq(false);
    if (response.ok) {
      posthog?.capture('add-credit-card');
      if (!!config.GOOGLE_TAG_ID) {
        TagManager.dataLayer({
          dataLayer: {
            event: 'add-credit-card',
            user_data: {
              email_address: values.email,
              sha256_email_address: await PerformanceUtils.sha256(values.email)
            }
          },
        });
      }

      let joinTeamId = cache.joinTeamId;
      localStorage.clear();
      sessionStorage.clear();
      onLoginPayload(response.body, joinTeamId)
    } else {
      setError(response.body.message)
    }
  }

  async function onSubmitTeam(credentials) {
    setError();
    setLoadingReq(true);

    let auth = {
      name: credentials.name || values.name,
      password: credentials.password || credentials.password,
      team_id: credentials.teamId || values.teamId,
      email: credentials.email || values.email,
    };

    let response = await call(registerPaywallTeam, {auth});
    setLoadingReq(false);
    if (response.ok) {
      localStorage.clear();
      sessionStorage.clear();
      onLoginPayload(response.body)
    } else {
      setError(response.body.message)
    }
  }

  function onLoginPayload(payload) {
    setAuth(payload, false);
    let selectedProjectId = payload.user.selected_project || DEFAULT_CACHE.selectedProjectId;
    let path = cache.unauthenticatedPath || "/";
    setCache({
      ...DEFAULT_CACHE,
      selectedProjectId,
      projectsOpen: !selectedProjectId,
      unauthenticatedPath: undefined,
    });
    if (consent) {
      if (!!config.GOOGLE_TAG_ID) {
        TagManager.dataLayer({
          dataLayer: {
            event: 'registration',
          },
        });
      }
      posthog?.capture('registration');
    }
    history.push(path);
  }

  return (
    <div className="app-wrapper bg-gradient min-vh-100 register register-paywall2">
      <div className="app-main min-vh-100 top-bg-content">
        <div className="app-content p-0">
          <div className="app-content--inner d-flex align-items-center">
            <div className="app-content--inner__wrapper">
              <div className="flex-grow-1 w-100 d-flex align-items-center">
                <div className="bg-composed-wrapper--content pb-5 px-2 mb-5">
                  <div className="auth-form-wrapper">
                    <div className="text-center mb-4 pb-2">
                      <h1 className="display-4 font-weight-bold font-size-xxxxl">
                        <img
                          width="225"
                          alt="Ludo"
                          className="d-block m-auto logo"
                          src={logoLarge}
                        />
                        {MESSAGES[state]}
                      </h1>
                    </div>
                    <ShowIf condition={state === STATES.start}>
                      <CredentialsForm
                        initialValues={values}
                        onSubmit={onSubmitCredentials}
                        loading={loading[checkEmailValid]}
                        error={error}
                        successMessage={successMessage}
                      />
                    </ShowIf>
                    <ShowIf condition={values.email && state === STATES.team}>
                      <CredentialsForm
                        initialValues={values}
                        onSubmit={onSubmitTeam}
                        loading={loading[checkEmailValid]}
                        error={error}
                        successMessage={successMessage}
                      />
                    </ShowIf>
                    <ShowIf condition={state === STATES.payment && !codeError}>
                        <span className="info">
                         <a
                           href="https://ludo.ai/pricing"
                           target="_blank">Click here to find out more about our plans</a></span>
                      <PaymentForm
                        onSubmit={onFinishPayment}
                        values={values}
                        loadingSubmission={loadingReq}
                        error={error}
                      />
                    </ShowIf>
                    <ShowIf condition={state === STATES.success}>
                      <center>
                        <CircularProgress color="white" size={55}/>
                      </center>
                    </ShowIf>
                    <ErrorBadge error={codeError}/>
                    {state === STATES.start && <div className="text-center pt-4">
                      Already on Ludo?{' '}
                      <NavLink to="/login" className="text-white font-weight-bold">
                        Login here
                      </NavLink>
                    </div>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const CredentialsForm = ({onSubmit, loading, error, successMessage, initialValues}) => {

  const [showPassword, setShowPassword] = useState(false);

  return (<Formik
      key={initialValues}
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={PasswordValidationSchema}
    >
      {({isValid, errors, dirty, validateField, setFieldValue}) => (
        <Form>
          <div className="mb-4">
            <label className="font-weight-bold mb-1 caps">
              Email
            </label>
            <FormikTextField
              name="email"
              variant="outlined"
              size="small"
              fullWidth
              type="email"
            />
          </div>
          <div className="mb-4">
            <label className="font-weight-bold mb-1 caps">
              Full Name
            </label>
            <FormikTextField
              name="name"
              variant="outlined"
              size="small"
              fullWidth
              disabled={loading}
            />
          </div>
          <div className="mb-4">
            <div className="d-flex justify-content-between position-relative">
              <label className="font-weight-bold mb-1">
                Password (6 or more characters)
              </label>
              <div
                className="d-flex"
                style={{position: "absolute", right: "5px", top: 0, color: "white", cursor: "pointer"}}
                onClick={() => setShowPassword(!showPassword)}
              >
                <div className="mr-3">
                  {showPassword ? <VisibilityOff/> : <Visibility/>}
                </div>
                <span style={{top: "1px", position: "relative", width: "35px", textAlign: "right"}}>
                  {showPassword ? "Hide" : "Show"}
                </span>
              </div>
            </div>
            <FormikTextField
              name="password"
              variant="outlined"
              size="small"
              fullWidth
              type={showPassword ? "text" : "password"}
              disabled={loading}
            />
          </div>
          <ErrorBadge error={error}/>

          <ShowIf condition={!!successMessage}>
            <Alert severity="success" className="mt-5 mb-2">
              {successMessage}
            </Alert>
          </ShowIf>

          <div className="mt-4 text-white mb-4 opacity-7">
          <span>By proceeding, you agree to our <a tabIndex="-1" href="https://ludo.ai/privacy-policies"
                                                   target="_blank"
                                                   className="text-white underline">Terms of Service and Privacy Policy</a></span>
          </div>

          <ShowIf condition={!successMessage}>
            <div className="text-center">
              <MyButton
                className="mt-4 mb-2 font-weight-bold blue"
                style={{width: "100%"}}
                loading={loading}
              >
                Get Started!
              </MyButton>
            </div>
          </ShowIf>
        </Form>
      )}
    </Formik>
  );
}

const PaymentForm = ({onSubmit, loadingSubmission, error, values}) => {

  const {call, loading} = useContext(APIContext);
  const [plans, setPlans] = useState([]);
  const [interval, setInterval] = useState("month");

  const posthog = usePostHog();

  useEffect(() => {
    posthog?.capture('start-payment-registration');
  }, [])

  useEffect(() => {
    call(getPaymentPlans).then(response => {
      if (response.ok) {
        setPlans(response.body);
      }
    })
  }, []);

  const plan = useMemo(() => {
    return plans?.find(plan => plan.interval === interval && !plan.metadata.team);
  }, [interval, plans])

  function onSubmitWrapper(token) {
    return onSubmit({
      token,
    })
  }

  async function onGoToCheckout() {
    posthog?.capture('stripe-checkout-start');
    let response = await call(goToCheckout, {
      auth: {
        email: values.email,
        password: values.password,
        name: values.name
      }
    });
    if (response.ok) {
      window.location = response.body.value;
    }
  }

  return (
    <div className="payment-form">
      <CreditCardForm
        whiteForm={false}
        onTokenGenerated={onSubmitWrapper}
        onCancel={() => {
        }}
        slim={true}
        showStripeText={true}
        buttonText="Start My Free Trial"
        buttonClassName="blue"
        name={values.name}
        loading={loadingSubmission}
        additionalContent={
          <>
            <MyButton
              id="registration.stripe-checkout"
              className="tertiary-light-light mb-5"
              onClick={onGoToCheckout}
              loading={loading[goToCheckout]}
            >
              Other Payment Methods&nbsp;
              <img height="25" src={applePayLogo} className="mx-2"/>
              <img height="25" src={googlePayLogo} className="mr-2"/>
              <img height="25" src={cashAppLogo} className="mr-2"/>
            </MyButton>
            <PlanInfo plan={plan}/>
          </>
        }
      />
      <ErrorBadge error={error}/>
    </div>
  );
}

const PlanInfo = ({plan, tax}) => {

  if (!plan) return null;

  const date = moment().add(7, 'day').format('Do MMMM');
  const amount = (plan.amount || 0) / 100.0;
  const taxAmount = tax || 0;
  const total = amount + taxAmount;

  return (
    <div className="plan-info">
      <div className="box">
        <div className="row-wrapper line">
          <div className="row">
        <span className="title">
          Due Today
        </span>
            <span className="description">
          </span>
            <span className="amount">
          $0
        </span>
          </div>
        </div>
        <div className="row-wrapper">
          <div className="row">
          <span className="title">
            Due {date}
          </span>
            <span className="description">
            {plan.name} Subscription
          </span>
            <span className="amount">
            ${amount}
          </span>
          </div>
          {taxAmount > 0 && <div className="row">
          <span className="description">
            Tax
          </span>
            <span className="amount">
            ${taxAmount}
          </span>
          </div>}
          {taxAmount > 0 && <div className="row">
          <span className="description">
            Total
          </span>
            <span className="amount">
            ${total}
          </span>
          </div>}
        </div>
      </div>
      <span className="info">
        You can cancel your subscription at any time.
      </span>
    </div>
  )
}

export default RegisterPaywall;

const StartValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Must be a valid email')
    .required('No email provided'),
});

const PasswordValidationSchema = Yup.object().shape({
  name: Yup.string()
    .required('No name provided'),
  email: Yup.string()
    .email('Must be a valid email')
    .required('No email provided'),
  password: Yup.string()
    .required('No password provided')
    .min(6, 'Password is too short - should be 6 characters minimum'),
});
